.form-group {
  display: flex;
  align-items: stretch;
  gap: 1px;

  width: 100%;

  .divider {
    height: unset;
    width: unset;
  }

  &.vertical {
    flex-direction: column;

    .increment-picker,
    .pill {
      &:not(.omit-from-form-group) {
        &:not(:first-child) {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        &:not(:last-child) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    .input-wrapper,
    .select-wrapper,
    .date-picker-wrapper,
    .time-picker-wrapper,
    .search-wrapper {
      &:not(.omit-from-form-group) {
        &:not(:first-child) {
          .input,
          .button,
          .input-overflow-wrapper {
            &:not(.omit-from-form-group) {
              border-top-left-radius: 0;
              border-top-right-radius: 0;
            }
          }
        }
        &:not(:last-child) {
          .input,
          .button,
          .input-overflow-wrapper {
            &:not(.omit-from-form-group) {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }
      }
    }
  }

  &.horizontal {
    flex-direction: row;

    .increment-picker,
    .pill {
      &:not(.omit-from-form-group) {
        &:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    .input-wrapper,
    .select-wrapper,
    .date-picker-wrapper,
    .time-picker-wrapper,
    .search-wrapper {
      &:not(.omit-from-form-group) {
        &:not(:first-child) {
          .input,
          .button,
          .input-overflow-wrapper {
            &:not(.omit-from-form-group) {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
        &:not(:last-child) {
          .input,
          .button,
          .input-overflow-wrapper {
            &:not(.omit-from-form-group) {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }
      }
    }
  }
}
