#experiences-manage-select-page.desktop {
  .cards {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 64px;

    min-height: 100px;

    padding: 64px 16px;
  }
}
