.build-experience {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 32px 24px 48px 24px;

  border-radius: 10px;
  background-color: $black-2;

  overflow: hidden;
  box-sizing: border-box;

  @include maxScreen($sm) {
    min-height: 533px;
    padding: 20px 16px 28px 20px;
  }

  .inner {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 32px;

    width: 100%;
    max-width: 550px;

    margin-top: 16px;

    .title {
      color: $white;
      @include blatant(500);
      font-size: 32px;
      text-align: center;

      @include maxScreen($xl) {
        font-size: 30px;
      }

      @include maxScreen($lg) {
        font-size: 28px;
      }

      @include maxScreen($md) {
        font-size: 26px;
      }

      @include maxScreen($sm) {
        font-size: 24px;
      }
    }

    .cig-selection {
      width: 100%;

      .search-wrapper {
        margin-top: 12px;

        width: 100%;
      }

      .selected-cig {
        position: relative;
        display: flex;
        align-items: center;
        gap: 12px;

        width: 100%;
        height: 70px;

        margin-top: 12px;

        padding: 0 12px;

        border-radius: 5px;
        background-color: $black-4;

        box-sizing: border-box;
        overflow: hidden;

        transition: background-color 300ms ease;

        &.cig-selected {
          background-color: $purple;
        }

        .avatar-wrapper {
          svg {
            color: $white;
          }
        }

        .cig-details {
          .display-name {
            @include inter(500);
            color: $white;
            font-size: 18px;
          }

          .tag-line {
            color: $white;
            font-size: 12px;
          }
        }
      }
    }

    .game-selection {
      display: flex;
      flex-direction: column;

      width: 100%;

      .game-select-wrapper {
        position: relative;

        min-height: 54px;

        margin-top: 12px;
      }
    }

    .platform-selection {
      width: 100%;

      .button-select-wrapper {
        position: relative;

        min-height: 75px;

        margin-top: 12px;

        .button-select {
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          gap: 16px;

          min-height: inherit;
        }
      }
    }

    .next-button {
      width: 200px;
    }
  }
}
