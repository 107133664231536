.alt-auth-bar {
  display: flex;
  align-items: center;
  gap: 12px;

  margin: 2px;

  button.button {
    &.medium {
      width: 90px;

      border-radius: 11.25px;

      span {
        @include size(24px);
        svg {
          @include size(24px);
        }
      }
    }

    &.small {
      width: 60px;

      border-radius: 8px;

      span {
        @include size(20px);
        svg {
          @include size(20px);
        }
      }
    }
  }
}
