.calendar-view .calendar-wrapper {
  height: 100%;

  @include maxScreen($md) {
    height: 100%;
  }

  .calendar-view-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 16px;

    min-height: 0;

    .dates {
      .row {
        grid-template-columns: repeat(7, 160px);

        @include maxScreen($xl) {
          grid-template-columns: repeat(7, 140px);
        }

        @include maxScreen($lg) {
          grid-template-columns: repeat(7, 125px);
        }

        @include maxScreen($md) {
          grid-template-columns: auto;
        }
      }
    }

    .blocks {
      flex-grow: 1;
      position: relative;

      min-height: 0;

      padding: 16px;

      user-select: none;
      overflow-y: auto;

      @include maxScreen($sm) {
        padding: 8px;
      }

      &.selecting {
        cursor: grabbing;
      }

      .row {
        position: relative;
        display: grid;
        grid-template-columns: repeat(7, 160px);

        height: 100%;

        @include maxScreen($xl) {
          grid-template-columns: repeat(7, 140px);
        }

        @include maxScreen($lg) {
          grid-template-columns: repeat(7, 125px);
        }

        @include maxScreen($md) {
          grid-template-columns: auto;
        }

        .cell {
          position: relative;
          display: flex;
          flex-direction: column;

          height: 100%;

          overflow: visible;
          pointer-events: none;

          .space-saver {
            flex-grow: 1;

            margin: 4px;

            background-color: $black-3;
            border-radius: 5px;

            .text {
              color: $gray-3;
              margin: 8px;
            }
          }
        }
      }

      .session {
        position: relative;
        display: flex;
        flex-direction: column;

        height: 180px;

        margin: 4px;

        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 5px;

        box-sizing: border-box;
        z-index: 100;
        pointer-events: all;
        cursor: pointer;
        overflow: visible;

        transition: border-color ease 300ms;

        @include maxScreen($lg) {
          @include minScreen($md) {
            height: 150px;
          }
        }

        &.full,
        &.ongoing {
          .details-wrapper {
            background-color: rgba($red, 0.4);
          }
        }

        &.ended {
          background-color: $black-3;
          border-color: $black-3;

          .details-wrapper {
            .details {
              .slots-wrapper {
                .slots {
                  color: $gray-2;
                  text-decoration: line-through;
                }
              }
            }
          }
        }

        &.exclusive,
        &.time-exclusive {
          pointer-events: none;
          background-color: $white;

          .details-wrapper {
            opacity: 0.1;
          }
        }

        &.selected {
          .details-wrapper {
            background-color: rgba($purple, 0.4);
          }
        }

        .details-wrapper {
          position: relative;
          flex-grow: 1;
          display: flex;

          border-radius: 5px;

          overflow: hidden;
          transition: background-color 300ms ease;

          .details {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 12px;

            min-width: 0;

            margin: 12px 8px;

            @include maxScreen($lg) {
              @include minScreen($md) {
                margin: 10px 8px;
                gap: 6px;
              }
            }

            .title {
              @include lineClamp(2);
              color: $white;
              font-size: 12px;

              @include maxScreen($lg) {
                @include minScreen($md) {
                  font-size: 11px;
                }
              }
            }

            .game-name {
              @include inter(600);
              font-size: 16px;
              @include lineClamp(2);
              color: $white;

              @include maxScreen($lg) {
                @include minScreen($md) {
                  font-size: 15px;
                }
              }
            }

            .time {
              @include inter(500);
              font-size: 12px;
              color: $white;

              @include maxScreen($lg) {
                @include minScreen($md) {
                  font-size: 11px;
                }
              }
            }

            .pill {
              width: fit-content;
            }
          }

          .icons {
            display: flex;
            flex-direction: column;
            gap: 4px;

            top: 0;
            right: 0;

            padding: 8px 6px 8px 0;

            @include maxScreen($lg) {
              @include minScreen($md) {
                padding: 4px 4px 4px 0;
              }
            }

            & > *,
            svg,
            img {
              color: $white;
              @include size(24px);

              @include maxScreen($lg) {
                @include minScreen($md) {
                  @include size(20px);
                }
              }
            }
          }
        }

        .plus-cover {
          .group {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;

            inset: 0;

            .yakkr-banner {
              width: 70px;
            }

            .diamond-icon {
              width: 40px;
            }
          }

          .time-icon {
            position: absolute;

            @include size(20px);

            right: 0;
            top: 0;
            margin: 8px;

            color: $black;
          }
        }
      }
    }

    .footer-prompt {
      display: flex;
      flex-direction: column;
      gap: 16px;

      p {
        @include inter(600);
        color: $gray-3;
        text-align: center;
        font-size: 14px;
      }
    }
  }
}

.book-session-panel {
  .button-row {
    .button-wrapper {
      flex-direction: column;
      align-items: stretch;

      .increment-picker {
        margin-bottom: 2px;
      }
    }
  }

  .action-panel-form {
    .form-wrapper {
      .form {
        .inner {
          align-items: center;

          .form-row {
            width: 100%;
          }
        }
      }
    }
  }
}

.book-session-panel-modal {
  .modal {
    height: unset;

    .modal-content {
      padding: 0;

      .book-session-panel {
        width: 340px;

        padding: 24px 8px;

        @include maxScreen($xs) {
          width: 100%;
        }

        .action-panel-header {
          border-color: transparent;
          height: auto;
          min-height: auto;
        }

        .action-panel-form {
          .form-wrapper {
            margin: 0;

            .form {
              .inner {
                padding-bottom: 2px;
                margin: 0;
              }
            }
          }
        }

        .button-row {
          padding: 16px 12px !important;

          .button-wrapper {
            padding: 0 !important;

            .discount {
              margin-top: 2px;

              @include inter(600);
              font-size: 12px;
              text-align: center;

              .price {
                text-decoration: line-through;
              }
            }
          }
        }
      }
    }
  }
}
