&.last-chance {
  margin: 48px 0;

  // Scale up
  @include minScreen($sl) {
    margin: 96px 0;
  }

  @include minScreen($ul) {
    margin: 160px 0;
  }

  .cta-box {
    display: flex;

    background-color: $black-2;
    border: solid 2px $black-3;
    border-radius: 5px;

    overflow: hidden;

    // Scale down
    @include maxScreen($md) {
      flex-direction: column-reverse;
    }

    .content {
      flex-basis: 60%;

      padding: 32px;

      // Scale down
      @include maxScreen($md) {
        flex-basis: 55%;

        padding: 24px;
      }

      @include maxScreen($sm) {
        padding: 16px;
      }

      // Scale up
      @include minScreen($sl) {
        padding: 48px;
      }

      @include minScreen($ul) {
        padding: 96px;
      }

      .title {
        @include blatant(700);
        color: $white;
        font-size: 40px;

        // Scale down
        @include maxScreen($xl) {
          font-size: 32px;
        }

        @include maxScreen($lg) {
          font-size: 28px;
        }

        @include maxScreen($sm) {
          font-size: 24px;
        }

        @include maxScreen($xs) {
          font-size: 20px;
        }

        // Scale up
        @include minScreen($sl) {
          font-size: 48px;
        }

        @include minScreen($ul) {
          font-size: 80px;
        }
      }

      .text {
        margin-top: 8px;

        @include inter(500);
        color: $gray-3;
        font-size: 20px;

        // Scale down
        @include maxScreen($xl) {
          font-size: 18px;
        }

        @include maxScreen($lg) {
          font-size: 16px;
        }

        @include maxScreen($sm) {
          font-size: 14px;
        }

        @include maxScreen($xs) {
          font-size: 12px;
        }

        // Scale up
        @include minScreen($sl) {
          font-size: 28px;
        }

        @include minScreen($ul) {
          font-size: 40px;
        }
      }

      .gradient-button {
        margin-top: 16px;

        .button {
          min-width: 200px;
        }
      }
    }

    .cta-img-wrapper {
      flex-basis: 40%;
      position: relative;

      z-index: 100;

      // Scale down
      @include maxScreen($md) {
        flex-basis: 45%;
      }

      @include maxScreen($md) {
        min-height: 200px;
        width: 100%;
      }
    }
  }
}
