.increment-picker {
  position: relative;
  display: flex;
  align-items: center;

  box-sizing: border-box;
  box-shadow: 0 0 0 1px transparent;

  transition: background-color 300ms ease, border-radius 300ms ease, box-shadow 75ms ease-out;

  .increment-divider {
    transition: border-color 300ms ease;
  }

  .increment-input-wrapper {
    flex-grow: 1;
    width: min-content;

    .increment-input {
      text-align: center;

      &.read-only:not(.disabled) {
        @include disabled;
        opacity: 1 !important;
      }
    }
  }

  @mixin sizing($height, $border-radius) {
    border-radius: $border-radius;

    .increment-divider {
      height: $height;
    }

    &.rounded {
      border-radius: calc($border-radius * 4.3);
    }
  }

  &.extra-small {
    @include sizing($height: 18px, $border-radius: 3px);
  }

  &.small {
    @include sizing($height: 29px, $border-radius: 4px);
  }

  &.medium {
    @include sizing($height: 41px, $border-radius: 6px);
  }

  &.large {
    @include sizing($height: 56px, $border-radius: 8px);
  }

  &.extra-large {
    @include sizing($height: 85px, $border-radius: 10px);
  }

  &.contained {
    @each $name, $color in $colors {
      &.#{$name} {
        background-color: $color;
        box-shadow: 0 0 0 1px $color;

        &:not(.hover-disabled) {
          &:hover {
            background-color: offset($name, 5%);
            box-shadow: 0 0 0 1px offset($name, 5%);
          }

          &:active,
          &:focus-within {
            background-color: offset($name, 5%);
            box-shadow: 0 0 0 2px offset($name, 5%);
          }
        }
      }
    }
  }

  &.outlined {
    background-color: transparent;

    @each $name, $color in $colors {
      &.#{$name} {
        box-shadow: 0 0 0 1px $color;

        &:not(.hover-disabled) {
          &:hover {
            background-color: rgba($color, 0.1);
            box-shadow: 0 0 0 1px offset($name, 5%);
          }

          &:active,
          &:focus-within {
            background-color: rgba($color, 0.1);
            box-shadow: 0 0 0 2px offset($name, 10%);
          }
        }
      }
    }
  }

  &.flat {
    background-color: transparent;

    @each $name, $color in $colors {
      &.#{$name} {
        &:not(.hover-disabled) {
          &:hover {
            background-color: rgba($color, 0.1);
            box-shadow: 0 0 0 1px rgba($color, 0.1);
          }

          &:active,
          &:focus-within {
            background-color: rgba($color, 0.1);
            box-shadow: 0 0 0 2px rgba($color, 0.1);
          }
        }
      }
    }
  }

  &.transparent {
    background-color: transparent;

    @each $name, $color in $colors {
      &.#{$name} {
        background-color: rgba($color, 0.3);
        box-shadow: 0 0 0 1px rgba($color, 0.3);

        &:not(.hover-disabled) {
          &:hover {
            background-color: rgba($color, 0.5);
            box-shadow: 0 0 0 1px rgba($color, 0.5);
          }

          &:active,
          &:focus-within {
            background-color: rgba($color, 0.5);
            box-shadow: 0 0 0 2px rgba($color, 0.5);
          }
        }
      }
    }
  }

  &.disabled {
    @include disabled;
  }
}

.form-entry.error {
  .increment-picker {
    box-shadow: 0 0 0 1px $red;

    .increment-divider {
      border-color: $red !important;
    }

    &:hover {
      box-shadow: 0 0 0 1px $red;

      .increment-divider {
        border-color: $red !important;
      }
    }

    &:focus,
    &:focus-within {
      box-shadow: 0 0 0 2px $red;

      .increment-divider {
        border-color: $red !important;
      }
    }
  }
}
