.nav-drawer {
  @include maxScreen($sm) {
    width: 50px;
    left: -50px;
  }

  &.active {
    width: 250px;

    &.collapsed {
      width: 50px;
    }

    @include maxScreen($sm) {
      width: 65%;
      left: 0;
    }
  }

  &.collapsed {
    align-items: center;

    .nav-tabs {
      align-items: center;
      padding: 16px 0;
    }
  }

  .yakkr-banner {
    width: 60%;
    height: auto;

    padding: 32px 0 24px 24px;

    box-sizing: border-box;
    overflow: hidden;
  }

  .yakkr-logo {
    margin-top: 24px;
  }

  .nav-cta {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-bottom: 20px;

    border-radius: 5px;

    @include minScreen($sm) {
      margin-top: 31%;

      background-color: $black-4;
    }

    .cta-img {
      @include minScreen($sm) {
        width: 70%;

        margin-top: -31%;
      }

      @include maxScreen($sm) {
        width: 60%;
      }
    }

    .cta-title {
      margin-top: 8px;

      @include blatant(500);
      font-size: 18px;
      color: $white;
      text-align: center;
    }

    .cta-text {
      margin-top: 2px;

      @include inter(500);
      font-size: 16px;
      color: $gray-2;
      text-align: center;
    }

    .button {
      margin-top: 12px;
    }
  }

  .nav-tabs {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;

    height: 100%;
    width: 100%;

    padding: 2px 16px 16px 16px;

    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;

    transition: padding 300ms ease;

    .nav-tab-wrapper {
      &.sub-tab {
        transition: margin 300ms ease;

        &.selected {
          margin-top: -12px;

          .nav-tab {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }

      .nav-tab {
        width: 100%;

        .button-inner {
          span {
            flex-grow: 1;
            display: inline-block;

            width: 100%;

            text-align: left;
          }
        }
      }
    }
  }
}
