&.fan-testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  margin-top: 96px;
  padding-top: 48px;
  padding-bottom: 48px;

  // Scale up
  @include minScreen($sl) {
    gap: 64px;

    margin-top: 128px;
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @include minScreen($ul) {
    gap: 96px;

    margin-top: 192px;
    padding-top: 96px;
    padding-bottom: 96px;
  }

  // Scale down

  @include maxScreen($sm) {
    gap: 32px;

    margin-top: 64px;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @include maxScreen($xs) {
    gap: 24px;

    padding-top: 24px;
    padding-bottom: 24px;
  }

  &:not(.no-background) {
    background-color: $black-2;
  }

  .boxes {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    --gap: 48px;

    $gap: var(--gap);
    gap: $gap;

    // Scale up
    @include minScreen($sl) {
      --gap: 64px;
    }

    @include minScreen($ul) {
      --gap: 96px;
    }

    // Scale down
    @include maxScreen($lg) {
      grid-template-columns: 1fr 1fr;
    }

    @include maxScreen($md) {
      grid-template-columns: 1fr;
    }

    @include maxScreen($sm) {
      --gap: 32px;
    }

    @include maxScreen($xs) {
      --gap: 24px;
    }

    .fan-testimonial {
      padding: 24px;

      background-color: $black-3;

      box-sizing: border-box;

      // Scale up
      @include minScreen($sl) {
        padding: 32px;
      }

      @include minScreen($ul) {
        padding: 48px;
      }

      @include minScreen($xl) {
        min-width: 450px;
      }

      // Scale down
      @include maxScreen($md) {
        flex-direction: column;

        padding: 20px;
      }

      @include maxScreen($xs) {
        padding: 16px;
      }

      .fan-details {
        display: flex;
        align-items: center;
        gap: 24px;

        // Scale up
        @include minScreen($sl) {
          gap: 32px;
        }

        @include minScreen($ul) {
          gap: 48px;
        }

        // Scale down
        @include maxScreen($xs) {
          gap: 16px;
        }
      }

      .fan-testimonial-text,
      .rating-selector {
        margin-top: 24px;

        // Scale up
        @include minScreen($sl) {
          margin-top: 32px;
        }

        @include minScreen($ul) {
          margin-top: 48px;
        }

        // Scale down
        @include maxScreen($md) {
          margin-top: 20px;
        }

        @include maxScreen($xs) {
          margin-top: 16px;
        }
      }
    }
  }
}
