@import "./updateExperiencePackageMobile.scss";
@import "./updateExperiencePackageDesktop.scss";

#update-experience-package-page {
  .form-wrapper {
    .form {
      .inner {
        .form-section {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 16px;

          width: 100%;

          .any-checkbox {
            margin-bottom: 16px;
          }

          .form-row {
            .tag-line-entry {
              .input-wrapper {
                min-height: 78px;
              }
            }

            .duration-entry {
              .prompt-text {
                margin-top: 8px;

                @include inter(600);
                color: $gray-3;
                font-size: 14px;
              }
            }

            .extra-details-entry {
              .input-wrapper {
                min-height: 300px;
              }
            }

            .game-platform-selections-entry {
              .game-selector-wrapper {
                padding: 24px;

                background-color: $black-3;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
  }
}
