$weights: (
  "100": 100,
  "200": 200,
  "300": 300,
  "400": 400,
  "500": 500,
  "600": 600,
  "700": 700,
  "800": 800,
  "900": 900,
);

@each $name, $weight in $weights {
  .text-#{$name} {
    font-weight: $weight !important;
  }
}

.text-bold {
  @include inter(600);
}
.text-italic {
  font-style: italic;
}
.text-underline {
  text-decoration: underline;
}

.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}

@each $name, $gradient in $gradients {
  .text-#{$name} {
    @include textGradient($gradient);
  }
}

@each $name, $gradient in $gradients {
  .bg-#{$name} {
    background: $gradient;
  }
}

@each $name, $color in $colors {
  .text-#{$name} {
    color: $color !important;
  }
}

@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color !important;
  }
}

@each $name, $color in $colors {
  .bd-#{$name} {
    border-color: $color !important;
  }
}

@each $name, $color in $colors {
  .fill-#{$name} {
    fill: $color !important;
  }
}

@each $name, $color in $colors {
  .stroke-#{$name} {
    stroke: $color !important;
  }
}

@each $name, $color in $colors {
  .stop-#{$name} {
    stop-color: $color !important;
  }
}

@each $name, $color in $colors {
  .alpha-fill-#{$name} {
    fill: rgba($color, 0.5) !important;
  }
}

@each $name, $color in $colors {
  .extra-alpha-fill-#{$name} {
    fill: rgba($color, 0.15) !important;
  }
}

@each $name, $color in $colors {
  .alpha-text-#{$name} {
    color: rgba($color, 0.5) !important;
  }
}

@each $name, $color in $colors {
  .extra-alpha-text-#{$name} {
    color: rgba($color, 0.15) !important;
  }
}

@each $name, $color in $colors {
  .alpha-bg-#{$name} {
    background-color: rgba($color, 0.5) !important;
  }
}

@each $name, $color in $colors {
  .extra-alpha-bg-#{$name} {
    background-color: rgba($color, 0.15) !important;
  }
}

@each $name, $z-index in $z-indexes {
  .z-#{$name} {
    z-index: $z-index !important;
  }
}

.flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.flex-grow {
  flex-grow: 1 !important;
}

.flex-shrink {
  flex-shrink: 1 !important;
}

.align-start {
  align-items: flex-start !important;
}

.align-center {
  align-items: center !important;
}

.align-end {
  align-items: flex-end !important;
}

.align-base {
  align-items: baseline !important;
}

.align-stretch {
  align-items: stretch !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-base {
  justify-content: baseline !important;
}

.justify-stretch {
  justify-content: stretch !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.pos-relative {
  position: relative !important;
}

.pos-absolute {
  position: absolute !important;
}

.pos-fixed {
  position: fixed !important;
}

.vis-hidden {
  visibility: hidden !important;
}

.vis-visible {
  visibility: visible !important;
}

.display-block {
  display: block !important;
}

.display-flex {
  display: flex !important;
}

.display-inline {
  display: inline !important;
}

.display-grid {
  display: grid !important;
}

.display-none {
  display: none !important;
}

@for $i from 1 through 10 {
  .flex-#{$i} {
    flex-basis: calc(100% / #{$i});
  }
}

.gradient-title {
  @include textGradient($gradient-blend-horizontal);
  @include blatant(700);

  font-size: 48px;
  color: $white;
  font-style: italic;

  @include maxScreen($sm) {
    font-size: 32px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

.sort-arrow {
  transition: transform 300ms ease;

  &.up {
    transform: rotate(180deg);
  }
  &.down {
    transform: rotate(0);
  }
}
