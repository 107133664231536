#settings-page {
  position: relative;
  width: 100%;

  padding: 128px 0;

  @include maxScreen($md) {
    padding: 96px 0;
  }

  @include maxScreen($sm) {
    padding: 40px 0;
  }

  .settings-title {
    margin: 0 32px;

    color: $white;
    @include inter(600);

    @include maxScreen($md) {
      font-size: 24px;
      text-align: center;
    }
  }

  .tabs {
    margin: 0 16px;

    @include maxScreen($md) {
      margin: 24px auto 0 auto;
    }

    @include maxScreen($sm) {
      margin: 16px auto 0 auto;
    }
  }

  .sections-wrapper {
    display: flex;
    gap: 128px;

    margin: 32px 32px 0 32px;

    @include maxScreen($xl) {
      gap: 64px;
    }

    @include maxScreen($lg) {
      gap: 32px;
    }

    @include maxScreen($md) {
      flex-direction: column;

      gap: 24px;

      margin: 32px 0 0 0;
    }

    .sections {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &.full-width {
        .section {
          max-width: 100%;
        }
      }

      @include maxScreen($md) {
        gap: 24px;
      }

      .section {
        max-width: 75%;

        @include maxScreen($lg) {
          max-width: 100%;
        }
      }
    }
  }

  // @import "./yourYakkrPlus.scss";

  @import "./personal.scss";
  @import "./payment.scss";
  @import "./support.scss";
  @import "./statuses.scss";
}
