@keyframes top-progress {
  0% {
    width: 0%;
    left: 0;
  }
  25% {
    width: 50%;
    left: 0%;
  }
  50% {
    width: 25%;
    left: 37.5%;
  }
  75% {
    width: 50%;
    left: 50%;
  }
  100% {
    width: 0%;
    left: 100%;
  }
}

.bar-progress {
  position: relative;

  overflow: hidden;

  @each $name, $color in $colors {
    &.#{$name} {
      .inner {
        background-color: offset($name, 5%);
      }
    }
    &.back-#{$name} {
      background-color: offset($name, 5%);
    }
  }

  &.linear {
    .inner {
      animation: top-progress 2000ms infinite linear;
    }
  }

  &.percent {
    .inner {
      transition: all 500ms ease;
    }
  }

  .inner {
    position: absolute;
    height: 4px;
    width: 100px;
  }
}
