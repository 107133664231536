#consultancy-landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden;

  .yakkr-banner {
    position: absolute;

    top: 0;
    left: 0;
    margin: 64px 96px;

    z-index: 100;

    @include maxScreen($md) {
      right: 0;
      margin: 32px auto;
    }
  }

  .discord-icon {
    position: fixed;

    right: 0;
    bottom: 0;

    margin: 16px;
  }

  .pane {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    box-sizing: border-box;

    @import "./panes/topSplash/topSplash.scss";
    @import "./panes/explanation/explanation.scss";
    @import "./panes/howItWorks/howItWorks.scss";
    @import "./panes/cards/cards.scss";
    @import "./panes/features/features.scss";
    @import "./panes/whyUseUs/whyUseUs.scss";
    @import "./panes/lastQuestions/lastQuestions.scss";

    @include maxScreen($md) {
      padding: 0 32px;
    }

    @include maxScreen($sm) {
      padding: 0 16px;
    }

    @include maxScreen($xs) {
      padding: 0 8px;
    }

    .inner {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 65%;

      @include maxScreen($xl) {
        width: 75%;
      }

      @include maxScreen($lg) {
        width: 85%;
      }

      @include maxScreen($md) {
        width: 95%;
      }

      @include maxScreen($sm) {
        width: 100%;
      }

      &::after {
        content: "";

        position: absolute;

        width: 0;

        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;

        border-left: 32px solid transparent;
        border-right: 32px solid transparent;

        border-top-width: 30px;
        border-top-style: solid;

        transform: translateY(100%);
        z-index: 100;
      }

      .flow-icon {
        @include size(66px);

        @include maxScreen($lg) {
          @include size(60px);
        }

        @include maxScreen($md) {
          @include size(48px);
        }

        @include maxScreen($sm) {
          @include size(32px);
        }
      }

      .flow-sub-title {
        width: 65%;

        @include blatant(700);
        font-size: 48px;
        text-align: center;
        line-height: 1.1em;

        @include maxScreen($lg) {
          width: 75%;

          font-size: 40px;
        }

        @include maxScreen($md) {
          width: 85%;

          font-size: 32px;
        }

        @include maxScreen($sm) {
          width: 90%;

          font-size: 24px;
        }

        @include maxScreen($xs) {
          font-size: 16px;
        }

        &.full-width {
          width: 100%;
        }
      }

      .flow-text {
        width: 65%;

        @include inter(500);
        color: $gray-1;

        @include maxScreen($lg) {
          width: 75%;
        }

        @include maxScreen($md) {
          width: 85%;
        }

        @include maxScreen($sm) {
          width: 90%;
        }

        @include maxScreen($xs) {
          font-size: 10px;
        }

        &.full-width {
          width: 100%;
        }

        span {
          @include inter(800);
        }
      }

      .landing-testimonial {
        width: 65%;

        background: $gradient-blend-horizontal;
        border-radius: 15px;

        @include maxScreen($lg) {
          width: 75%;
        }

        @include maxScreen($md) {
          width: 85%;
        }

        @include maxScreen($sm) {
          width: 90%;

          margin-top: 48px;
        }

        .testimonial-inner {
          margin: 5px;

          padding: 48px 80px;

          background-color: $white;
          border-radius: 15px;

          @include maxScreen($md) {
            padding: 32px 48px;
          }

          @include maxScreen($sm) {
            padding: 24px 32px;
          }

          .user {
            display: flex;
            align-items: center;
            gap: 24px;

            @include maxScreen($md) {
              gap: 20px;
            }

            @include maxScreen($sm) {
              gap: 16px;
            }

            .user-details {
              display: flex;
              flex-direction: column;
              gap: 2px;

              .display-name {
                @include inter(800);
                font-size: 24px;
                color: $gray-1;

                @include maxScreen($lg) {
                  font-size: 20px;
                }

                @include maxScreen($md) {
                  font-size: 18px;
                }

                @include maxScreen($sm) {
                  font-size: 16px;
                }

                @include maxScreen($xs) {
                  font-size: 14px;
                }
              }

              .tag-line {
                @include inter(500);
                font-size: 18px;
                color: $gray-1;

                @include maxScreen($lg) {
                  font-size: 16px;
                }

                @include maxScreen($md) {
                  font-size: 16px;
                }

                @include maxScreen($sm) {
                  font-size: 14px;
                }

                @include maxScreen($xs) {
                  font-size: 12px;
                }
              }
            }
          }

          .flow-text {
            margin-top: 24px;

            font-size: 22px;

            @include maxScreen($lg) {
              font-size: 16px;

              font-size: 20px;
            }

            @include maxScreen($md) {
              margin-top: 20px;

              font-size: 18px;
            }

            @include maxScreen($sm) {
              margin-top: 16px;

              font-size: 16px;
            }

            @include maxScreen($xs) {
              font-size: 12px;

              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

#consultancy-sign-up-modal {
  .modal {
    .modal-content {
      .auth-wrapper {
        .content-wrapper {
          .form-wrapper {
            .form {
              .inner {
                .completion-text-row {
                  p {
                    @include inter(400);
                    font-size: 20px;
                    color: $white;
                  }
                }

                .auth-button-row {
                  flex-direction: column;
                  align-items: stretch;
                }
              }
            }
          }
        }
      }
    }
  }
}
