.yakkr-logo {
  transition: color 300ms ease;

  &.disabled {
    @include disabled;
  }

  &.clickable {
    cursor: pointer;
  }

  @each $name, $color in $colors {
    &.#{$name} {
      color: $color;

      &.clickable {
        &:hover {
          color: offset($name, 20%);
        }
      }
    }
  }
}
