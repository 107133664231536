.section {
  &.dividers {
    .section-table,
    .section-flex {
      .section-bar,
      .section-row,
      .section-head {
        &:not(:last-child) {
          border-bottom-style: solid;
          border-width: 1px;
          border-color: $gray-3;
        }
      }
    }
  }

  .section-back {
    margin: 0 0 16px 12px;
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include maxScreen($md) {
      padding: 0 16px;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 12px;
      color: $gray-3;

      @include inter(600);

      @include maxScreen($md) {
        font-size: 16px;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .section-flex,
  .section-table {
    position: relative;

    width: 100%;

    border-radius: 5px;

    overflow: hidden;

    .data-check {
      background-color: $black-3;

      .progress {
        transform: scale(0.75);
      }
    }

    .form-wrapper {
      margin: 0;
      .form .inner {
        gap: 0;
        padding: 0;
        margin: 0;
      }
    }

    @include maxScreen($sm) {
      border-radius: 0;
    }

    .section-bar,
    .section-row,
    .section-head {
      position: relative;

      background-color: $black-2;

      p:not(.text):not(.footnote) {
        font-size: 14px;
        @include inter(500);
        text-align: center;

        &:not(.no-clamp) {
          @include lineClamp(2);
        }

        @include maxScreen($sm) {
          font-size: 12px;
        }
      }

      label {
        display: block;
        @include inter(400);
        font-size: 14px;
        color: $gray-2;
      }

      .group {
        display: flex;
        align-items: center;
        gap: 8px;

        overflow: hidden;

        &.actions {
          justify-content: center;
          flex-grow: 1;
        }
      }
    }

    .section-head {
      z-index: 100;
    }

    .section-row {
      p:not(.text):not(.footnote) {
        word-wrap: break-word;
      }
    }
  }

  .section-flex {
    display: flex;
    flex-direction: column;

    min-height: 90px;

    margin-top: 16px;

    .section-bar {
      display: flex;
      align-items: center;
      gap: 24px;

      padding: 0 24px;

      @include maxScreen($md) {
        padding: 0 16px;
      }

      &:not(.dynamic-height):not(.can-wrap) {
        height: 90px;
        max-height: 90px;

        @include maxScreen($md) {
          height: 80px;
          max-height: 80px;
        }
      }

      &.dynamic-height {
        padding: 24px;

        @include maxScreen($md) {
          padding: 16px;
        }
      }

      &.can-wrap {
        flex-wrap: wrap;

        padding: 16px;
      }

      .pair {
        flex-grow: 1;

        p {
          text-align: left !important;
          color: $white;
        }
      }

      .form-row {
        flex-grow: 1;

        @include maxScreen($sm) {
          gap: 4px;
        }

        label {
          flex-shrink: 0;
          flex-basis: 25%;

          p {
            text-align: left !important;
          }
        }
      }
    }
  }

  .section-table {
    min-height: 150px;

    margin-top: 16px;

    table-layout: fixed;
    border-collapse: collapse;

    .data-check {
      padding-top: 60px;

      box-sizing: border-box;
    }

    .section-row,
    .section-head {
      vertical-align: middle;

      width: 100%;

      th {
        color: $white;
      }

      td {
        color: $gray-3;
      }

      td,
      th {
        &:first-child {
          padding-left: 24px;

          @include maxScreen($md) {
            padding-left: 8px;
          }
        }
        &:last-child {
          padding-right: 24px;

          @include maxScreen($md) {
            padding-right: 8px;
          }

          @include maxScreen($md) {
            width: 55px;
          }
        }
      }
    }

    .section-head {
      height: 60px;
      max-height: 60px;

      .sort-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        cursor: pointer;
      }
    }

    .section-row {
      height: 90px;
      max-height: 90px;

      @include maxScreen($md) {
        height: 80px;
        max-height: 80px;
      }
    }
  }

  .section-more {
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 16px;

    margin-top: 8px;

    p {
      font-size: 14px;
      @include inter(500);

      cursor: pointer;
    }
  }

  .section-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    margin: 16px;
  }
}
