&.cards {
  background-color: $black-2;

  .inner {
    &::after {
      border-top-color: $black-2;
    }
  }

  .inner {
    width: 100%;

    .flow-sub-title {
      margin-top: 64px;
    }

    .sub-text {
      margin-top: 16px;

      font-size: 18px;
      color: $white;
      text-align: center;

      @include maxScreen($lg) {
        font-size: 16px;
      }

      @include maxScreen($md) {
        font-size: 14px;
      }

      @include maxScreen($sm) {
        font-size: 12px;
      }

      @include maxScreen($xs) {
        font-size: 10px;
      }
    }

    .wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 32px;

      width: 100%;

      margin-top: 64px;

      @include maxScreen($md) {
        margin-top: 48px;
      }

      .card {
        $color: var(--color);

        display: flex;
        flex-direction: column;

        width: 450px;

        background-color: $white;
        border-radius: 17px;

        overflow: hidden;

        @include minMaxScreen($xs, $sm) {
          min-height: 600px;
        }

        @include minScreen($sm) {
          min-height: 686px;
        }

        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;

          padding: 24px 24px 0 24px;

          .card-icon {
            @include size(45px);

            color: $color;
          }
        }

        .head {
          padding: 24px;

          .price {
            position: relative;

            min-width: 402px;
            min-height: 121px;

            border-radius: 5px;

            overflow: hidden;

            .amount {
              @include inter(900);
              font-size: 100px;
              color: $color;
              line-height: 1em;

              @include maxScreen($sm) {
                font-size: 80px;
              }
            }

            .per {
              @include inter(600);
              font-size: 40px;
              color: $gray-3;

              @include maxScreen($sm) {
                font-size: 30px;
              }
            }
          }

          .title {
            @include blatant(700);
            font-size: 34px;
            color: $gray-1;
            line-height: 1em;

            @include maxScreen($sm) {
              font-size: 28px;
            }
          }
        }

        .content {
          display: flex;
          flex-direction: column;

          padding: 32px 24px;

          .prev-text {
            @include inter(700);
            font-size: 16px;
            color: $gray-1;

            @include maxScreen($sm) {
              font-size: 14px;
            }
          }

          .plus {
            margin-top: 12px;

            @include inter(700);
            font-size: 14px;
            color: $gray-1;

            @include maxScreen($sm) {
              font-size: 12px;
            }
          }

          ul.perks-list {
            li {
              margin-top: 12px;

              @include inter(500);
              font-size: 16px;
              color: $gray-1;

              @include maxScreen($sm) {
                font-size: 14px;
              }

              span {
                @include inter(800);
              }
            }
          }
        }

        .divider-2 {
          margin-top: auto;
        }

        .button {
          margin: 24px;
        }
      }
    }

    .payment-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      margin-top: 32px;

      .row {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include maxScreen($sm) {
          flex-direction: column;
          align-items: center;
        }

        p {
          white-space: nowrap;
        }
      }

      .card-row {
        display: flex;
        align-items: center;
        gap: 16px;

        svg {
          color: $gray-2;
          @include size(48px);
        }
      }

      p {
        display: inline-flex;
        align-items: center;
        gap: 8px;

        @include inter(500);
        color: $gray-2;
        font-size: 24px;
        text-align: center;

        @include maxScreen($lg) {
          font-size: 22px;
        }

        @include maxScreen($md) {
          font-size: 18px;
        }

        @include maxScreen($sm) {
          font-size: 16px;
        }

        @include maxScreen($xs) {
          font-size: 14px;
        }

        .shield-icon {
          @include size(24px);
        }

        .stripe-banner {
          height: 34px;
          width: auto;
        }
      }
    }

    .bottom-text {
      margin: 32px 0 64px 0;

      @include inter(500);
      font-size: 24px;
      color: $gray-3;

      @include maxScreen($lg) {
        font-size: 22px;
      }

      @include maxScreen($md) {
        font-size: 18px;
      }

      @include maxScreen($sm) {
        font-size: 16px;
      }

      @include maxScreen($xs) {
        font-size: 14px;
      }

      span {
        @include inter(800);
        color: $purple-5;
      }
    }
  }
}
