#update-experience-package-page.desktop {
  .pro-tip {
    display: flex;
    align-items: center;
    gap: 8px;

    padding: 12px;
    margin-top: 12px;

    background-color: $black-2;

    .bulb-icon {
      @include size(40px);
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        @include blatant(700);
        font-size: 20px;
      }

      .text {
        @include inter(500);
        font-size: 14px;
        color: $gray-3;
      }
    }
  }
}
