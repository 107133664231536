&.cig-card-carousel {
  padding-top: 32px;

  // Scale up
  @include minScreen($sl) {
    padding-top: 64px;
  }

  @include minScreen($ul) {
    padding-top: 96px;
  }
}
