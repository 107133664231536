.room-drawer {
  background-color: $black-2;

  .drawer-wrapper {
    display: flex;
    flex-direction: column;

    height: 100%;

    padding: 32px 0;

    overflow: hidden;

    .drawer-title {
      margin: 0 32px;

      color: $white;
      @include inter(400);
      font-size: 24px;
    }

    .tabs {
      margin: 24px 32px -1px 32px;

      @include maxScreen($sm) {
        margin: 24px 16px -1px 16px;
      }
    }

    .tab-content {
      &:not(.visible) {
        display: none !important;
      }

      &.channels-tab {
        display: flex;
        flex-direction: column;
        gap: 4px;

        margin-top: 16px;

        overflow-y: auto;
        overflow-x: hidden;

        .channel {
          margin: 0 24px;
          padding: 12px;

          border-radius: 5px;

          transition: background-color 300ms ease;

          &.disabled {
            @include disabled;
          }

          &:not(.active) {
            cursor: pointer;
          }

          &.active {
            .channel-header {
              .left {
                .channel-name {
                  color: $gray-3;
                }
              }
            }
          }

          &:hover,
          &.active {
            background-color: $black-3;

            .participants {
              background-color: $black-2;
            }
          }

          .channel-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
              display: flex;
              align-items: center;
              gap: 8px;

              svg {
                @include size(20px);

                color: $gray-3;
              }

              .channel-name {
                @include inter(500);
                font-size: 16px;
                color: $gray-2;
              }
            }

            .right {
              svg {
                @include size(20px);

                color: $gray-1;
              }
            }
          }

          .participants {
            display: flex;
            flex-direction: column;

            margin-top: 12px;

            background-color: $black-3;
            border-radius: 5px;

            transition: background-color 300ms ease;
            overflow: hidden;

            .participant {
              display: flex;
              justify-content: space-between;
              gap: 16px;

              padding: 12px;

              @include maxScreen($sm) {
                gap: 12px;
                flex-direction: column;
              }

              &.highlight {
                @include flash($white, $black-3, 500ms);
              }

              .empty-wrapper {
                position: relative;
                display: flex;
                align-items: center;
                gap: 8px;

                svg {
                  @include size(24px);
                  color: $gray-2;
                }

                .empty-text {
                  @include inter(400);
                  font-size: 14px;
                  color: $gray-2;
                }
              }

              .details {
                flex-grow: 1;
                position: relative;
                display: flex;
                align-items: center;
                gap: 8px;

                min-width: 0;

                span {
                  display: flex;

                  svg {
                    color: $white;
                    @include size(16px);
                  }
                }

                .display-name {
                  flex-grow: 1;
                  color: $white;
                  font-size: 15px;

                  @include textOverflow;

                  &.is-cig {
                    @include inter(500);
                  }
                }
              }

              .actions {
                display: flex;
                align-items: center;
                gap: 6px;
              }
            }
          }
        }

        .open-session-button {
          width: fit-content;

          margin: 16px auto 0 auto;
        }
      }

      &.chat-tab {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 16px;

        padding: 32px 32px 2px 32px;

        overflow: hidden;

        @include maxScreen($sm) {
          padding: 32px 16px 2px 16px;
        }

        .messages {
          display: flex;
          flex-direction: column;
          gap: 16px;

          padding-right: 8px;

          overflow: auto;

          .message {
            display: flex;
            flex-direction: column;
            gap: 4px;

            padding: 8px 20px;

            border-radius: 10px;

            &.event {
              flex-direction: row;
              align-items: center;
              gap: 12px;

              background-color: $purple;

              padding: 8px 12px;

              svg {
                color: $white;
                @include size(20px);
              }

              .text {
                p {
                  &:first-of-type {
                    font-size: 12px;
                    @include inter(600);
                  }

                  &:last-of-type {
                    font-size: 13px;
                    @include inter(400);
                  }
                }
              }
            }

            &.not-own {
              margin-right: 32px;

              background-color: $black-4;
            }

            &.is-own {
              margin-left: 32px;

              background-color: $gray-1;
            }

            .message-top {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .message-author {
                color: $white;
                font-size: 14px;
                @include inter(600);
                cursor: pointer;
              }

              .message-time {
                color: $white;
                font-size: 11px;
                @include inter(600);
              }
            }

            .message-body {
              color: $white;
              font-size: 13px;
              word-wrap: break-word;
            }
          }

          .scroll-placeholder {
            visibility: none;
            height: 0;
          }
        }

        .form-wrapper {
          margin: 0;

          .form {
            .inner {
              padding: 0;
              margin: 0;

              overflow: hidden;

              .form-row {
                .form-entry {
                  padding-bottom: 0;

                  .input-wrapper {
                    margin: 2px;

                    .input {
                      min-height: 50px;
                    }
                  }

                  .validation {
                    position: relative;
                    top: 0;

                    .text {
                      white-space: normal;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.settings-tab {
        display: flex;
        flex-direction: column;
        gap: 16px;

        padding: 32px 32px 2px 32px;

        overflow-y: auto;

        .form-row {
          .form-entry {
            min-width: 0;

            label {
              color: $gray-3;
              font-size: 12px;
              @include inter(600);
              text-transform: uppercase;
            }
          }
        }

        .participant-view {
          position: relative;

          height: 200px;
        }
      }
    }
  }
}
