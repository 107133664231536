&.how-it-works {
  .boxes {
    .splash-box {
      svg {
        color: $peach-2;
      }
    }

    .divider-arrow-wrapper {
      svg {
        color: $peach-1;
      }
    }
  }
}
