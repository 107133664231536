#cig-profile-page {
  background-color: $black-1;

  .section {
    position: relative;

    @import "./sections/banner.scss";
    @import "./sections/clips.scss";
    @import "./sections/experiences.scss";
    @import "./sections/buildBar.scss";
    @import "./sections/howItWorks.scss";
    @import "./sections/events.scss";
    @import "./sections/ready.scss";
  }

  .section-title {
    position: relative;

    @include blatant(700);
    font-size: 30px;
    color: $white;

    @include maxScreen($sm) {
      font-size: 24px;
      text-align: center;
    }

    &.center {
      text-align: center;
    }

    &.small {
      font-size: 24px;

      @include maxScreen($sm) {
        font-size: 20px;
      }
    }

    .gradient {
      @include blatant(900);
      @include textGradient($gradient-faded-horizontal);
    }
  }

  .section-sub-title {
    position: relative;

    @include inter(500);
    font-size: 16px;
    color: $gray-1;

    @include maxScreen($sm) {
      font-size: 14px;
      text-align: center;
    }
  }
}

.cig-calendar-modal {
  .modal {
    @include minScreen($sm) {
      height: calc(100% - 64px);
    }

    .modal-content {
      height: 100%;
    }
  }
}

.modal-wrapper.dialogue-modal.discard-modal {
  z-index: $z-tooltip;
}
