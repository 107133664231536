.flank-banner {
  overflow: visible;

  &.disabled {
    @include disabled;
  }

  &.clickable {
    cursor: pointer;
  }
}
