&.faqs {
  display: flex;
  flex-direction: column;
  gap: 48px;

  margin-top: 64px;

  // Scale up
  @include minScreen($sl) {
    gap: 64px;

    margin-top: 96px;
  }

  @include minScreen($ul) {
    gap: 96px;

    margin-top: 128px;
  }

  // Scale down
  @include maxScreen($sm) {
    gap: 32px;

    margin-top: 32px;
  }

  @include maxScreen($xs) {
    gap: 24px;
  }

  .accordions {
    display: flex;
    flex-direction: column;
    gap: 16px;

    // Scale up
    @include minScreen($sl) {
      gap: 32px;
    }

    ul {
      li {
        margin-left: 12px;

        list-style: disc;

        color: inherit;
        font-family: inherit;
        font-weight: inherit;
      }
    }
  }
}
