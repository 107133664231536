.header {
  position: fixed;

  right: 0;
  top: 0;

  z-index: $z-header;
  overflow: visible;

  .header-inner {
    display: flex;
    gap: 8px;

    width: 100vw;

    padding: 32px 48px;

    background-color: $black-2;
    box-shadow: 0 0 5px $black-2;

    box-sizing: border-box;

    transition-property: padding-top, padding-bottom, padding-bottom;
    transition-duration: 300ms;
    transition-timing-function: ease;

    &:not(.scroll-top) {
      padding: 16px 48px;
    }

    @include minScreen($lg) {
      &.drawer-active {
        width: calc(100vw - 50px);

        &.drawer-expanded {
          width: calc(100vw - 250px);
        }
      }
    }

    @include maxScreen($sm) {
      height: 60px;

      &.search-only {
        .header-center,
        .header-right {
          display: none;
        }

        .header-left {
          flex-grow: 1;

          .nav-button {
            display: none;
          }

          .search-transition-wrapper {
            flex-grow: 1;

            .search-wrapper {
              width: 100%;
            }
          }
        }
      }
    }

    &.transparent {
      box-shadow: none;
      background-color: transparent;
    }

    @include maxScreen($lg) {
      padding: 24px 32px;

      &:not(.scroll-top) {
        padding: 16px 32px;
      }
    }
    @include maxScreen($sm) {
      padding: 9px 16px;

      &:not(.scroll-top) {
        padding: 9px 16px;
      }
    }

    .header-left {
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 16px;

      .yakkr-banner {
        @include minScreen($sm) {
          transform: translateY(10%);
        }
      }

      @include maxScreen($sm) {
        flex-basis: 33%;
        gap: 0;
      }

      .search-transition-wrapper {
        flex-basis: 400px;
      }
    }

    .header-center {
      flex-basis: 33%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .header-right {
      flex-grow: 1;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;

      @include minScreen($sm) {
        gap: 16px;
      }

      @include maxScreen($sm) {
        flex-basis: 33%;
      }

      .button {
        @include maxScreen($sm) {
          width: 75px;
        }
      }

      .login-button {
        @include maxScreen($sm) {
          font-size: 14px;
        }
      }
    }
  }
}
