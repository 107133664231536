#report-issue-page {
  min-height: inherit;

  background-color: $gray-3;

  .google-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    min-height: inherit;

    padding: 108px 0 0 0;

    box-sizing: border-box;

    @include maxScreen($sm) {
      padding: 0;
    }

    .google-form {
      flex-grow: 1;
      width: 100%;
    }
  }
}
