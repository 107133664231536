#experiences-manage-select-page.mobile {
  .cards {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;

    min-height: 100px;

    padding: 32px 8px;
  }
}
