.toggle-switch-wrapper {
  position: relative;
  display: flex;

  gap: 12px;

  &.has-label {
    align-items: center;
    justify-content: space-between;
  }

  @mixin sizing($height, $font-size) {
    .toggle-switch {
      width: calc($height * 1.75);
      min-width: calc($height * 1.75);
      max-width: calc($height * 1.75);
      height: $height;

      .slider {
        height: $height;
      }
    }

    .label {
      font-size: $font-size;
    }
  }

  &.extra-small {
    @include sizing($height: 18px, $font-size: 12px);
  }

  &.small {
    @include sizing($height: 29px, $font-size: 14px);
  }

  &.medium {
    @include sizing($height: 41px, $font-size: 16px);
  }

  &.large {
    @include sizing($height: 56px, $font-size: 18px);
  }

  &.extra-large {
    @include sizing($height: 85px, $font-size: 20px);
  }

  &.contained {
    @each $name, $color in $colors {
      &.#{$name} {
        .toggle-switch {
          .slider {
            background-color: $color;
            box-shadow: 0 0 0 1px $color;
          }

          input {
            &:hover + .slider:not(.hover-disabled) {
              background-color: offset($name, 5%);
              box-shadow: 0 0 0 1px offset($name, 5%);
            }
            &:active + .slider:not(.hover-disabled) {
              background-color: offset($name, 5%);
              box-shadow: 0 0 0 2px offset($name, 5%);
            }
          }
        }
      }

      &.#{$name}-thumb {
        .toggle-switch {
          .slider {
            &::before {
              background-color: $color;
              box-shadow: 0 0 0 1px $color;
            }
          }
        }
      }
    }
  }

  &.outlined {
    @each $name, $color in $colors {
      &.#{$name} {
        .toggle-switch {
          .slider {
            box-shadow: 0 0 0 1px $color;
          }

          input {
            &:hover + .slider:not(.hover-disabled) {
              background-color: rgba($color, 0.1);
              box-shadow: 0 0 0 1px offset($name, 5%);
            }
            &:active + .slider:not(.hover-disabled) {
              background-color: rgba($color, 0.1);
              box-shadow: 0 0 0 2px offset($name, 10%);
            }
          }
        }
      }

      &.#{$name}-thumb {
        .toggle-switch {
          .slider {
            &::before {
              background-color: $color;
              box-shadow: 0 0 0 1px $color;
            }
          }
        }
      }
    }
  }

  &.flat {
    @each $name, $color in $colors {
      &.#{$name} {
        .toggle-switch {
          .slider {
            background-color: rgba($color, 0.05);
            box-shadow: 0 0 0 1px rgba($color, 0.05);
          }

          input {
            &:hover + .slider:not(.hover-disabled) {
              background-color: rgba($color, 0.1);
              box-shadow: 0 0 0 1px rgba($color, 0.1);
            }
            &:active + .slider:not(.hover-disabled) {
              background-color: rgba($color, 0.1);
              box-shadow: 0 0 0 2px rgba($color, 0.1);
            }
          }
        }
      }
    }
  }

  &.transparent {
    @each $name, $color in $colors {
      &.#{$name} {
        .toggle-switch {
          .slider {
            background-color: rgba($color, 0.3);
            box-shadow: 0 0 0 1px rgba($color, 0.3);
          }

          input {
            &:hover + .slider:not(.hover-disabled) {
              background-color: rgba($color, 0.5);
              box-shadow: 0 0 0 1px rgba($color, 0.5);
            }
            &:active + .slider:not(.hover-disabled) {
              background-color: rgba($color, 0.5);
              box-shadow: 0 0 0 2px rgba($color, 0.5);
            }
          }
        }
      }
    }
  }

  @each $name, $color in $colors {
    &.#{$name}-thumb {
      .toggle-switch {
        .slider {
          &::before {
            background-color: $color;
            box-shadow: 0 0 0 1px $color;
          }
        }
      }
    }
  }

  .toggle-switch {
    position: relative;

    input {
      width: 0;
      height: 0;

      opacity: 0;

      &:checked + .slider {
        &::before {
          transform: translateX(75%) scale(0.8);
        }
      }

      &:disabled + .slider {
        @include disabled;
      }
    }

    .slider {
      position: absolute;

      inset: 0;

      border-radius: 100px;

      transition: background-color 300ms ease, border-radius 300ms ease, box-shadow 75ms ease-out;

      cursor: pointer;

      &:before {
        position: absolute;

        height: 100%;

        padding-right: 57.125%;

        border-radius: 50%;

        content: "";
        transition: transform 300ms ease-out, background-color 300ms ease;
        transform: scale(0.8);
      }
    }
  }

  .label {
    @include inter(500);

    @each $name, $color in $colors {
      &.#{$name}-text {
        color: $color;
      }
    }
  }
}
