.game-card {
  position: relative;

  border-radius: 0.05em;

  height: 1.35em;

  overflow: hidden;

  .background-gradient {
    position: absolute;

    width: 100%;

    inset: 0;

    background: linear-gradient(180deg, rgba($black, 0) 0%, $black 100%);

    z-index: -100;
  }

  .stats-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    height: 100%;

    padding: 0.06em 0.04em;

    box-sizing: border-box;

    .stats-value {
      font-size: 0.2em;
      @include blatant(700);
      color: $white;
      line-height: 1em;
    }

    .stats-name {
      font-size: 0.08em;
      @include inter(500);
      color: rgba($white, 0.6);
      line-height: 1em;
    }
  }
}
