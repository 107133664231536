.form-box {
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 24px;
  margin-top: 16px;

  border-radius: 11.25px;
  background-color: $black-2;
  border: 1px solid $black-3;

  text-align: left;

  @include maxScreen($sm) {
    padding: 18px;
  }

  .text {
    @include inter(500);
    font-size: 15.75px;
    color: $white;
    line-height: 1.2em;

    word-wrap: break-word;

    @include maxScreen($sm) {
      font-size: 12px;
    }

    a {
      @include inter(700);
    }

    &.large {
      margin: 4px 0 8px 0;

      color: $white;
      font-size: 16px;
      @include inter(600);

      @include maxScreen($xl) {
        font-size: 15px;
      }

      @include maxScreen($sm) {
        font-size: 14px;
      }
    }

    &.gradient {
      @include inter(900);
      @include textGradient($gradient-faded-horizontal);
    }
  }
}
