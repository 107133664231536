.rating-selector {
  display: flex;

  &.read-only {
    .star-button {
      cursor: default;
      pointer-events: none;
    }
  }

  @mixin sizing($spacing, $icon-size) {
    gap: $spacing;

    .star-button {
      svg {
        @include size($icon-size);
      }
    }
  }

  &.ultra-small {
    @include sizing($spacing: 3px, $icon-size: 16px);
  }

  &.extra-small {
    @include sizing($spacing: 4px, $icon-size: 22px);
  }

  &.small {
    @include sizing($spacing: 2px, $icon-size: 29px);
  }

  &.medium {
    @include sizing($spacing: 3px, $icon-size: 41px);
  }

  &.large {
    @include sizing($spacing: 4px, $icon-size: 56px);
  }

  &.extra-large {
    @include sizing($spacing: 5px, $icon-size: 85px);
  }

  .star-button {
    padding: 0;

    border: none;
    background-color: transparent;

    cursor: pointer;
    transition: transform 300ms ease;

    svg {
      transition: transform 75ms ease;
    }

    &:disabled {
      @include disabled;
    }

    &.selected {
      transform: rotate(72deg);
    }

    &:active {
      svg {
        transform: scale(1.25);
      }
    }

    &:not(.active):not(.selected) {
      svg {
        color: $gray-1;
      }
    }
  }

  @each $name, $color in $colors {
    &.#{$name} {
      .star-button {
        &.active,
        &.selected {
          svg {
            color: $color;
          }
        }
      }
    }
  }
}

.form-entry.error {
  .rating-selector {
    .star-button {
      svg {
        color: $red !important;
      }
    }
  }
}
