&.split-views {
  .split-view-wrapper {
    .split-view {
      @include maxScreen($md) {
        flex-direction: column-reverse;
      }

      .content-wrapper.alt {
        flex-basis: 40%;

        .head {
          display: flex;
          align-items: center;
          gap: 32px;

          .bar {
            flex-grow: 1;

            height: 10px;

            background: $gradient-orange-horizontal;
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: 12px;

          margin-top: 8px;

          li {
            display: flex;
            align-self: center;
            gap: 12px;

            width: 100%;

            @include inter(600);
            font-size: 20.5px;
            color: $white;

            @include maxScreen($lg) {
              font-size: 18px;
            }

            @include maxScreen($md) {
              font-size: 16px;
            }

            svg {
              @include size(20px);

              color: $peach-2;

              @include maxScreen($lg) {
                @include size(18px);
              }

              @include maxScreen($md) {
                @include size(16px);
              }
            }
          }
        }
      }
    }
  }
}
