&.split-views {
  padding: 0 1px;

  .splash-text {
    @include inter(500);
    color: $gray-3;

    .gradient {
      @include inter(900);
      @include textGradient($gradient-dark-orange-horizontal);
    }
  }

  .split-view-wrapper {
    .split-view {
      align-items: center;

      min-height: 400px;

      padding: 16px 128px 0 128px;

      box-sizing: border-box;

      @include maxScreen($xl) {
        padding: 16px 64px 0 64px;
      }

      @include maxScreen($md) {
        padding: 64px 48px 48px 48px;
      }

      @include maxScreen($sm) {
        padding: 64px 16px 48px 16px;
      }
    }

    &:nth-of-type(2n) {
      background-color: $black-2;

      &:after {
        content: "";

        position: absolute;

        bottom: 0;
        left: 0;

        border-left: 25vw solid transparent;
        border-right: 25vw solid transparent;

        border-top: 3vw solid $black-2;

        transform: translateY(100%);

        @include maxScreen($md) {
          border-left: 35vw solid transparent;
          border-right: 35vw solid transparent;
        }
      }

      @include minScreen($md) {
        &:before {
          content: "";

          position: absolute;

          bottom: 0;
          right: 0;

          border-left: 25vw solid transparent;
          border-right: 25vw solid transparent;

          border-top: 2vw solid $black-2;

          transform: translateY(100%);
        }
      }

      .split-view {
        &:after {
          content: "";

          position: absolute;

          top: 0;
          left: 5vw;

          border-left: 22.5vw solid transparent;
          border-right: 22.5vw solid transparent;

          border-top: 3vw solid $black-1;

          @include maxScreen($md) {
            left: unset;
            right: 10vw;

            border-left: 30vw solid transparent;
            border-right: 30vw solid transparent;
          }
        }

        @include minScreen($md) {
          &:before {
            content: "";

            position: absolute;

            top: 0;
            right: 10vw;

            border-left: 20vw solid transparent;
            border-right: 20vw solid transparent;

            border-top: 2vw solid $black-1;
          }
        }
      }
    }
  }
}
