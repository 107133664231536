&.how-it-works {
  background-color: $off-white;

  .inner {
    &::after {
      border-top-color: $off-white;
    }

    padding-top: 64px;
  }

  .steps-wrapper {
    position: relative;

    width: 65%;

    margin: 48px 0 40px 0;

    @include maxScreen($lg) {
      width: 75%;
    }

    @include maxScreen($md) {
      width: 85%;
    }

    @include maxScreen($sm) {
      width: 90%;
    }

    .arrow {
      display: block;

      width: 61px;
      height: auto;

      @include maxScreen($lg) {
        width: 55px;
      }

      @include maxScreen($md) {
        width: 50px;
      }

      @include maxScreen($sm) {
        width: 45px;
      }

      @include maxScreen($xs) {
        width: 40px;
      }
    }

    .steps {
      position: absolute;
      display: flex;
      flex-direction: column;

      inset: 0;

      .step {
        display: flex;
        align-items: center;
        gap: 32px;

        margin-bottom: 6.5%;

        @include maxScreen($lg) {
          gap: 24px;
        }

        @include maxScreen($md) {
          gap: 16px;
        }

        @include maxScreen($sm) {
          gap: 12px;
        }

        @include maxScreen($xs) {
          gap: 8px;
        }

        .number {
          align-self: flex-start;
          display: flex;
          align-items: center;
          justify-content: center;

          @include size(61px);

          background-color: $white;
          border: 1px solid $stained-white;
          border-radius: 50%;

          @include inter(700);
          font-size: 26px;
          color: $gray-1;

          box-sizing: border-box;

          @include maxScreen($lg) {
            @include size(55px);

            font-size: 24px;
          }

          @include maxScreen($md) {
            @include size(50px);

            font-size: 22px;
          }

          @include maxScreen($sm) {
            @include size(45px);

            font-size: 20px;
          }

          @include maxScreen($xs) {
            @include size(40px);

            font-size: 18px;
          }
        }

        .text {
          @include inter(500);
          font-size: 20px;
          color: $gray-1;

          @include maxScreen($lg) {
            font-size: 18px;
          }

          @include maxScreen($md) {
            font-size: 16px;
          }

          @include maxScreen($sm) {
            font-size: 14px;
          }

          @include maxScreen($xs) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .testimonial {
    width: 65%;

    margin: 64px 0;

    background: $gradient-blend-horizontal;
    border-radius: 15px;

    @include maxScreen($lg) {
      width: 75%;
    }

    @include maxScreen($md) {
      width: 85%;
    }

    @include maxScreen($sm) {
      width: 90%;
    }

    .testimonial-inner {
      margin: 5px;

      padding: 96px 96px 16px 96px;

      background-color: $white;
      border-radius: 15px;

      @include maxScreen($xl) {
        padding: 80px 80px 12px 80px;
      }

      @include maxScreen($md) {
        padding: 48px 48px 12px 48px;
      }

      @include maxScreen($sm) {
        padding: 32px 32px 8px 32px;
      }

      .user {
        display: flex;
        align-items: center;
        gap: 24px;

        @include maxScreen($md) {
          gap: 20px;
        }

        @include maxScreen($sm) {
          gap: 16px;
        }

        .user-details {
          display: flex;
          flex-direction: column;
          gap: 2px;

          .display-name {
            @include inter(800);
            font-size: 30px;
            color: $gray-1;

            @include maxScreen($lg) {
              font-size: 26px;
            }

            @include maxScreen($md) {
              font-size: 24px;
            }

            @include maxScreen($sm) {
              font-size: 22px;
            }

            @include maxScreen($xs) {
              font-size: 20px;
            }
          }

          .tag-line {
            @include inter(500);
            font-size: 26px;
            color: $gray-1;

            @include maxScreen($lg) {
              font-size: 22px;
            }

            @include maxScreen($md) {
              font-size: 20px;
            }

            @include maxScreen($sm) {
              font-size: 18px;
            }

            @include maxScreen($xs) {
              font-size: 16px;
            }
          }
        }
      }

      .statement {
        margin-top: 40px;

        font-size: 28px;

        @include maxScreen($xl) {
          font-size: 26px;
        }

        @include maxScreen($lg) {
          font-size: 24px;
        }

        @include maxScreen($md) {
          margin-top: 32px;

          font-size: 22px;
        }

        @include maxScreen($sm) {
          margin-top: 16px;

          font-size: 20px;
        }

        @include maxScreen($xs) {
          font-size: 16px;
        }
      }

      .sub-title {
        margin-top: 40px;

        @include blatant(700);
        font-size: 36px;
        color: $gray-1;
        text-align: center;

        @include maxScreen($lg) {
          font-size: 30px;
        }

        @include maxScreen($md) {
          margin-top: 32px;

          font-size: 26px;
        }

        @include maxScreen($sm) {
          margin-top: 16px;

          font-size: 24px;
        }

        @include maxScreen($xs) {
          font-size: 22px;
        }
      }

      .what-we-did {
        margin-top: 40px;

        font-size: 20px;

        @include maxScreen($lg) {
          font-size: 18px;
        }

        @include maxScreen($md) {
          margin-top: 32px;

          font-size: 16px;
        }

        @include maxScreen($sm) {
          margin-top: 16px;

          font-size: 14px;
        }

        @include maxScreen($xs) {
          font-size: 12px;
        }

        &:not(.reading-more) {
          @include lineClamp(11);
        }
      }

      .button {
        margin: 16px auto 0 auto;

        &.reading-more {
          .button-icon {
            transform: rotate(180deg);
          }
        }

        .button-icon {
          transition: transform 300ms ease;
        }
      }
    }
  }
}
