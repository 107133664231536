.avatar-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &.circle {
    border-radius: 50%;
  }
  &.rounded {
    border-radius: 6px;
  }
  &.square {
    border-radius: 0;
  }

  & > *:not(p) {
    width: 100%;
    height: 100%;
  }
}

.avatar-wrapper-fallback {
  background-color: $gray-1;
}

.avatar-icon {
  color: $white;
  @include size(0.75em);
}

.avatar-fallback {
  flex-grow: 1;
  text-align: center;
  justify-content: center;

  color: $white;
  @include blatant(400);
  font-size: 0.75em;
}

.avatar-img {
  object-fit: cover;
}
