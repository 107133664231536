&.top-splash {
  padding-bottom: 64px;

  .layer.gradient {
    background: linear-gradient(to top, $peach-1 0%, rgba($peach-1, 0) 100%);
  }

  .cta {
    flex-basis: 40%;

    .title {
      @include leagueGothic(500, italic);
    }

    .sub-title {
      @include inter(600);
      color: $white;
    }

    .gradient {
      @include inter(900);
      @include textGradient($gradient-dark-orange-horizontal);
    }
  }
}
