@mixin disabled() {
  opacity: 0.5;

  transition: opacity 500ms ease;

  pointer-events: none;
}

@mixin hideScrollbar {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin size($size) {
  width: $size;
  height: $size;
  min-width: $size;
  min-height: $size;
  max-width: $size;
  max-height: $size;
}

@mixin textOverflow() {
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

@mixin lineClamp($lines) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  overflow: hidden;
  word-wrap: break-word;
}

@mixin longBorderBlack() {
  border-image-source: url("../media/borders/long.png");
  border-image-width: 1;
  border-image-slice: 1;
  border-image-repeat: round;
}

@mixin longBorderWhite() {
  border-image-source: url("../media/borders/long-white.png");
  border-image-width: 1;
  border-image-slice: 1;
  border-image-repeat: round;
}

@mixin backgroundGradient($gradient) {
  background: $gradient;
}

@mixin borderGradient($gradient) {
  border-image: $gradient 1;
}

@mixin fillGradient($gradient) {
  fill: $gradient;
}

@mixin textGradient($gradient) {
  display: inline;
  width: fit-content;
  background: $gradient;
  background-size: 100% 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin bannerGradient() {
  background: linear-gradient(
    to right,
    rgba($black-1, 1) 0%,
    rgba($black-1, 0.95) 25%,
    rgba($black-1, 0) 80%
  );
  @include maxScreen($sm) {
    background: linear-gradient(to right, rgba($black-1, 0.75) 0%, rgba($black-1, 0.25) 100%);
  }
}

@mixin buttonGradient(
  $gradient: $gradient-blend-horizontal,
  $orientation: horizontal,
  $duration: 4s,
  $label: 1
) {
  background: $gradient;

  animation: shift-#{$orientation}-#{$label} #{$duration} infinite linear both;
  will-change: background-position;
  animation-play-state: paused;

  @if $orientation == horizontal {
    background-size: 300% 100%;

    @keyframes shift-horizontal-#{$label} {
      0% {
        background-position: 0%;
      }
      100% {
        background-position: 300%;
      }
    }
  } @else {
    background-size: 100% 300%;

    @keyframes shift-vertical-#{$label} {
      0% {
        background-position: 0 0%;
      }
      100% {
        background-position: 0 300%;
      }
    }
  }

  &:hover:not(.hover-disabled) {
    animation-play-state: running;
  }
}

@mixin gradientGlow(
  $duration: 4s,
  $size: 20px,
  $delay: 0ms,
  $label: 1,
  $color1: $blue,
  $color2: $dark-blue,
  $color3: $purple
) {
  animation: gradient-glow-#{$label} #{$duration} infinite $delay linear forwards;
  will-change: box-shadow;

  @keyframes gradient-glow-#{$label} {
    0% {
      box-shadow: 0 0 $size $color1;
    }
    25% {
      box-shadow: 0 0 $size $color2;
    }
    50% {
      box-shadow: 0 0 $size $color3;
    }
    75% {
      box-shadow: 0 0 $size $color2;
    }
    100% {
      box-shadow: 0 0 $size $color1;
    }
  }
}

@mixin float($duration, $margin) {
  @keyframes float {
    0% {
      margin-top: 0;
    }
    50% {
      margin-top: #{$margin};
    }
    100% {
      margin-top: 0;
    }
  }

  animation: float #{$duration} infinite ease-in-out;
}

@mixin spin($duration) {
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation: spin #{$duration} infinite linear;
}

@mixin pulsate($duration) {
  @keyframes pulsate {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  animation: pulsate #{$duration} infinite linear;
}

@mixin jump($distance, $duration) {
  @keyframes jump {
    0% {
      transform: translateY(0);
    }
    15% {
      transform: translateY(-$distance);
    }
    30% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }

  animation: jump #{$duration} infinite ease;
}

@mixin maxScreen($width) {
  @media screen and (max-width: #{$width}) {
    @content;
  }
}

@mixin minScreen($width) {
  @media screen and (min-width: #{$width}) {
    @content;
  }
}

@mixin minMaxScreen($widthFrom, $widthTo) {
  @media screen and (min-width: #{$widthFrom}) and (max-width: #{$widthTo}) {
    @content;
  }
}

@mixin gloss() {
  background: $white;
  background: linear-gradient(90deg, $gray-1 0%, $black-3 20%, $black-3 80%, $gray-1 100%);
  background-size: 800% 800%;

  @keyframes gloss {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 800%;
    }
  }

  animation: gloss 16s infinite linear;
}

@mixin hang($duration, $multiplier: 1, $label: 1) {
  @keyframes hang-#{$label} {
    0% {
      transform: rotate(0);
    }
    16% {
      transform: rotate(200deg * $multiplier);
    }
    32% {
      transform: rotate(95deg * $multiplier);
    }
    48% {
      transform: rotate(155deg * $multiplier);
    }
    64% {
      transform: rotate(120deg * $multiplier);
    }
    80% {
      transform: rotate(138deg * $multiplier);
    }
    90% {
      transform: rotate(132deg * $multiplier);
    }
    100% {
      transform: rotate(135deg * $multiplier);
    }
  }

  animation: hang-#{$label} #{$duration} 1 ease-in-out both;
  animation-play-state: paused;
}

@mixin flash($flashColor, $initialColor, $duration) {
  @keyframes flash {
    0% {
      background-color: $flashColor;
    }
    10% {
      background-color: $flashColor;
    }
    100% {
      background-color: $initialColor;
    }
  }

  animation: flash $duration 1 linear;
}

@mixin scrollbar($trackColor, $thumbColor, $size: 8px) {
  scrollbar-width: thin;
  scrollbar-color: $trackColor $thumbColor;

  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-track {
    background-color: $trackColor;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumbColor;
  }
}

@function offset($name, $offset) {
  $color: map-get($colors, $name);
  @if (index($darkColors, $name)) {
    @return lighten($color, $offset);
  } @else {
    @return darken($color, $offset);
  }
}
