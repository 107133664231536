#feedback-page {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: inherit;

  .main-background-image {
    background-repeat: repeat;
    background-size: auto;

    .background-image-inner {
      background: linear-gradient(180deg, rgba($black-1, 0) 0%, rgba($black-1, 1) 95%);
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    height: 90vh;
    width: 520px;

    margin: auto;

    @include maxScreen($sm) {
      width: 90vw;
    }

    @include maxScreen($xs) {
      width: 100vw;
    }

    .box {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      width: 100%;

      background-color: $black-2;
      border-radius: 5px;

      overflow: hidden;

      .feedback-header {
        flex-basis: 30%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;

        z-index: 100;

        .background-image {
          .background-image-inner {
            background: linear-gradient(180deg, rgba($black-2, 0) 0%, rgba($black-2, 1) 90%);
          }
        }

        svg {
          color: $white;
          @include size(32px);

          @include maxScreen($sm) {
            @include size(28px);
          }
        }

        .title {
          width: 100%;

          padding: 0 16px;
          margin-bottom: 16px;

          @include blatant(700);
          font-size: 36px;
          text-align: center;
          line-height: 1em;
          @include lineClamp(3);

          box-sizing: border-box;

          @include maxScreen($sm) {
            padding: 0 12px;
            margin-bottom: 12px;

            font-size: 32px;
          }

          span {
            @include textGradient($gradient-faded-horizontal);
            line-height: 1em;
          }
        }
      }

      .form-wrapper {
        flex-basis: 70%;

        padding: 0 28px;

        @include maxScreen($sm) {
          padding: 0 16px;
        }

        @include maxScreen($xs) {
          padding: 0 8px;
        }

        .form {
          .inner {
            align-items: center;

            padding: 28px 2px;

            @include maxScreen($sm) {
              padding: 16px 2px;
            }

            @include maxScreen($xs) {
              padding: 8px 2px;
            }

            .form-rows {
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 24px;

              width: 100%;

              .suggested-amounts-row {
                flex-wrap: wrap;

                .form-entry {
                  flex-grow: 0;
                  height: auto;
                }
              }

              .rating-entry {
                align-items: center;
              }

              .rating {
                margin-top: 24px;

                @include inter(600);
                font-size: 22.5px;
                color: $gray-1;

                span {
                  color: $gray-3;
                }
              }

              .message-input {
                height: 200px;
              }
            }

            .button-wrapper {
              display: flex;
              flex-direction: column;
              gap: 16px;

              width: 100%;

              margin-top: auto;
            }
          }
        }
      }
    }
  }
}
