#bookings-page {
  position: relative;
  display: flex;

  .booking-selector {
    flex-basis: 35%;
    position: sticky;
    display: flex;
    flex-direction: column;

    height: 100vh;
    width: 35%;

    top: 0;

    @include maxScreen($xl) {
      flex-basis: 45%;
    }

    @include maxScreen($lg) {
      flex-basis: 50%;
    }

    @include maxScreen($md) {
      flex-basis: 100%;

      background-color: transparent;
    }

    .main-title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      margin: 128px 48px 0 48px;

      @include maxScreen($md) {
        margin: 96px 24px 0 24px;
      }

      @include maxScreen($sm) {
        flex-direction: column;
        justify-content: center;

        margin: 40px 0 0 0;
      }

      .main-title {
        @include inter(600);
        color: $white;

        @include maxScreen($md) {
          font-size: 24px;
        }
      }

      .group {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }

    .sections-wrapper {
      flex-grow: 1;

      min-height: 0;

      margin-top: 16px;

      @include minScreen($sm) {
        overflow: auto;
      }

      .sections {
        margin-top: 12px;

        @include maxScreen($md) {
          margin-top: 0;
        }

        .section {
          padding: 16px 48px;

          @include maxScreen($md) {
            padding: 16px 16px;
          }

          @include maxScreen($sm) {
            padding: 16px;
          }

          .section-header {
            @include maxScreen($md) {
              padding: 0;
            }

            .title {
              margin-left: 16px;

              @include inter(600);
              color: $white;
              font-size: 16px;
            }
          }

          .section-flex {
            gap: 8px;

            min-height: 80px;

            .data-check {
              border-radius: 12px;
            }

            .section-bar {
              align-items: stretch;
              gap: 0;

              padding: 12px 18px;

              border-radius: 12px;

              cursor: pointer;

              transition: background-color 300ms ease;

              @include maxScreen($sm) {
                align-items: center;

                padding: 10px 12px;
              }

              &:hover:not(.cancelled):not(.selected) {
                background-color: rgba($gray-3, 0.1);
              }

              &.selected {
                background-color: $purple;
              }

              &.cancelled {
                cursor: default;

                & > *:not(.cancelled-cover) {
                  opacity: 0.1;
                }
              }

              .avatar-wrapper {
                .avatar-group {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  background-color: $purple-4;

                  svg {
                    @include size(75%);
                    color: $white;
                  }
                }
              }

              .cancelled-cover {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;

                inset: 0;

                .cancelled-text {
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  color: $red;
                }
              }

              .text-wrapper {
                flex-grow: 1;
                display: flex;
                gap: 8px;

                min-width: 0;

                padding: 0 12px;

                @include maxScreen($sm) {
                  flex-direction: column;
                }

                p {
                  color: $white;
                  @include inter(300);
                  white-space: nowrap;

                  @include maxScreen($md) {
                    @include inter(500);
                  }

                  @include maxScreen($sm) {
                    @include inter(600);
                  }
                }

                .left-text,
                .right-text {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-around;

                  min-width: 0;

                  .pills {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 8px;

                    min-width: 0;
                  }
                }

                .left-text {
                  flex-shrink: 1;

                  @include maxScreen($sm) {
                    gap: 2px;
                  }

                  p {
                    font-size: 15px;
                    text-align: left;

                    @include maxScreen($sm) {
                      font-size: 12px;
                    }

                    &:last-child {
                      display: flex;
                      align-items: center;
                      gap: 6px;
                    }
                  }
                }

                .right-text {
                  flex-grow: 1;
                  flex-shrink: 0;

                  @include maxScreen($sm) {
                    flex-direction: row;
                    justify-content: space-between;
                  }

                  p {
                    font-size: 13px;
                    text-align: right;

                    @include maxScreen($sm) {
                      font-size: 12px;

                      &:first-of-type {
                        color: $purple-4;
                      }
                    }
                  }

                  .pill {
                    width: fit-content;
                    align-self: flex-end;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .booking-viewer {
    flex-basis: 65%;
    display: flex;
    flex-direction: column;

    height: 100%;
    min-height: 100vh;

    @include maxScreen($xl) {
      flex-basis: 55%;
    }

    @include maxScreen($lg) {
      flex-basis: 50%;
    }

    @include maxScreen($md) {
      flex-basis: 100%;
    }

    .booking-banner {
      position: relative;
      width: 100%;
      height: 350px;
      overflow: hidden;

      @include maxScreen($sm) {
        height: 200px;
      }

      .wrapper {
        position: absolute;
        inset: 0;

        @include bannerGradient();

        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 16px;

          height: calc(100% - 96px);
          width: calc(100% - 96px);

          padding: 48px;

          @include maxScreen($sm) {
            padding: 28px;
            height: calc(100% - 56px);
            width: calc(100% - 56px);
            justify-content: space-between;
          }

          .title {
            font-size: 24px;
            color: $gray-3;
            @include inter(500);

            @include maxScreen($sm) {
              font-size: 18px;
            }

            &.wrap {
              @include textOverflow();
            }
          }

          .buttons {
            display: flex;
            gap: 8px;

            .button {
              width: fit-content;

              @include maxScreen($sm) {
                align-self: flex-end;
              }
            }
          }
        }
      }
    }

    .sections-wrapper {
      flex-grow: 1;
      position: relative;
      display: flex;
      gap: 32px;

      margin: 48px 32px 32px 32px;

      @include maxScreen($xl) {
        flex-direction: column;
      }

      @include maxScreen($md) {
        margin: 32px;
      }

      @include maxScreen($sm) {
        margin: 32px 0 32px;
      }

      .sections {
        flex-basis: 50%;
        display: flex;
        flex-direction: column;

        gap: 16px;

        .section-flex {
          @include maxScreen($sm) {
            border-radius: 0;
          }

          .section-bar {
            justify-content: space-between;

            &.extra-info-bar {
              padding-right: 0;

              p {
                flex-basis: 60%;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                min-height: 0;
                height: 100%;
                max-height: 100%;

                padding: 8px 8px 8px 8px;

                box-sizing: border-box;

                span {
                  min-height: 0;
                  max-height: 100%;

                  padding-right: 8px;

                  text-align: right;
                  white-space: pre-line;

                  overflow: auto;
                }
              }
            }

            p {
              svg {
                font-size: 24px;
              }
            }
          }
        }
      }

      .none-selected-cover {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;

        inset: 0;

        svg {
          color: $gray-3;
          @include size(40px);
        }

        h3 {
          @include inter(500);
        }
      }
    }
  }
}
