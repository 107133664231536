&.ready {
  width: 80%;

  margin: 48px auto 0 auto;

  @include maxScreen($lg) {
    width: 90%;
  }

  .box {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    padding: 48px;

    background-color: $black-2;
    border-radius: 5px;

    z-index: 100;

    @include maxScreen($md) {
      flex-direction: column;
    }

    @include maxScreen($sm) {
      gap: 24px;

      padding: 24px;
    }

    .section-title {
      flex-grow: 1;
    }

    .background-image {
      inset: unset;
      right: 0;
      top: 0;
      bottom: 0;

      width: 55%;

      .background-image-inner {
        background: linear-gradient(to right, $black-2 0%, rgba($black-2, 0.5) 100%);
      }
    }
  }
}
