.fade-transition {
  &.fade-enter-active,
  &.fade-appear-active,
  &.fade-enter-done,
  &.fade-appear-done {
    opacity: 1;
  }

  &.fade-exit-active,
  &.fade-exit-done {
    opacity: 0;
  }
}
