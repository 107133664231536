.no-access-cover {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 12px;

  inset: 0;

  background-color: rgba($black-1, 0.75);
  border-radius: 10px;

  z-index: 100;

  p {
    font-size: 18px;
    @include inter(600);
    color: $white;
  }
}
