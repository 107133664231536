#experiences-request-page.mobile {
  .tabs {
    margin: 20px 20px 0 20px;

    @include maxScreen($xs) {
      margin: 20px 10px 0 10px;
    }
  }

  .form-wrapper {
    padding: 0 20px 48px 20px;

    @include maxScreen($xs) {
      padding: 0 10px 48px 10px;
    }

    .form {
      .inner {
        padding: 0 4px;
        margin: 0 4px;

        .form-section {
          gap: 16px;

          .form-row {
            .customer-requests-details-entry {
              height: 250px;
            }

            .time-entry {
              .form-label {
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;

                .controls {
                  gap: 8px;
                }
              }

              .times {
                min-height: 150px;

                margin-top: 16px;
              }
            }

            .cost-entry {
              position: relative;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              gap: 8px;

              min-height: 26px;

              margin-top: 12px;

              border-radius: 5px;

              overflow: hidden;

              .label {
                @include inter(600);
                color: $gray-2;
                font-size: 20px;
              }

              .value {
                @include inter(700);
                color: $white;
                font-size: 20px;
              }
            }

            .form-entry {
              gap: 12px;

              .checkbox-outer {
                background-color: $black-3;
              }

              .tickets-breakdown {
                padding: 0;

                background-color: transparent;

                .body {
                  background-color: $black-2;
                }
              }
            }
          }
        }
      }
    }
  }

  .form-label {
    .label {
      font-size: 22px;
    }
  }

  .flow-button-wrapper {
    position: fixed;

    width: 100vw;

    bottom: 0;
    left: 0;
    right: 0;

    padding: 16px;

    box-sizing: border-box;

    .button,
    .gradient-button {
      width: 100%;
    }
  }

  .experiences-request-summary {
    .title {
      margin: 16px 0;
    }

    .containers {
      .container {
        background-color: $black-2;
      }
    }
  }
}
