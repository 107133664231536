&.features {
  background-color: $purple-6;

  .inner {
    &::after {
      border-top-color: $purple-6;
    }
  }

  .inner {
    .flow-sub-title {
      margin-top: 64px;
    }

    .feature-sets {
      display: flex;
      flex-direction: column;
      gap: 48px;

      width: 65%;

      margin: 32px 0 64px 0;

      @include maxScreen($lg) {
        width: 75%;
      }

      @include maxScreen($md) {
        width: 85%;
      }

      @include maxScreen($sm) {
        width: 90%;
      }

      .feature-set {
        $color: var(--color);

        .feature-set-top {
          display: flex;
          align-items: center;
          gap: 12px;

          .feature-set-icon {
            @include size(45px);

            color: $color;
          }

          .feature-set-title {
            @include inter(600);
            font-size: 30px;
            color: $color;

            @include maxScreen($lg) {
              font-size: 28px;
            }

            @include maxScreen($md) {
              font-size: 26px;
            }

            @include maxScreen($sm) {
              font-size: 24px;
            }

            @include maxScreen($xs) {
              font-size: 22px;
            }
          }
        }

        .feature-set-boxes {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-areas: "box-1 box-2" "box-3 box-3";
          gap: 16px;

          margin-top: 16px;

          @mixin fullBox {
            flex-direction: row;
            align-items: center;
            gap: 32px;

            padding: 24px 64px;

            @include maxScreen($md) {
              padding: 16px 32px;
            }

            @include maxScreen($sm) {
              flex-direction: column;
              gap: 16px;

              padding: 16px 24px;
            }

            .feature-box-top {
              flex-direction: column;
              align-items: center;

              min-width: 203px;

              .feature-box-title {
                @include minScreen($md) {
                  white-space: nowrap;
                }
              }
            }
          }

          @include maxScreen($md) {
            grid-template-columns: 1fr;
            grid-template-areas: "box-1" "box-2" "box-3";
          }

          .feature-box {
            padding: 8px;

            background-color: $white;
            border-radius: 15px;

            &:nth-of-type(1) {
              grid-area: box-1;
            }

            &:nth-of-type(2) {
              grid-area: box-2;
            }

            &:nth-of-type(3) {
              grid-area: box-3;

              .feature-box-inner {
                @include fullBox;
              }
            }

            .feature-box-inner {
              display: flex;
              flex-direction: column;
              gap: 8px;

              height: 100%;
              padding: 24px 32px;

              background-color: $white;
              border-radius: 10px;

              box-sizing: border-box;

              @include maxScreen($md) {
                @include fullBox;
              }

              .feature-box-top {
                display: flex;
                flex-direction: column;
                gap: 12px;

                .feature-box-icon {
                  @include size(48px);

                  color: $color;
                }

                .feature-box-title {
                  @include inter(700);
                  font-size: 20px;
                  color: $gray-1;

                  @include maxScreen($lg) {
                    font-size: 18px;
                  }

                  @include maxScreen($md) {
                    font-size: 16px;
                    text-align: center;
                  }

                  @include maxScreen($sm) {
                    font-size: 14px;
                  }

                  @include maxScreen($xs) {
                    font-size: 12px;
                  }
                }
              }

              .feature-box-content {
                p,
                li {
                  @include inter(400);
                  font-size: 20px;
                  color: $gray-1;

                  @include maxScreen($lg) {
                    font-size: 18px;
                  }

                  @include maxScreen($md) {
                    font-size: 16px;
                  }

                  @include maxScreen($sm) {
                    font-size: 14px;
                  }

                  @include maxScreen($xs) {
                    font-size: 12px;
                  }
                }

                li {
                  list-style-type: disc;

                  margin-left: 32px;
                }
              }
            }
          }
        }
      }
    }
  }
}
