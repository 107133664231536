a,
button {
  &.button {
    display: block;

    height: fit-content;

    border: 0;
    box-shadow: 0 0 0 1px transparent;

    cursor: pointer;
    box-sizing: border-box;

    transition: background-color 300ms ease, border-radius 300ms ease, color 300ms ease,
      box-shadow 75ms ease-out, width 300ms ease;

    &:not(.wrap) {
      .button-inner {
        span {
          @include textOverflow;
        }
      }
    }

    &.do-justify {
      .button-inner {
        justify-content: var(--justify-content);
      }
    }

    &.is-ctaing {
      .button-inner {
        .button-icon:last-of-type {
          @keyframes ctaing {
            0% {
              transform: translateX(-50%);
            }
            100% {
              transform: translateX(75%);
            }
          }

          animation: ctaing 950ms infinite ease-out;
        }
      }
    }

    a {
      color: inherit;
    }

    .button-inner {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        @include inter(600);
      }

      .progress-wrapper {
        position: relative;

        .progress {
          position: absolute;

          left: 0;
        }
      }
    }

    @mixin sizing(
      $vertical-padding,
      $horizontal-padding,
      $border-radius,
      $font-size,
      $icon-size,
      $icon-spacing,
      $scale,
      $translate
    ) {
      padding: $vertical-padding $horizontal-padding;

      border-radius: $border-radius;

      font-size: $font-size;

      .button-icon {
        @include size($icon-size);

        &:first-child {
          margin-right: $icon-spacing;
        }

        &:last-child {
          margin-left: $icon-spacing;
        }
      }

      .button-inner {
        .progress-wrapper {
          @include size($icon-size);

          .progress {
            @include size($icon-size);

            transform: scale($scale) translate($translate, $translate);
          }
        }
      }

      &.rounded {
        border-radius: calc($border-radius * 4.3);
      }
    }

    &.extra-small {
      @include sizing(
        $vertical-padding: 3px,
        $horizontal-padding: 5.5px,
        $border-radius: 3px,
        $font-size: 10px,
        $icon-size: 12px,
        $icon-spacing: 4px,
        $scale: 0.175,
        $translate: -32px
      );
    }

    &.small {
      @include sizing(
        $vertical-padding: 7px,
        $horizontal-padding: 9px,
        $border-radius: 4px,
        $font-size: 12px,
        $icon-size: 15px,
        $icon-spacing: 6px,
        $scale: 0.225,
        $translate: -30px
      );
    }

    &.medium {
      @include sizing(
        $vertical-padding: 12px,
        $horizontal-padding: 18px,
        $border-radius: 6px,
        $font-size: 14px,
        $icon-size: 17px,
        $icon-spacing: 8px,
        $scale: 0.25,
        $translate: -30px
      );
    }

    &.large {
      @include sizing(
        $vertical-padding: 18px,
        $horizontal-padding: 27px,
        $border-radius: 8px,
        $font-size: 16px,
        $icon-size: 20px,
        $icon-spacing: 10px,
        $scale: 0.3,
        $translate: -28px
      );
    }

    &.extra-large {
      @include sizing(
        $vertical-padding: 28px,
        $horizontal-padding: 42px,
        $border-radius: 10px,
        $font-size: 24px,
        $icon-size: 29px,
        $icon-spacing: 14px,
        $scale: 0.4,
        $translate: -25px
      );
    }
  }

  &.icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    height: min-content;
    width: min-content;

    padding: 0;

    border: 0;
    box-shadow: 0 0 0 1px transparent;

    line-height: 0;

    box-sizing: border-box;
    cursor: pointer;

    transition: background-color 300ms ease, border-radius 300ms ease, box-shadow 75ms ease-out;

    &.rounded {
      border-radius: 50% !important;
    }

    @mixin sizing($padding, $border-radius, $icon-size, $scale, $translate) {
      @include size(calc($padding + $icon-size));

      border-radius: $border-radius;

      svg.button-icon {
        @include size($icon-size);
      }

      .progress-wrapper {
        margin: 0 auto;

        @include size($icon-size);

        .progress {
          @include size($icon-size);

          transform: scale($scale) translate($translate, $translate);
        }
      }
    }

    &.no-padding {
      @include sizing(
        $padding: 0px,
        $border-radius: 2px,
        $icon-size: 10px,
        $scale: 0.1,
        $translate: -34px
      );
    }

    &.extra-small {
      @include sizing(
        $padding: 4px,
        $border-radius: 3px,
        $icon-size: 14px,
        $scale: 0.2,
        $translate: -32px
      );
    }

    &.small {
      @include sizing(
        $padding: 7px,
        $border-radius: 4px,
        $icon-size: 22px,
        $scale: 0.3,
        $translate: -28px
      );
    }

    &.medium {
      @include sizing(
        $padding: 17px,
        $border-radius: 6px,
        $icon-size: 24px,
        $scale: 0.4,
        $translate: -27px
      );
    }

    &.large {
      @include sizing(
        $padding: 24px,
        $border-radius: 8px,
        $icon-size: 32px,
        $scale: 0.5,
        $translate: -23px
      );
    }

    &.extra-large {
      @include sizing(
        $padding: 37px,
        $border-radius: 10px,
        $icon-size: 48px,
        $scale: 0.9,
        $translate: -15px
      );
    }

    .count-circle {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      @include size(40%);

      top: 0;
      right: 0;
      margin: 10%;

      background-color: $red;
      border-radius: 50%;

      pointer-events: none;

      span {
        @include inter(400);
        color: $white;
        font-size: 0.7em;
      }
    }
  }

  &.button,
  &.icon {
    &:disabled {
      @include disabled;
    }

    &.read-only {
      pointer-events: none;
    }

    &.is-loading {
      pointer-events: none;
    }

    .button-icon.avatar-wrapper {
      transform: scale(1.25);
    }

    @each $name, $color in $colors {
      &.#{$name}-text {
        color: $color;
      }

      &.#{$name}-icon {
        .button-icon {
          color: $color;

          &.mask {
            mask-size: cover;
            mask-repeat: no-repeat;
            background-color: $color;
          }
        }
      }
    }

    &.contained {
      @each $name, $color in $colors {
        &.#{$name} {
          background-color: $color;
          box-shadow: 0 0 0 1px $color;

          &:not(.hover-disabled) {
            &:hover {
              background-color: offset($name, 5%);
              box-shadow: 0 0 0 1px offset($name, 5%);
            }

            &:active,
            &.active {
              background-color: offset($name, 5%);
              box-shadow: 0 0 0 2px offset($name, 5%);
            }
          }
        }
      }
    }

    &.outlined {
      background-color: transparent;

      @each $name, $color in $colors {
        &.#{$name} {
          box-shadow: 0 0 0 1px $color;

          &:not(.hover-disabled) {
            &:hover {
              background-color: rgba($color, 0.1);
              box-shadow: 0 0 0 1px offset($name, 5%);
            }

            &:active,
            &.active {
              background-color: rgba($color, 0.1);
              box-shadow: 0 0 0 2px offset($name, 10%);
            }
          }
        }
      }
    }

    &.flat {
      background-color: transparent;

      @each $name, $color in $colors {
        &.#{$name} {
          &:not(.hover-disabled) {
            &:hover {
              background-color: rgba($color, 0.1);
              box-shadow: 0 0 0 1px rgba($color, 0.1);
            }

            &:active,
            &.active {
              background-color: rgba($color, 0.1);
              box-shadow: 0 0 0 2px rgba($color, 0.1);
            }
          }
        }
      }
    }

    &.transparent {
      @each $name, $color in $colors {
        &.#{$name} {
          background-color: rgba($color, 0.3);
          box-shadow: 0 0 0 1px rgba($color, 0.3);

          &:not(.hover-disabled) {
            &:hover {
              background-color: rgba($color, 0.5);
              box-shadow: 0 0 0 1px rgba($color, 0.5);
            }

            &:active,
            &.active {
              background-color: rgba($color, 0.5);
              box-shadow: 0 0 0 2px rgba($color, 0.5);
            }
          }
        }
      }
    }
  }
}
