@import "./select/experiencesManageSelect.scss";
@import "./update/package/updateExperiencePackage.scss";
@import "./update/custom/updateExperienceCustom.scss";

.experiences-manage-page {
  display: flex;
  flex-direction: column;

  padding: 106px 0 64px 0;

  min-height: inherit;

  box-sizing: border-box;

  @include maxScreen($lg) {
    padding: 90px 0 48px 0;
  }

  @include maxScreen($sm) {
    padding: 16px 0;
  }

  .pro-tip,
  .form-wrapper {
    width: calc(100% - 64px);

    margin: 0 32px;
    box-sizing: border-box;

    @include maxScreen($sm) {
      width: calc(100% - 40px);

      margin: 0 20px;
    }

    @include maxScreen($xs) {
      width: calc(100% - 20px);

      margin: 0 10px;
    }
  }

  .experiences-manage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 0 32px;

    @include maxScreen($sm) {
      margin: 0 20px;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title,
      .sub-title {
        line-height: 1.2em;
      }

      .title {
        @include blatant(600);
        color: $white;
        font-size: 40px;

        @include maxScreen($xl) {
          font-size: 32px;
        }

        @include maxScreen($lg) {
          font-size: 28px;
        }

        @include maxScreen($sm) {
          font-size: 24px;
        }
      }

      .sub-title {
        @include inter(500);
        color: $gray-3;
        font-size: 20px;

        @include maxScreen($xl) {
          font-size: 18px;
        }

        @include maxScreen($lg) {
          font-size: 16px;
        }

        @include maxScreen($sm) {
          font-size: 14px;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 8px;

      .actions {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .form-wrapper {
    flex-grow: 1;

    .form {
      .inner {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 48px;

        padding: 12px 0;

        .form-row {
          .form-entry {
            .bottom-text {
              margin-top: 12px;
              @include inter(700);
              font-size: 14px;
              color: $gray-2;
              text-align: center;
            }
          }
        }

        .toggle-switch-outer {
          padding: 24px 16px;

          background-color: $black-3;
          border-radius: 5px;
        }

        .form-section-outer {
          display: flex;
          flex-direction: column;
          gap: 24px;

          flex-basis: calc(33.33% - (96px / 3));
          width: calc(33.33% - (96px / 3));

          @include maxScreen($md) {
            flex-basis: calc(50% - (48px / 2));
            width: calc(50% - (48px / 2));
          }

          .form-section-wrapper {
            padding: 16px 24px 32px 24px;

            background-color: $black-2;
            border-radius: 10px;

            box-sizing: border-box;
          }
        }

        .save-button {
          width: 180px;

          margin: 12px 0 0 auto;

          @include maxScreen($sm) {
            width: 220px;

            margin: 0 auto;
          }
        }
      }
    }
  }

  .form-label {
    .label {
      @include blatant(700);
      font-size: 30px;
      white-space: normal;
    }

    .sub-label {
      margin-bottom: 16px;

      @include inter(600);
      font-size: 20px;
      color: $gray-3;
      white-space: normal;
    }
  }

  .experiences-manage-game-selector {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;

    min-height: 56px;

    .game-platform-group {
      display: flex;
      justify-content: space-between;
      gap: 12px;

      .form-group {
        flex-grow: 1;

        min-width: 0;
      }
    }

    .add-button {
      width: 100%;
    }
  }

  .experiences-manage-availability-selector {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;

    min-height: 56px;

    .availability-group {
      display: flex;
      flex-direction: column;
      gap: 12px;

      padding: 16px 24px;

      background-color: $black-3;
      border-radius: 5px;

      .switch-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .day-name {
          @include inter(500);
          font-size: 14px;
        }
      }

      .times {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .form-group {
          flex-grow: 1;
        }

        .time-group {
          display: flex;
          gap: 12px;
        }
      }
    }

    .add-button {
      width: 100%;
    }
  }
}
