.scroll-carousel {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .scroll-carousel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      position: relative;
      @include inter(600);
      font-size: 24px;

      min-height: 24px;
    }
  }

  .row-wrapper {
    position: relative;

    .row {
      display: flex;

      padding: 8px 0;

      overflow: auto hidden;

      @include hideScrollbar();
    }

    .icon-button-wrapper {
      position: absolute;

      height: fit-content;

      top: 0;
      bottom: 0;
      margin: auto 12px;

      border-radius: 50%;
      box-shadow: 0 0 10px $black-2;

      z-index: 200;

      &.back {
        left: 0;
      }

      &.forward {
        right: 0;
      }
    }
  }
}
