#cig-overlay-page {
  overflow: hidden;

  .overlay-alert-wrapper {
    display: flex;

    width: 100vw;
    height: 100vh;

    border-radius: 3.5vw;
    background-color: $black;

    overflow: hidden;

    .overlay-alert {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      min-width: 0;

      .icon-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5vw;

        height: 100%;

        padding: 0 4vw;

        background-color: $purple;

        .yakkr-banner {
          width: 14vw;
        }

        svg:not(.yakkr-banner) {
          @include size(10vw);
        }
      }

      .text-wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        min-width: 0;

        padding: 4vw 6vw;

        .title {
          color: $white;
          @include textOverflow;
          @include inter(600);
          font-size: 7vw;
        }

        .sub-title {
          margin-top: -4px;

          color: $gray-3;
          @include textOverflow;
          @include inter(400);
          font-size: 3.5vw;

          .gradient {
            @include textGradient(none);
            @include inter(800);
          }
        }
      }
    }
  }
}
