#flank-landing-page {
  .flank-banner {
    position: absolute;

    top: 0;
    left: 0;
    margin: 32px 64px;

    z-index: 100;

    @include maxScreen($md) {
      right: 0;
      margin: 32px auto;
    }
  }

  .section {
    @import "./sections/topSplash.scss";
    @import "./sections/splitViews.scss";
    @import "./sections/howItWorks.scss";
    @import "./sections/perks.scss";
    @import "./sections/cta.scss";
    @import "./sections/faqs.scss";
  }

  .splash-title {
    @include leagueGothic(500, italic);
    text-transform: uppercase;

    font-size: 64px;

    // Scale down
    @include maxScreen($xl) {
      font-size: 52px;
    }

    @include maxScreen($lg) {
      font-size: 44px;
    }

    @include maxScreen($sm) {
      font-size: 36px;
    }

    @include maxScreen($xs) {
      font-size: 32px;
    }

    // Scale up
    @include minScreen($sl) {
      font-size: 96px;
    }

    @include minScreen($ul) {
      font-size: 128px;
    }
  }
}

#flank-sign-up-modal {
  .modal {
    .modal-content {
      align-items: flex-start;

      .flank-banner {
        margin: 16px 0 0 24px;
      }

      .auth-wrapper {
        .header-wrapper {
          .title {
            @include leagueGothic(400, italic);
            text-transform: uppercase;
          }
        }

        .content-wrapper {
          .form-wrapper {
            .form {
              .inner {
                a:not(.button) {
                  color: $peach-3;
                }

                .completion-text-row {
                  p {
                    @include inter(500);
                    font-size: 20px;
                    color: $white;
                    text-align: center;
                  }
                }

                .auth-button-row {
                  flex-direction: column;
                  align-items: stretch;
                }
              }
            }
          }
        }
      }
    }
  }
}
