@import "./fonts/fonts.scss";
@import "./vars.scss";
@import "./mixins.scss";
@import "./generic.scss";

@import "./common/drawer.scss";
@import "./common/pill.scss";
@import "./common/divider.scss";
@import "./common/progress.scss";
@import "./common/barProgress.scss";
@import "./common/pageCover.scss";
@import "./common/tooltip.scss";
@import "./common/relativePortal.scss";
@import "./common/ribbon.scss";
@import "./common/avatar.scss";
@import "./common/dataCheck.scss";
@import "./common/scrollCarousel.scss";
@import "./common/tabs.scss";
@import "./common/yakkrBanner.scss";
@import "./common/flankBanner.scss";
@import "./common/logo.scss";
@import "./common/paginatedControls.scss";
@import "./common/accordion.scss";
@import "./common/backgroundImage.scss";
@import "./common/noAccessCover.scss";
@import "./common/altAuthBar.scss";
@import "./common/progressBar.scss";
@import "./common/clip.scss";

@import "./tickets/ticket.scss";
@import "./tickets/ticketRegular.scss";
@import "./tickets/ticketBook.scss";

@import "./calendar/calendar.scss";
@import "./calendar/calendarManage.scss";
@import "./calendar/calendarView.scss";

@import "./section/section.scss";

@import "./sections/cigCard.scss";
@import "./sections/gameCard.scss";
@import "./sections/experienceCard.scss";
@import "./sections/experienceRequestCard.scss";
@import "./sections/buildExperience.scss";
@import "./sections/startingSoonSessions.scss";
@import "./sections/featuredSession.scss";
@import "./sections/featuredCig.scss";
@import "./sections/reviewClip.scss";
@import "./sections/cigEvent.scss";
@import "./sections/cigEventSpread.scss";
@import "./sections/reviewCard.scss";

@import "./splash/splash.scss";

@import "./modals/modal.scss";

@import "./forms/button.scss";
@import "./forms/gradientButton.scss";
@import "./forms/input.scss";
@import "./forms/options.scss";
@import "./forms/select.scss";
@import "./forms/search.scss";
@import "./forms/checkbox.scss";
@import "./forms/slider.scss";
@import "./forms/menu.scss";
@import "./forms/datePicker.scss";
@import "./forms/timePicker.scss";
@import "./forms/simpleDatePicker.scss";
@import "./forms/simpleCalendar.scss";
@import "./forms/toggleSwitch.scss";
@import "./forms/filePicker.scss";
@import "./forms/editableText.scss";
@import "./forms/selectableText.scss";
@import "./forms/buttonSelect.scss";
@import "./forms/ratingSelector.scss";
@import "./forms/incrementPicker.scss";
@import "./forms/form.scss";
@import "./forms/formBar.scss";
@import "./forms/formBox.scss";
@import "./forms/formGroup.scss";

@import "./layout/header.scss";
@import "./layout/footer.scss";
@import "./layout/walkthrough.scss";
@import "./layout/nav.scss";
@import "./layout/ticketsOverlay.scss";
@import "./layout/snackbars.scss";
@import "./layout/stickyBar.scss";
@import "./layout/notifications.scss";
@import "./layout/changelog.scss";
@import "./layout/devPanel.scss";
@import "./layout/authWrapper.scss";

@import "./transitions/fade.scss";
@import "./transitions/collapse.scss";
@import "./transitions/slide.scss";
@import "./transitions/shake.scss";
@import "./transitions/drop.scss";

@import "./media/cardBrandImg.scss";

@import "./pages/home/home.scss";
@import "./pages/browse/browse.scss";
@import "./pages/bookings/bookings.scss";
@import "./pages/bookings/room/room.scss";
@import "./pages/bookings/room/feedback/feedback.scss";
@import "./pages/bookings/paymentCompletion/paymentCompletion.scss";
@import "./pages/cigSplash/cigSplash.scss";
@import "./pages/cigProfile/cigProfile.scss";
@import "./pages/cigProfile/cigOverlay.scss";
@import "./pages/settings/settings.scss";
@import "./pages/reportIssue/reportIssue.scss";
@import "./pages/styleGuide/styleGuide.scss";
@import "./pages/experiences/experiences.scss";
@import "./pages/myExperiences/myExperiences.scss";
@import "./pages/becomeTalentOld/becomeTalentOld.scss";
@import "./pages/becomeTalent/becomeTalent.scss";
@import "./pages/cigAuth/cigRegister/cigRegister.scss";
@import "./pages/cigAuth/cigLogin/cigLogin.scss";
@import "./pages/cigAuth/cigAuthWrapper.scss";

@import "./pages/landing/consultancy/consultancy.scss";
@import "./pages/landing/flank/flank.scss";

@import "./ribbons/cookieConsentRibbon.scss";

html {
  body {
    &:not(.no-background) {
      background-color: $black-1;
    }

    #content-root {
      position: relative;

      margin-left: 0;

      transition: margin 300ms ease;

      &.initial-loading {
        height: 100vh;
        overflow: hidden;
      }

      .content-wrapper {
        &.header-active {
          @include maxScreen($sm) {
            padding-top: 60px;
          }
        }
      }

      @include maxScreen($sm) {
        margin-left: 0;
      }

      &.drawer-active {
        margin-left: 250px;

        @include maxScreen($sm) {
          margin-left: 0;
        }

        &.drawer-collapsed {
          margin-left: 50px;

          @include maxScreen($sm) {
            margin-left: 0;
          }
        }
      }

      &.nav-active {
        min-height: 100%;
      }
    }
  }
}

* {
  @include scrollbar($black-2, $gray-1);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
small,
label,
li,
a {
  color: $white;
  margin: 0;
  padding: 0;
  letter-spacing: 0.04em;
  line-height: 1.3em;

  @include inter(300);
}

small {
  font-size: 0.5em;
  color: inherit;
  @include inter(400);
}

span {
  letter-spacing: 0.04em;
  line-height: 1.3em;
}

small {
  font-size: 12px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

pre {
  white-space: pre-wrap;
}

a {
  text-decoration: none;
  color: $purple-4;
  @include inter(600);
  cursor: pointer;

  &.disabled {
    @include disabled;

    &.no-visual {
      opacity: unset;
    }
  }
}

.disable-links {
  a:not(.no-disable) {
    @include disabled;
  }
}

ul,
ol {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.progress-overlay {
  height: 100vh;

  background-color: $black-3;

  z-index: $z-root;
}

.top-progress {
  position: fixed;

  width: 100%;

  box-shadow: 0 1px 2px rgba($gray-2, 0.75);

  z-index: $z-root;
  overflow: hidden;
}
