.ribbon {
  position: fixed;

  width: 100vw;

  bottom: 0;

  z-index: $z-ribbon;

  @each $name, $color in $colors {
    &.#{$name} {
      background-color: $color;
    }
  }
}
