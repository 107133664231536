.ticket {
  position: relative;
  display: flex;
  flex-direction: column;

  border-radius: 20px;

  &.loading {
    .top {
      .top-svg {
        visibility: hidden;
      }
    }
  }

  .top {
    position: relative;
    display: flex;

    overflow: hidden;

    .top-content {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      inset: 0;

      overflow: hidden;

      border-radius: 14px 14px 8px 8px;

      .divider {
        bottom: -1px;
      }

      .cig-name-wrapper {
        display: flex;

        .cig-name {
          flex-grow: 1;

          @include inter(600);
          color: $white;
          word-wrap: break-word;
          @include lineClamp(2);

          min-width: 0;

          margin-right: 40px;
        }
      }
    }
  }

  .divider {
    position: absolute;

    border-width: 4px;
    width: 95%;

    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .bottom {
    position: relative;
    display: flex;

    margin-top: -1px;

    overflow: hidden;
    user-select: none;
    transition-property: opacity, transform;
    transition-duration: 500ms;
    transition-timing-function: ease;

    .ticket-svg {
      &.bottom-svg {
        .bg-path {
          @each $name, $color in $colors {
            &.#{$name} {
              fill: $color;
            }
          }
        }
      }
    }

    .bottom-content {
      position: absolute;
      inset: 0;

      border-radius: 8px 8px 14px 14px;

      overflow: hidden;

      .divider {
        top: -2px;
      }

      .content-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        height: 100%;

        .details-wrapper {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          height: 100%;

          p {
            color: $white;
          }

          .titles {
            .main-title {
              @include inter(500);
              @include lineClamp(1);
            }

            .sub-title {
              color: $white;
              @include inter(500);
              text-transform: uppercase;
              letter-spacing: 0.15em;
            }
          }
        }
      }
    }
  }

  &.small {
    width: 240px;

    .top {
      height: 247px;

      .top-content {
        padding: 18px;

        .cig-name-wrapper {
          gap: 8px;

          .cig-name {
            font-size: 24px;
          }

          img {
            width: 30px;
          }
        }
      }
    }

    .bottom {
      height: 78px;

      .bottom-content {
        padding: 10px 14px;

        .content-wrapper {
          .details-wrapper {
            .titles {
              .main-title {
                font-size: 14px;
              }
              .sub-title {
                font-size: 9px;
              }
            }
          }
        }
      }
    }
  }

  &.medium {
    width: 300px;

    .top {
      height: 309px;

      .top-content {
        padding: 20px;

        .cig-name-wrapper {
          gap: 12px;

          .cig-name {
            font-size: 32px;
          }

          img {
            width: 35px;
          }
        }
      }
    }

    .bottom {
      height: 97px;

      .bottom-content {
        padding: 12px 16px;

        .content-wrapper {
          .details-wrapper {
            .titles {
              .main-title {
                font-size: 16px;
              }
              .sub-title {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  &.large {
    width: 400px;

    .top {
      height: 412px;

      .top-content {
        padding: 32px;

        .cig-name-wrapper {
          gap: 16px;

          .cig-name {
            font-size: 40px;
          }

          img {
            width: 50px;
          }
        }
      }
    }

    .bottom {
      height: 130px;

      .bottom-content {
        padding: 20px 24px;

        .content-wrapper {
          .details-wrapper {
            .titles {
              .main-title {
                font-size: 20px;
              }
              .sub-title {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
