.changelog-relative-portal {
  z-index: $z-modal;

  &:not(.full-width) {
    .changelog {
      width: 500px;
      min-width: 500px;
      max-width: 500px;
    }
  }

  .changelog {
    display: flex;
    flex-direction: column;

    background-color: $black-3;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba($black, 0.1);

    box-sizing: border-box;
    z-index: $z-tooltip;
    transition: opacity 300ms ease;
    overflow: hidden;

    .changelog-inner {
      .changelog-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 8px 16px;

        .title {
          @include inter(500);
          color: $white;
        }
      }

      .changelog-list-wrapper {
        max-height: 300px;

        overflow: auto;

        .changelog-list {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 8px;

          padding: 0 12px 12px 12px;

          ol {
            li {
              list-style: circle;

              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}
