@import "./inter.scss";
@import "./blatant.scss";

@mixin inter($weight: 500, $style: normal) {
  font-weight: $weight;
  font-style: $style;
  font-family: "Inter", sans-serif;
}

@mixin blatant($weight: 400, $style: normal) {
  font-weight: $weight;
  font-style: $style;
  font-family: "Blatant", sans-serif;
}

@mixin leagueGothic($weight: 400, $style: normal) {
  font-weight: $weight;
  font-style: $style;
  font-family: "League Gothic", sans-serif;
}
