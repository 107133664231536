@mixin down($distance) {
  transform: translateY($distance);
  opacity: 0;

  &.slide-enter-active,
  &.slide-appear-active,
  &.slide-enter-done,
  &.slide-appear-done {
    transform: translateY(0);
    opacity: 1;
  }

  &.slide-exit-active,
  &.slide-exit-done {
    transform: translateY($distance);
    opacity: 0;
  }
}

@mixin up($distance) {
  transform: translateY($distance);
  opacity: 0;

  &.slide-enter-active,
  &.slide-appear-active,
  &.slide-enter-done,
  &.slide-appear-done {
    transform: translateY(0);
    opacity: 1;
  }

  &.slide-exit-active {
    transform: translateY($distance);
    opacity: 0;
  }
}

@mixin left($distance) {
  transform: translateX($distance);
  opacity: 0;

  &.slide-enter-active,
  &.slide-appear-active,
  &.slide-enter-done,
  &.slide-appear-done {
    transform: translateX(0);
    opacity: 1;
  }

  &.slide-exit-active {
    transform: translateX($distance);
    opacity: 0;
  }
}

@mixin right($distance) {
  transform: translateX($distance);
  opacity: 0;

  &.slide-enter-active,
  &.slide-appear-active,
  &.slide-enter-done,
  &.slide-appear-done {
    transform: translateX(0);
    opacity: 1;
  }

  &.slide-exit-active {
    transform: translateX($distance);
    opacity: 0;
  }
}

.slide-transition {
  &.down {
    &.tiny {
      @include down(-2%);
    }

    &.short {
      @include down(-50%);
    }

    &.normal {
      @include down(-100%);
    }

    &.long {
      @include down(-150%);
    }
  }

  &.up {
    &.tiny {
      @include up(2%);
    }

    &.short {
      @include up(50%);
    }

    &.normal {
      @include up(100%);
    }

    &.long {
      @include up(150%);
    }
  }

  &.left {
    &.tiny {
      @include left(2%);
    }

    &.short {
      @include left(50%);
    }

    &.normal {
      @include left(100%);
    }

    &.long {
      @include left(150%);
    }
  }

  &.right {
    &.tiny {
      @include right(-2%);
    }

    &.short {
      @include right(-50%);
    }

    &.normal {
      @include right(-100%);
    }

    &.long {
      @include right(-150%);
    }
  }
}
