.ticket-regular {
  &.torn {
    .bottom {
      transform: translate(-5px, 15px) rotate(5deg);
      opacity: 0.75;
    }
  }

  .top {
    .top-content {
      .top-squares {
        .square {
          position: absolute;

          &:nth-of-type(1) {
            width: 12px;
            height: 12px;

            top: 19.5%;
            left: 12%;

            opacity: 0.5;

            transform: rotate(35deg);
          }

          &:nth-of-type(2) {
            width: 17px;
            height: 17px;

            top: 25%;
            left: 7%;

            transform: rotate(-15deg);
            animation-delay: 900ms;
          }
        }
      }

      .bottom-squares {
        .square {
          position: absolute;

          &:nth-of-type(1) {
            width: 12px;
            height: 12px;

            bottom: 14%;
            right: 7%;

            opacity: 0.5;

            transform: rotate(-20deg);
          }

          &:nth-of-type(2) {
            width: 17px;
            height: 17px;

            bottom: 9%;
            right: 11.5%;

            transform: rotate(25deg);
            animation-delay: 900ms;
          }
        }
      }

      .color-tab {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        top: 0;
        right: 20px;

        @include size(28px);
        border-radius: 0 0 8px 8px;

        p {
          color: $white;
          font-size: 13px;
          @include inter(500);
        }
      }

      .banners {
        display: flex;
        justify-content: space-between;

        .yakkr-banner {
          width: auto;
        }
      }
    }
  }

  .bottom {
    &.tearable {
      cursor: grab;
    }

    &.dragging {
      cursor: grabbing;
    }

    .bottom-content {
      .content-wrapper {
        .details-wrapper {
          .titles {
            .sub-title {
              @include lineClamp(2);
              word-wrap: break-word;
            }
          }

          .meta-wrapper {
            display: flex;

            .date {
              @include inter(500);
            }
          }
        }
      }
    }
  }

  &.small {
    .top {
      .top-content {
        .banners {
          .yakkr-banner {
            height: 18px;
          }
        }

        .top-squares {
          .square {
            &:nth-of-type(1) {
              width: 8px;
              height: 8px;
            }

            &:nth-of-type(2) {
              width: 13px;
              height: 13px;
            }
          }
        }

        .bottom-squares {
          .square {
            &:nth-of-type(1) {
              width: 8px;
              height: 8px;
            }

            &:nth-of-type(2) {
              width: 13px;
              height: 13px;
            }
          }
        }
      }
    }

    .bottom {
      .bottom-content {
        .content-wrapper {
          .details-wrapper {
            .meta-wrapper {
              .date {
                font-size: 8px;
              }

              img {
                width: 25px;
              }
            }
          }

          .qr-code {
            @include size(48px);
          }
        }
      }
    }
  }

  &.medium {
    .top {
      .top-content {
        .banners {
          .yakkr-banner {
            height: 20px;
          }
        }

        .top-squares {
          .square {
            &:nth-of-type(1) {
              width: 10px;
              height: 10px;
            }

            &:nth-of-type(2) {
              width: 15px;
              height: 15px;
            }
          }
        }

        .bottom-squares {
          .square {
            &:nth-of-type(1) {
              width: 10px;
              height: 10px;
            }

            &:nth-of-type(2) {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }

    .bottom {
      .bottom-content {
        .content-wrapper {
          .details-wrapper {
            .meta-wrapper {
              .date {
                font-size: 10px;
              }

              img {
                width: 30px;
              }
            }
          }

          .qr-code {
            @include size(64px);
          }
        }
      }
    }
  }

  &.large {
    .top {
      .top-content {
        .banners {
          .yakkr-banner {
            height: 25px;
          }
        }
      }
    }

    .bottom {
      .bottom-content {
        .content-wrapper {
          .details-wrapper {
            .meta-wrapper {
              .date {
                font-size: 14px;
              }

              img {
                width: 40px;
              }
            }
          }

          .qr-code {
            @include size(96px);
          }
        }
      }
    }
  }
}
