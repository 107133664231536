.gradient-button {
  display: block;

  width: fit-content;

  border-radius: 50px;

  @each $name, $double-gradient in $double-gradients {
    &.#{$name} {
      &.animated {
        &.horizontal {
          .button {
            @include buttonGradient($double-gradient, horizontal, 4s, $name);
          }
        }
        &.vertical {
          .button {
            @include buttonGradient($double-gradient, vertical, 4s, $name);
          }
        }
      }
    }
  }

  @each $name, $gradient in $gradients {
    &.#{$name} {
      &:not(.animated) {
        .button {
          background: $gradient;
        }
      }
    }
  }

  @mixin sizing($border-radius) {
    border-radius: $border-radius;
  }

  &.extra-small {
    @include sizing($border-radius: 3px);
  }

  &.small {
    @include sizing($border-radius: 4px);
  }

  &.medium {
    @include sizing($border-radius: 6px);
  }

  &.large {
    @include sizing($border-radius: 8px);
  }

  &.extra-large {
    @include sizing($border-radius: 10px);
  }

  &.glow:hover:not(.hover-disabled):not(.disabled):not(.loading) {
    box-shadow: 0 0 20px $blue;
    @include gradientGlow(4s, 300ms);

    transition: box-shadow 300ms ease;
  }

  .button {
    width: 100%;

    box-shadow: none;
  }
}
