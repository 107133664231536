@import "./roomLoadingModal.scss";

#room-page {
  position: relative;

  width: 100vw;
  min-height: inherit;

  overflow-x: hidden;
  box-sizing: border-box;

  .circular-blurs {
    position: fixed;

    width: inherit;
    min-height: inherit;

    background-color: $black-1;

    overflow: hidden;
    pointer-events: none;

    .circular-blur {
      position: absolute;

      width: 1500px;

      &:first-of-type {
        top: 0;
        right: 0;
        transform: translate(60%, -60%);
      }

      &:last-of-type {
        left: 0;
        bottom: 0;
        transform: translate(-60%, 60%);
      }
    }
  }

  .participant-view {
    height: 100%;

    overflow: hidden;
    pointer-events: none;

    &.clickable {
      pointer-events: visible;
      cursor: pointer;
      transition: 300ms ease transform;

      transform: scale(1);

      &:hover {
        transform: scale(1.05);
      }
    }

    &.large {
      .state-wrapper {
        gap: 16px;

        svg {
          @include size(128px);
        }

        p:not(.avatar-fallback) {
          font-size: 18px;
        }
      }
    }

    &.small {
      background-color: $black-1;

      pointer-events: all;

      border-radius: 5px;

      &.landscape {
        .name-wrapper {
          .text-wrapper {
            @include maxScreen($md) {
              gap: 1px;

              padding: 0;
            }

            .group {
              .icon {
                @include maxScreen($md) {
                  @include size(10px);
                }
              }

              svg {
                @include maxScreen($md) {
                  @include size(10px);
                }
              }

              p {
                @include maxScreen($md) {
                  font-size: 10px;
                }
              }
            }
          }
        }

        .name-wrapper {
          .icons-wrapper {
            .left,
            .right {
              svg {
                @include maxScreen($md) {
                  @include size(12px);

                  &:first-of-type {
                    padding-left: 0;
                  }

                  &:last-of-type {
                    padding-right: 0;
                  }
                }
              }
            }
          }
        }
      }

      .yakkr-banner {
        margin: 26px 38px;
      }

      .name-wrapper {
        .icons-wrapper {
          .left,
          .right {
            svg {
              @include size(16px);

              padding: 2px;
            }
          }
        }
      }

      .state-wrapper {
        gap: 4px;

        svg {
          @include size(48px);
        }

        p {
          font-size: 12px;
        }
      }
    }

    .action-buttons {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .yakkr-banner {
      position: absolute;

      right: 0;
      bottom: 0;
      margin: 28px 56px;

      opacity: 0.3;
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-color: $black-1;
    }

    .action-buttons {
      position: absolute;
      display: flex;
      flex-direction: column;

      top: 0;
      right: 0;
      margin: 8px;
    }

    .name-wrapper {
      position: absolute;
      display: flex;
      flex-direction: column;

      width: 100%;

      bottom: 0;

      .text-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;

        height: 18px;

        padding: 2px 8px;

        background-color: rgba($black, 0.3);

        .group {
          flex-grow: 1;
          display: flex;
          align-items: center;
          gap: 4px;

          min-width: 0;

          &:last-of-type {
            justify-content: flex-end;
            flex-shrink: 0;

            p {
              text-align: right;
            }
          }

          .icon {
            @include size(16px);
          }

          svg {
            color: $white;
            font-size: 14px;
            @include size(14px);
          }

          p {
            flex-grow: 1;

            @include textOverflow();
            color: $white;
            font-size: 12px;
          }

          .icon {
            pointer-events: all;
          }
        }
      }

      .icons-wrapper {
        display: flex;
        justify-content: space-between;

        width: 100%;

        .left,
        .right {
          display: flex;

          svg {
            @include size(32px);

            padding: 4px;

            background-color: rgba($black, 0.3);

            @include maxScreen($md) {
              @include size(16px);
            }

            &:first-of-type {
              padding-left: 8px;
            }

            &:last-of-type {
              padding-right: 8px;
            }
          }
        }

        .left {
          svg {
            color: $white;
          }
        }

        .right {
          justify-content: flex-end;

          svg {
            color: $blue;
          }
        }
      }
    }

    .state-wrapper {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      inset: 0;

      width: 100%;
      height: 100%;

      background-color: rgba($black, 0.3);

      svg {
        color: $white;
      }

      p {
        width: 100%;

        color: $white;
        text-align: center;
        @include inter(500);
        @include textOverflow();
      }

      .data-check {
        .progress {
          transform: scale(0.5);
        }
      }
    }

    .video-placeholder {
      position: absolute;

      inset: 0;

      width: 100%;
      height: 100%;

      background-color: $purple;
    }
  }

  .room-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    width: inherit;
    height: inherit;

    @include maxScreen($md) {
      gap: 16px;

      & > div {
        flex-basis: 50%;
      }
    }

    .view-port-wrapper {
      position: relative;

      flex-grow: 1;

      overflow: hidden;

      &.collapsible {
        display: block;
      }

      &.collapse-exit-active,
      &.collapse-exit-done {
        max-height: 0;
        margin-bottom: 0;
      }

      .view-port {
        height: 100%;

        &.drawer-open {
          .grid-streams-wrapper {
            @include minScreen($md) {
              right: 450px;
            }
          }
        }

        &.fullscreen {
          .participant-view {
            .name-wrapper {
              .text-wrapper {
                @include maxScreen($md) {
                  padding: 2px 24px;
                }
              }
            }
          }
        }

        .grid-streams-wrapper {
          position: absolute;

          height: calc(100% - 22px);

          top: 0;
          right: 0;

          opacity: 1;

          overflow: visible;

          pointer-events: none;

          &.one-column {
            width: 15%;
            .grid-streams {
              grid-template-columns: auto;
            }
          }

          &.two-columns {
            width: 30%;
            .grid-streams {
              grid-template-columns: auto auto;
            }
          }

          .grid-streams {
            display: grid;

            justify-items: center;
            grid-template-rows: repeat(5, 1fr);
            grid-auto-flow: column;
            direction: rtl;

            pointer-events: none;

            & > * {
              direction: ltr;
            }

            @mixin spacing($size) {
              height: calc(100% - calc(#{$size} * 2));
              padding: $size;
              gap: $size;
            }

            @include spacing(16px);

            @include maxScreen($lg) {
              @include spacing(8px);
            }

            .participant-view {
              position: relative;
              width: 100%;
            }
          }

          .grid-streams-toggle {
            position: absolute;
            left: -48px;
            top: 0;
            bottom: 0;

            margin: auto 0;

            pointer-events: all;
          }
        }
      }
    }

    .title {
      position: absolute;

      top: 0;
      left: 0;
      margin: 24px;

      pointer-events: all;

      &.fixed {
        position: relative;

        margin: 16px 16px 0 16px;

        p {
          font-size: 14px;
          @include inter(500);
        }
      }

      p {
        display: block;

        color: $white;
        font-size: 18px;
        text-shadow: 0 0 2px $gray-1;
        text-transform: capitalize;

        &.overtime {
          color: $red;
          @include inter(500);
        }
      }
    }

    .controls {
      position: absolute;
      display: flex;
      justify-content: center;
      gap: 16px;

      width: 100%;

      bottom: 18px;
      margin: 0 auto;

      @include maxScreen($lg) {
        gap: 14px;
        bottom: 16px;
      }

      @include maxScreen($md) {
        gap: 12px;
        bottom: 28px;
      }

      &.fixed {
        position: relative;

        gap: 8px;

        bottom: 0;
        margin-bottom: 16px;
      }

      .icon {
        pointer-events: all;

        @include size(80px);

        @include minScreen($lg) {
          .count-circle {
            @include size(30%);
            margin: 15%;
          }
        }

        @include maxScreen($lg) {
          @include size(64px);
        }

        @include maxScreen($md) {
          @include size(40px);
        }

        @include maxScreen($sm) {
          @include size(40px);
        }

        &.gray {
          opacity: 0.75;
        }
      }
    }
  }

  @import "./roomDrawer.scss";

  .room-overlay {
    position: relative;
    display: flex;
    flex-direction: column;

    width: inherit;
    min-height: inherit;

    overflow: hidden;
    box-sizing: border-box;

    .room-overlay-inner {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      min-height: 100%;

      padding: 64px;
      z-index: 100;

      @include maxScreen($md) {
        padding: 128px 16px 64px 16px;
      }

      @include maxScreen($sm) {
        padding: 64px 16px;
      }

      .title {
        margin-top: 64px;

        color: $white;
        @include inter(500);
        font-size: 40px;
        text-align: center;

        @include maxScreen($sm) {
          margin-top: 48px;

          font-size: 28px;
        }
      }

      .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        width: 100%;

        .pre-room-overlay {
          flex-grow: 1;
          position: relative;
          display: flex;
          flex-direction: column;

          padding: 64px 0;

          @include maxScreen($sm) {
            padding: 32px 0;
          }

          &.joinable {
            .countdown {
              transform: translateY(-25%) scale(0.75);

              .time-stack {
                opacity: 0.75;
              }

              .prompt {
                opacity: 1;
              }
            }
          }

          .countdown {
            flex-grow: 1;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            transition: transform 300ms ease;

            .time-stack {
              flex-basis: 200px;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 12px;

              transition: opacity 300ms ease;

              @include maxScreen($sm) {
                flex-basis: 33.33%;
              }

              .number {
                color: $white;
                @include inter(500);
                font-size: 96px;

                @include maxScreen($sm) {
                  font-size: 64px;
                }
              }

              .text {
                color: $gray-3;
                @include inter(300);
                font-size: 32px;
              }

              @include maxScreen($sm) {
                font-size: 18px;
              }
            }

            .prompt {
              position: absolute;
              bottom: 0;

              opacity: 0;

              transform: translateY(200%);
              transition: opacity 300ms ease;

              p {
                color: $white;
                font-size: 40px;
                @include inter(500);
                white-space: nowrap;
              }
            }
          }
        }

        .post-room-overlay {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 24px;

          padding: 64px 0;

          @include maxScreen($sm) {
            padding: 32px 0;
          }

          .text {
            color: $white;
            font-size: 24px;
            @include inter(400);
            text-align: center;

            @include maxScreen($sm) {
              font-size: 18px;
            }
          }

          .sub-title {
            color: $white;
            font-size: 32px;
            @include inter(500);
            text-align: center;
          }
        }
      }

      .button-row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 32px;
      }
    }
  }
}

.room-rules-modal {
  .modal {
    .modal-content {
      display: flex;
      align-items: center;
      gap: 40px;

      @include maxScreen($md) {
        flex-direction: column;

        padding: 0 16px;

        overflow: hidden auto;
      }

      @include maxScreen($xs) {
        gap: 16px;

        padding: 0;
      }

      .box {
        position: relative;

        width: 400px;
        height: calc(100% - 48px);

        padding: 24px 32px;

        background-color: $black-2;
        border-radius: 10px;
        box-shadow: 0 0 10px $black-3;

        overflow: hidden;

        @include maxScreen($md) {
          flex-shrink: 0;
          height: fit-content;
        }

        @include maxScreen($sm) {
          width: calc(100% - 64px);

          padding: 20px 24px;
        }

        .title {
          @include inter(600);

          @include maxScreen($sm) {
            font-size: 20px;
          }
        }

        &.rules-wrapper {
          .rules {
            display: flex;
            flex-direction: column;
            gap: 24px;

            margin-top: 16px;

            .rule {
              display: flex;
              align-items: center;
              gap: 16px;

              @include maxScreen($sm) {
                gap: 12px;
              }

              .rule-icon {
                display: flex;
                align-items: center;
                justify-content: center;

                @include size(40px);

                background-color: $black-1;
                border-radius: 12px;

                @include maxScreen($sm) {
                  @include size(32px);
                }

                svg {
                  color: $red;
                  @include size(22px);

                  @include maxScreen($sm) {
                    @include size(18px);
                  }
                }
              }

              p {
                @include inter(500);
                font-size: 14px;

                @include maxScreen($sm) {
                  font-size: 12px;
                }
              }
            }
          }

          .foot-note {
            @include inter(600);
            text-align: center;
            font-size: 16px;

            margin-top: 20px;
            padding: 0 16px;

            @include maxScreen($sm) {
              font-size: 14px;
              padding: 0;
            }
          }

          .button-row {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;

            margin-top: 40px;

            @include maxScreen($sm) {
              justify-content: center;

              margin-top: 32px;
            }
          }
        }

        &.extra-info-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 24px;

          @include maxScreen($sm) {
            gap: 12px;
          }

          .title {
            text-align: center;
          }

          .info-icon {
            @include size(64px);
            color: $blue;
          }

          p {
            @include inter(500);
            font-size: 16px;
            text-align: center;

            @include maxScreen($sm) {
              font-size: 14px;
            }
          }

          .button-row {
            display: flex;
            gap: 8px;

            margin-top: 128px;

            .button {
              width: fit-content;
            }
          }
        }
      }

      .ticket {
        flex-shrink: 0;
      }
    }
  }
}
