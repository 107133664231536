&.benefits {
  margin: 48px 0;

  // Scale up
  @include minScreen($sl) {
    margin: 96px 0;
  }

  @include minScreen($ul) {
    margin: 160px 0;
  }

  .box-wrapper {
    display: flex;
    justify-content: space-around;
    gap: 32px 16px;

    margin-top: 32px;

    flex-wrap: wrap;

    // Scale down
    @include maxScreen($lg) {
      gap: 24px 16px;
    }

    @include maxScreen($sm) {
      gap: 12px;
    }

    // Scale up
    @include minScreen($sl) {
      margin-top: 48px;
    }

    @include minScreen($ul) {
      margin-top: 96px;
    }

    .box {
      flex-basis: calc((33% - (16px * 2) / 3));

      padding: 24px 16px 16px 16px;

      background-color: $black-2;
      border: solid 2px $black-3;
      border-radius: 10px;

      box-sizing: border-box;

      // Scale down
      @include maxScreen($lg) {
        padding: 16px 12px 12px 12px;
      }

      @include maxScreen($md) {
        flex-basis: calc((50% - (16px) / 2));
      }

      @include maxScreen($sm) {
        flex-basis: 100%;
      }

      // Scale up
      @include minScreen($sl) {
        padding: 32px 24px 24px 24px;
      }

      @include minScreen($ul) {
        padding: 48px 32px 32px 32px;
      }

      .tag-line {
        @include inter(600);
        color: $gray-2;
        font-size: 16px;

        // Scale down
        @include maxScreen($xl) {
          font-size: 14px;
        }

        @include maxScreen($lg) {
          font-size: 12px;
        }

        // Scale up
        @include minScreen($sl) {
          font-size: 20px;
        }

        @include minScreen($ul) {
          font-size: 32px;
        }

        span {
          @include textGradient($gradient-faded-horizontal);
        }
      }

      .title {
        @include inter(700);
        color: $white;
        font-size: 26px;

        // Scale down
        @include maxScreen($xl) {
          font-size: 22px;
        }

        @include maxScreen($lg) {
          font-size: 18px;
        }

        // Scale up
        @include minScreen($sl) {
          font-size: 34px;
        }

        @include minScreen($ul) {
          font-size: 58px;
        }

        span {
          @include textGradient($gradient-faded-horizontal);
        }
      }

      .text {
        margin-top: 12px;

        @include inter(500);
        color: $white;
        font-size: 16px;

        // Scale down
        @include maxScreen($xl) {
          font-size: 14px;
        }

        @include maxScreen($lg) {
          font-size: 12px;
        }

        // Scale up
        @include minScreen($sl) {
          font-size: 20px;
        }

        @include minScreen($ul) {
          font-size: 32px;
        }
      }
    }
  }
}
