#cig-register-page {
  min-height: inherit;

  .cig-auth-wrapper {
    .content-wrapper {
      .form-wrapper {
        .form {
          .inner {
            .platform-select {
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 32px;

              .wrapper {
                display: flex;
                align-content: center;
                gap: 16px;
                flex-wrap: wrap;

                @include maxScreen($sm) {
                  margin-top: 16px;
                }

                .button {
                  width: 170px;

                  @include maxScreen($md) {
                    width: 120px;
                  }

                  &:not(.selected) {
                    path {
                      fill: $gray-2;
                    }
                  }
                }
              }
            }

            .stripe-setup {
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .stripe-logo {
                width: 90px;

                opacity: 0.1;

                color: $white;
              }

              .title {
                margin-top: 0;
                text-align: center;
              }

              .stripe-button {
                width: 220px;

                margin-top: 32px;
              }
            }

            .join-discord {
              flex-grow: 1;
              display: flex;
              flex-direction: column;

              iframe {
                height: 400px;
                width: 100%;

                margin: auto 0 32px 0;
              }
            }
          }
        }
      }
    }
  }
}
