.select-wrapper {
  position: relative;
}

.select {
  width: 100%;

  &.open {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    .chevron {
      transform: rotate(90deg);
    }
  }

  .chevron {
    transition: transform 300ms ease;
  }
}

.form-entry.error {
  .select-wrapper {
    .select {
      box-shadow: 0 0 0 1px $red;

      &:hover:not(.open) {
        box-shadow: 0 0 0 1px $red;
      }
    }
  }
}
