.experience-card {
  display: flex;
  flex-direction: column;

  width: 1em;
  min-width: 1em;
  min-height: 1.56em;

  user-select: none;
  box-sizing: border-box;

  @each $name, $color in $colors {
    &.#{$name} {
      .experience-card-inner {
        .details {
          .name {
            color: $color;
          }
        }
      }
    }
  }

  &.is-loading {
    pointer-events: none;
  }

  &.highlight {
    .experience-card-inner {
      border: 0.0175em solid $purple;
    }
  }

  &:not(.highlight) {
    .experience-card-inner {
      padding: 0.0175em;
    }
  }

  &:not(.is-mobile) {
    .experience-card-inner {
      .details {
        .bars {
          .extra-details-wrapper {
            flex-grow: 1;

            .input-wrapper {
              height: 100%;

              .input {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  .experience-card-inner {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;

    border-radius: 0.05em;
    background-color: $black-2;

    line-height: unset;
    letter-spacing: unset;

    overflow: hidden;
    cursor: pointer;
    z-index: 100;

    transition: transform 300ms ease;
    transform-origin: bottom;

    &:hover {
      .offset-background {
        opacity: 1;
      }

      transform: translateY(-1%);
    }

    .offset-background {
      opacity: 0;

      transition: opacity 300ms ease;
    }

    .background-image {
      height: 35%;

      .background-image-inner {
        background: linear-gradient(
          to top,
          $black-2 0%,
          rgba($black-2, 0.98) 5%,
          rgba($black-2, 0) 100%
        );
      }
    }

    .pills {
      display: flex;
      align-items: center;
      gap: 12px;

      margin: 0.3em 0.05em 0.02em 0.05em;
    }

    .details {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      margin: 0 0.05em 0.06em 0.05em;

      .name {
        @include blatant(700);
        font-size: 0.078em;

        @include lineClamp(2);
      }

      .price {
        position: relative;

        @include inter(700);
        font-size: 0.078em;
        color: $white;

        .currency {
          color: $gray-2;
        }
      }

      .tag-line {
        margin-top: 0.5em;

        @include inter(500);
        font-size: 0.0417em;
        color: $white;

        @include lineClamp(2);
      }

      .bar {
        display: flex;
        justify-content: space-between;
        gap: 8px;
      }

      .bars {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 0.075em;

        margin-top: 0.05em;

        .bar {
          position: relative;

          .label {
            @include inter(600);
            color: $gray-2;
            font-size: 0.0417em;
          }

          .value {
            padding: 0;

            border: 0;

            @include inter(700);
            color: $white;
            font-size: 0.0417em;

            &.gradient {
              @include inter(800);

              @include textGradient($gradient-faded-horizontal);
              text-decoration: underline;

              .divider {
                @include borderGradient($gradient-faded-horizontal);
              }
            }
          }
        }

        .extra-details-wrapper {
          .input-wrapper {
            min-height: 6em;
            height: 6em;

            .input {
              min-height: 6em;
              height: 6em;
            }
          }
        }

        .button {
          margin-top: auto;
        }
      }
    }
  }
}
