.progress-bar {
  border-radius: 100px;

  @each $name, $color in $colors {
    &.#{$name} {
      background-color: $color;
    }
  }

  .progress-bar-inner {
    height: 100%;

    border-radius: 100px;

    transition: width 300ms ease;

    @each $name, $color in $colors {
      &.#{$name} {
        background-color: $color;
      }
    }
  }
}
