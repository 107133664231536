.snackbars {
  position: fixed;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 8px;
  z-index: $z-snackbar;
  min-width: 200px;
  max-width: 400px;
  margin: 8px;

  @include maxScreen($sm) {
    max-width: unset;
    min-width: unset;
    width: calc(100vw - 16px);
  }

  &.top {
    top: 0;
  }
  &.bottom {
    bottom: 0;
  }

  &.left {
    left: 0;
    .snackbar {
      transform: translateX(calc(-100% - 12px));
    }
  }
  &.right {
    right: 0;
    .snackbar {
      transform: translateX(calc(100% + 12px));
    }
  }

  .snackbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    width: 100%;
    min-height: 40px;
    min-width: 300px;

    padding: 4px 8px 4px 16px;

    background-color: $white;
    border-radius: 8px;

    @include inter(400);
    font-size: 16px;
    line-height: 1.3em;

    transition: transform 300ms ease;
    box-sizing: border-box;

    &.appear-active,
    &.appear-active,
    &.enter-active,
    &.enter-done {
      transform: translate(0);
    }

    .type-icon {
      @include size(32px);

      @include maxScreen($sm) {
        @include size(24px);
      }
    }

    & > * {
      color: inherit;
      text-align: center;
      font-size: 14px;

      @include maxScreen($sm) {
        font-size: 12px;
      }
    }

    .close-icon {
      align-self: flex-start;

      @include minScreen($sm) {
        margin-top: 4px;
      }
    }

    &.info {
      background-color: darken($purple, 5%);
      color: $white;
    }

    &.error {
      background-color: darken($red, 5%);
      color: $white;
    }

    &.warn {
      background-color: darken($orange, 5%);
      color: $white;
    }

    &.success {
      background-color: darken($green, 5%);
      color: $white;
    }
  }
}
