.menu {
  display: flex;
  flex-direction: column;

  min-width: 80px;

  border: 0;
  box-shadow: 0 0 0 1px transparent;

  box-sizing: border-box;
  pointer-events: all;
  overflow: auto;
  z-index: $z-select;

  transition: background-color 300ms ease, border-radius 300ms ease, box-shadow 75ms ease-out;

  @mixin sizing($padding, $spacing, $border-radius, $font-size) {
    padding: $padding;
    gap: $spacing;

    border-radius: $border-radius;

    font-size: $font-size;
  }

  &.no-padding {
    @include sizing($padding: 0, $spacing: 2px, $border-radius: 2px, $font-size: 10px);
  }

  &.small {
    @include sizing($padding: 4px, $spacing: 4px, $border-radius: 4px, $font-size: 12px);
  }

  &.medium {
    @include sizing($padding: 8px, $spacing: 6px, $border-radius: 6px, $font-size: 14px);
  }

  &.large {
    @include sizing($padding: 12px, $spacing: 8px, $border-radius: 8px, $font-size: 16px);
  }

  &.extra-large {
    @include sizing($padding: 16px, $spacing: 10px, $border-radius: 10px, $font-size: 24px);
  }

  &.contained {
    @each $name, $color in $colors {
      &.#{$name} {
        background-color: $color;
        box-shadow: 0 0 0 1px $color;

        &:active {
          background-color: offset($name, 5%);
          box-shadow: 0 0 0 2px offset($name, 5%);
        }
      }
    }
  }

  &.outlined {
    background-color: transparent;

    @each $name, $color in $colors {
      &.#{$name} {
        box-shadow: 0 0 0 1px $color;

        &:active {
          background-color: rgba($color, 0.1);
          box-shadow: 0 0 0 2px offset($name, 10%);
        }
      }
    }
  }

  &.flat {
    background-color: transparent;

    @each $name, $color in $colors {
      &.#{$name} {
        &:active {
          background-color: rgba($color, 0.1);
          box-shadow: 0 0 0 2px rgba($color, 0.1);
        }
      }
    }
  }

  &.transparent {
    @each $name, $color in $colors {
      &.#{$name} {
        background-color: rgba($color, 0.3);
        box-shadow: 0 0 0 1px rgba($color, 0.3);

        &:active {
          background-color: rgba($color, 0.5);
          box-shadow: 0 0 0 2px rgba($color, 0.5);
        }
      }
    }
  }
}
