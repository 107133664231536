.calendar {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  gap: 48px;

  .calendar-wrapper {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    height: 100%;
    min-height: 0;

    background-color: $black-2;
    border-radius: 12px;

    overflow: hidden;

    .bar-progress-wrapper {
      flex-shrink: 0;

      width: 100%;

      height: 4px;

      overflow: hidden;
    }

    .calendar-header {
      display: flex;
      align-items: center;

      padding: 24px;

      border-bottom-style: solid;
      border-color: $gray-3;
      border-width: 1px;

      @include maxScreen($md) {
        gap: 16px;

        padding: 16px;
      }

      & > * {
        flex-basis: 33.3%;
        max-width: 33.3%;
        display: flex;
        align-items: center;

        @include maxScreen($sm) {
          flex-basis: unset;
          max-width: unset;
        }
      }

      .left {
        gap: 16px;

        margin-right: 16px;

        @include maxScreen($sm) {
          display: none;
        }

        svg {
          color: $gray-3;
          @include size(32px);

          @include maxScreen($xl) {
            @include size(28px);
          }
        }

        .title {
          @include inter(600);
          color: $gray-3;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @include maxScreen($xl) {
            font-size: 20px;
          }

          @include maxScreen($md) {
            display: none;
          }
        }
      }

      .middle {
        display: flex;
        justify-content: center;

        @include maxScreen($sm) {
          flex-grow: 1;
          justify-content: flex-start;
        }

        .increment-picker {
          width: 300px;

          @include maxScreen($sm) {
            width: 210px;
          }
        }
      }

      .right {
        justify-content: flex-end;
      }
    }

    .calendar-body {
      .dates {
        padding: 16px 24px 16px 16px;

        @include maxScreen($sm) {
          padding: 8px 0 16px 0;
        }

        .row {
          display: grid;

          .cell {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
              @include inter(600);
              text-align: center;
              font-size: 24px;
            }

            .date {
              display: flex;
              align-items: center;
              justify-content: center;

              width: 42px;
              height: 42px;

              border-radius: 50%;

              color: $gray-2;
            }

            .day {
              color: $gray-3;
            }

            &.today {
              .date {
                background-color: $purple-6;

                color: $black-1;
              }

              .day {
                color: $purple-6;
              }
            }
          }
        }
      }
    }
  }
}

.action-panel {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 400px;

  background-color: $black-2;
  border-radius: 12px;

  overflow: hidden;
  box-sizing: border-box;

  .action-panel-header {
    display: flex;
    align-items: center;

    padding: 0 24px 0 24px;
    min-height: 93px;
    height: 93px;

    border-bottom-style: solid;
    border-color: $gray-3;
    border-width: 1px;

    .title {
      @include inter(600);
      font-size: 18px;
      @include textOverflow;
      color: $white;

      span:first-of-type {
        color: $gray-3;
      }

      span:last-of-type {
        color: $white;
      }
    }
  }

  .action-panel-form {
    height: 100%;

    flex-grow: 1;

    min-height: 0;

    overflow: hidden;

    .form-wrapper {
      height: 100%;

      .form {
        height: 100%;

        .inner {
          gap: 24px;
          padding: 24px 0;

          .form-row {
            padding: 0 32px;

            .form-entry {
              flex-wrap: nowrap;

              label {
                padding-top: 0;
              }
            }
          }
        }
      }
    }
  }

  .button-row {
    flex-grow: 1;
    align-items: flex-end;

    width: 100%;

    padding: 0;

    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      width: 100%;
      height: fit-content;

      padding: 16px;

      background-color: $black-2;
    }
  }

  .closed-prompt {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    inset: 15%;

    .prompt-text {
      @include inter(600);
      text-align: center;
      color: $gray-3;
    }
  }
}

.cig-calendar-modal {
  z-index: $z-modal - 1;
}

.action-panel-modal {
  .modal {
    @include minScreen($sm) {
      max-height: 85%;
    }

    .modal-content {
      flex-grow: 1;

      padding: 16px 32px 16px 32px;

      @include maxScreen($sm) {
        padding: 16px;
      }

      .action-panel {
        height: 100%;
        width: 380px;
        min-height: 400px;

        @include maxScreen($sm) {
          width: unset;
        }

        border-radius: 0;
        background-color: transparent;

        .action-panel-form {
          .form-wrapper {
            .form {
              .inner {
                .form-row {
                  @include maxScreen($sm) {
                    padding: 0 12px;
                  }
                }
              }
            }
          }
        }

        .action-panel-header {
          margin-right: 0;
          padding: 0 40px 0 12px;
        }

        .button-row {
          width: 100%;
          .button-wrapper {
            justify-content: center;
            gap: 16px;

            padding: 32px 0 16px 0;

            background-color: transparent;
          }
        }
      }
    }
  }
}
