&.explanation {
  background-color: $purple-6;

  padding-top: 320px;

  @include maxScreen($lg) {
    padding-top: 260px;
  }

  @include maxScreen($md) {
    padding-top: 160px;
  }

  @include maxScreen($sm) {
    padding-top: 110px;
  }

  @include maxScreen($xs) {
    padding-top: 90px;
  }

  .inner {
    &::after {
      border-top-color: $purple-6;
    }

    .flow-text {
      font-size: 22px;

      @include maxScreen($lg) {
        font-size: 18px;
      }

      @include maxScreen($md) {
        font-size: 16px;
      }

      @include maxScreen($sm) {
        font-size: 14px;
      }

      @include maxScreen($xs) {
        font-size: 10px;
      }
    }

    .flow-icon {
      margin-top: 64px;

      @include maxScreen($sm) {
        margin-top: 48px;
      }
    }

    .landing-testimonial {
      margin-top: 64px;
    }

    .button-row {
      display: flex;
      align-items: center;
      gap: 24px;

      margin: 48px 0 64px 0;

      @include maxScreen($sm) {
        flex-direction: column;
        gap: 16px;

        margin: 32px 0 40px 0;
      }

      .button {
        width: 190px;
      }
    }
  }
}
