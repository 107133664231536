.cookie-consent-ribbon {
  @include minScreen($md) {
    width: 50vw;

    border-top-right-radius: 5px;
  }

  @include minScreen($xl) {
    width: 25vw;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    padding: 16px;

    .text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      max-width: 600px;

      .title-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;

        svg {
          font-size: 24px;
          color: $white;

          @include maxScreen($md) {
            font-size: 22px;
          }
        }

        .title {
          @include inter(500);
          font-size: 20px;
          color: $white;
          text-align: center;

          @include maxScreen($md) {
            font-size: 18px;
          }
        }
      }

      .text {
        @include inter(400);
        font-size: 13px;
        color: $white;
        text-align: center;

        @include maxScreen($md) {
          font-size: 11px;
        }

        a {
          @include inter(600);
          color: $white;
        }
      }
    }

    .button-row {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
