&.top-splash {
  display: flex;
  align-items: center;
  gap: 64px;

  margin-top: 48px;

  @include maxScreen($xl) {
    gap: 48px;
  }

  @include maxScreen($md) {
    flex-direction: column;
    gap: 32px;

    margin-top: 32px;
  }

  .cta {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;

    @include maxScreen($md) {
      align-items: center;
    }

    .title {
      @include blatant(700);
      font-size: 100px;
      color: $white;
      line-height: 0.9em;

      // Scale down
      @include maxScreen($xl) {
        font-size: 80px;
      }

      @include maxScreen($md) {
        text-align: center;
      }

      @include maxScreen($sm) {
        font-size: 56px;
      }

      @include maxScreen($xs) {
        font-size: 52px;
      }

      // Scale up
      @include minScreen($sl) {
        font-size: 144px;
      }

      @include minScreen($ul) {
        font-size: 240px;
      }

      span {
        @include blatant(700, italic);
        @include textGradient($gradient-blend-horizontal);
        line-height: 0.9em;
      }
    }

    .sub-title {
      margin-top: 32px;

      @include inter(500);
      font-size: 20px;
      color: $gray-2;

      // Scale down
      @include maxScreen($xl) {
        margin-top: 24px;

        font-size: 16px;
      }

      @include maxScreen($md) {
        margin-top: 16px;

        text-align: center;
      }

      @include maxScreen($sm) {
        font-size: 14px;
      }

      @include maxScreen($xs) {
        font-size: 12px;
      }

      // Scale up
      @include minScreen($sl) {
        font-size: 32px;
      }

      @include minScreen($ul) {
        font-size: 48px;
      }
    }

    .gradient-button {
      margin-top: 24px;

      .button {
        min-width: 200px;
      }
    }
  }

  .data {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    .data-img-wrapper {
      position: relative;

      width: 85%;

      aspect-ratio: 100 / 106;

      border-radius: 20px;

      overflow: hidden;

      @include maxScreen($sm) {
        width: 90%;
      }

      @include maxScreen($xs) {
        width: 100%;
      }

      .data-img {
        width: 100%;
      }
    }
  }
}
