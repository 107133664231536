#become-talent-old-page {
  position: relative;

  padding: 106px 0 64px 0;

  overflow-x: hidden;

  @include maxScreen($lg) {
    padding: 90px 0 48px 0;
  }

  @include maxScreen($sm) {
    padding: 16px 0;
  }

  .divider:not(.accordion-divider) {
    opacity: 0.1;
  }

  @mixin sectionPadding {
    padding: 0 128px;

    @include maxScreen($xl) {
      padding: 0 64px;
    }

    @include maxScreen($md) {
      padding: 0 48px;
    }

    @include maxScreen($sm) {
      padding: 0 16px;
    }
  }

  .cta-button {
    .button {
      pointer-events: all;

      &:not(:hover) {
        background: unset;
        background-color: $purple;
      }
    }
  }

  .section {
    &:not(.no-padding) {
      @include sectionPadding;
    }

    @import "./sections/topSplash.scss";
    @import "./sections/why.scss";
    @import "./sections/how.scss";
    @import "./sections/benefits.scss";
    @import "./sections/features.scss";
    @import "./sections/games.scss";
    @import "./sections/faq.scss";
    @import "./sections/lastChance.scss";

    .become-talent-old-header {
      display: flex;
      flex-direction: column;
      gap: 12px;

      max-width: 60%;

      @include maxScreen($md) {
        max-width: 100%;
        align-items: center;

        .title,
        .sub-title {
          text-align: center;
        }
      }

      &.center {
        align-items: center;

        margin: 0 auto;

        .title,
        .sub-title {
          text-align: center;
        }
      }

      .title,
      .sub-title {
        line-height: 1.2em;
      }

      .title {
        @include blatant(700);
        color: $white;
        font-size: 40px;

        // Scale down
        @include maxScreen($xl) {
          font-size: 32px;
        }

        @include maxScreen($lg) {
          font-size: 28px;
        }

        @include maxScreen($sm) {
          font-size: 24px;
        }

        @include maxScreen($xs) {
          font-size: 20px;
        }

        // Scale up
        @include minScreen($sl) {
          font-size: 48px;
        }

        @include minScreen($ul) {
          font-size: 80px;
        }

        span {
          @include textGradient($gradient-faded-horizontal);
        }
      }

      .sub-title {
        @include inter(500);
        color: $gray-3;
        font-size: 20px;

        // Scale down
        @include maxScreen($xl) {
          font-size: 18px;
        }

        @include maxScreen($lg) {
          font-size: 16px;
        }

        @include maxScreen($sm) {
          font-size: 14px;
        }

        @include maxScreen($xs) {
          font-size: 12px;
        }

        // Scale up
        @include minScreen($sl) {
          font-size: 28px;
        }

        @include minScreen($ul) {
          font-size: 40px;
        }
      }
    }
  }
}
