#payment-completion-page {
  .banner {
    min-height: 50px;

    padding: 32px;

    background-color: $purple;

    @include maxScreen($lg) {
      min-height: 30px;
    }

    .banner-content {
      display: flex;
      gap: 24px;

      @include maxScreen($sm) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;

        height: 128px;
      }

      @include minScreen($sm) {
        align-items: flex-end;

        height: 162px;
      }

      .title {
        color: $white;
        @include inter(600);
        font-size: 36px;
      }

      .button {
        width: fit-content;
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    .title {
      color: $gray-3;
      @include inter(600);
      font-size: 32px;
      @include textOverflow();

      @include maxScreen($sm) {
        font-size: 28px;
        text-align: center;
      }
    }

    .left,
    .right {
      display: flex;
      flex-direction: column;
      gap: 24px;

      padding: 48px 32px;

      @include maxScreen($md) {
        padding: 32px 32px 0 32px;
      }

      @include maxScreen($sm) {
        padding: 24px 0 0 0;
      }
    }

    .left {
      width: 400px;

      @include maxScreen($sm) {
        width: 100%;
      }

      .details {
        position: relative;

        min-height: 464px;

        background-color: $black-2;
        border-radius: 6px;

        overflow: hidden;

        @include maxScreen($sm) {
          border-radius: 0;
        }

        .group {
          display: flex;
          flex-direction: column;
          gap: 24px;

          padding: 32px 24px;

          @include maxScreen($sm) {
            padding: 24px 16px;
          }

          .row {
            display: flex;
            gap: 12px;

            & > * {
              &:not(.no-grow) {
                flex-grow: 1;
                min-width: 0;
              }
            }

            p {
              @include inter(600);
              font-size: 16px;

              @include maxScreen($sm) {
                font-size: 14px;
              }

              &:first-of-type {
                @include textOverflow();
              }

              &:last-of-type {
                &:not(.avatar-fallback) {
                  text-align: right;
                }
              }

              &.large {
                font-size: 18px;

                @include maxScreen($sm) {
                  font-size: 16px;
                }
              }
            }

            .label {
              color: $gray-2;
            }

            .game-name {
              font-size: 20px;
            }

            .top-details {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              margin: 6px 0;

              p,
              p:last-child {
                text-align: left;
                @include textOverflow();
              }

              div {
                display: flex;
                flex-direction: column;
                gap: 8px;
              }
            }
          }
        }
      }
    }

    .right {
      flex-grow: 1;

      .tickets {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: 32px 16px;

        min-height: 400px;

        @include maxScreen($sm) {
          justify-content: center;

          padding: 0 32px;
        }

        .ticket-wrapper {
          display: flex;
          flex-direction: column;
          gap: 12px;

          &.shared {
            padding-bottom: 24px;
          }

          .button-row {
            display: flex;
            gap: 16px;

            z-index: 100;

            .button {
              flex-grow: 1;
            }
          }

          .ticket {
            cursor: pointer;
          }
        }
      }
    }
  }

  .guest-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 64px 16px 32px 16px;

    @include maxScreen($sm) {
      padding: 32px 16px 32px 16px;
    }

    .icon-wrapper {
      padding: 12px;

      border-radius: 50%;
      background-color: $purple-6;

      .cross-icon {
        margin-bottom: -4px;

        color: $red;
        @include size(40px);
      }
    }

    .titles-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: min-content;

      .title {
        margin-top: 16px;

        color: $white;
        @include inter(700);
        font-size: 40px;
        text-align: center;
        white-space: nowrap;

        @include maxScreen($xs) {
          font-size: 28px;
        }
      }

      .sub-title {
        margin-top: 16px;

        color: $gray-3;
        @include inter(500);
        font-size: 16px;
        text-align: center;

        @include maxScreen($xs) {
          margin-top: 12px;

          font-size: 14px;
        }
      }
    }

    .cards-wrapper {
      display: flex;
      align-items: stretch;
      gap: 24px;

      margin-top: 48px;

      @include maxScreen($md) {
        flex-direction: column;
      }

      @include maxScreen($xs) {
        margin-top: 32px;
      }

      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 18px;

        width: 270px;
        min-height: calc(375px - 48px);

        padding: 24px 16px 24px 16px;

        background-color: $black-2;
        border-radius: 5px;
        box-shadow: 0 0 0 1px $black-2;

        transition: box-shadow 300ms ease;

        &:hover {
          box-shadow: 0 0 0 1px $gray-3;
        }

        .card-icon {
          @include size(56px);

          color: $white;
        }

        .text-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;

          margin: 0 24px;

          .title {
            color: $white;
            @include inter(700);
            font-size: 24px;
            text-align: center;
          }

          .text {
            color: $gray-3;
            @include inter(500);
            font-size: 16px;
            text-align: center;

            &.small {
              margin-top: 16px;

              font-size: 14px;
            }
          }
        }

        .buttons-wrapper {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: flex-end;
          gap: 8px;

          width: 100%;
        }
      }
    }
  }
}
