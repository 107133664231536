.pill {
  display: block;

  height: min-content;
  width: min-content;

  border: 0;
  box-shadow: 0 0 0 1px transparent;

  box-sizing: border-box;

  &.no-wrap {
    white-space: nowrap;
  }

  &.do-justify {
    .pill-inner {
      justify-content: var(--justify-content);
    }
  }

  a {
    color: inherit;
  }

  .pill-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      @include inter(600);
      @include textOverflow;
    }

    .progress-wrapper {
      position: relative;

      .progress {
        position: absolute;

        left: 0;
      }
    }
  }

  @mixin sizing(
    $vertical-padding,
    $horizontal-padding,
    $border-radius,
    $font-size,
    $icon-size,
    $icon-spacing,
    $scale,
    $translate
  ) {
    padding: $vertical-padding $horizontal-padding;

    border-radius: $border-radius;

    font-size: $font-size;

    .pill-icon {
      @include size($icon-size);

      &:first-child {
        margin-right: $icon-spacing;
      }

      &:last-child {
        margin-left: $icon-spacing;
      }
    }

    .pill-inner {
      .progress-wrapper {
        @include size($icon-size);

        .progress {
          @include size($icon-size);

          transform: scale($scale) translate($translate, $translate);
        }
      }
    }

    &.rounded {
      border-radius: calc($border-radius * 4.3);
    }
  }

  &.extra-small {
    @include sizing(
      $vertical-padding: 3px,
      $horizontal-padding: 5.5px,
      $border-radius: 3px,
      $font-size: 10px,
      $icon-size: 12px,
      $icon-spacing: 4px,
      $scale: 0.175,
      $translate: -32px
    );
  }

  &.small {
    @include sizing(
      $vertical-padding: 7px,
      $horizontal-padding: 9px,
      $border-radius: 4px,
      $font-size: 12px,
      $icon-size: 15px,
      $icon-spacing: 6px,
      $scale: 0.225,
      $translate: -30px
    );
  }

  &.medium {
    @include sizing(
      $vertical-padding: 12px,
      $horizontal-padding: 18px,
      $border-radius: 6px,
      $font-size: 14px,
      $icon-size: 17px,
      $icon-spacing: 8px,
      $scale: 0.25,
      $translate: -30px
    );
  }

  &.large {
    @include sizing(
      $vertical-padding: 18px,
      $horizontal-padding: 27px,
      $border-radius: 8px,
      $font-size: 16px,
      $icon-size: 20px,
      $icon-spacing: 10px,
      $scale: 0.3,
      $translate: -28px
    );
  }

  &.extra-large {
    @include sizing(
      $vertical-padding: 28px,
      $horizontal-padding: 42px,
      $border-radius: 10px,
      $font-size: 24px,
      $icon-size: 29px,
      $icon-spacing: 14px,
      $scale: 0.4,
      $translate: -25px
    );
  }

  @each $name, $color in $colors {
    &.#{$name}-text {
      color: $color;
    }

    &.#{$name}-icon {
      .pill-icon {
        color: $color;

        &.mask {
          mask-size: cover;
          mask-repeat: no-repeat;
          background-color: $color;
        }
      }
    }
  }

  &.contained {
    @each $name, $color in $colors {
      &.#{$name} {
        background-color: $color;
        box-shadow: 0 0 0 1px $color;
      }
    }
  }

  &.outlined {
    background-color: transparent;

    @each $name, $color in $colors {
      &.#{$name} {
        box-shadow: 0 0 0 1px $color;
      }
    }
  }

  &.flat {
    background-color: transparent;
  }

  &.transparent {
    @each $name, $color in $colors {
      &.#{$name} {
        background-color: rgba($color, 0.3);
        box-shadow: 0 0 0 1px rgba($color, 0.3);
      }
    }
  }
}
