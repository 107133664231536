#experiences-request-page.desktop {
  display: flex;
  align-items: stretch;

  max-height: 100vh;

  .left-wrapper {
    display: flex;
    flex-direction: column;

    flex-basis: 60%;
    min-width: 60%;
    max-height: inherit;

    padding: 96px 32px 32px 32px;

    box-sizing: border-box;
    overflow: hidden;

    @include maxScreen($md) {
      flex-basis: 55%;
      min-width: 55%;

      padding: 96px 16px 16px 16px;
    }

    .tabs {
      flex-shrink: 0;
    }

    .form-wrapper {
      flex-grow: 1;

      overflow: auto;

      @include maxScreen($md) {
        margin: 0;
        width: 100%;
      }

      .form {
        .inner {
          @include maxScreen($md) {
            padding: 0 4px;
          }

          .form-section {
            gap: 32px;

            &.game {
              .form-row {
                max-width: 60%;

                @include maxScreen($lg) {
                  max-width: 80%;
                }

                @include maxScreen($md) {
                  max-width: 100%;
                }
              }
            }

            &.tickets {
              .form-row {
                max-width: 90%;

                @include maxScreen($lg) {
                  max-width: 100%;
                }
              }
            }

            .form-row {
              .form-entry {
                gap: 12px;
              }
            }

            .length-stream-row {
              @include maxScreen($md) {
                flex-direction: column;
                align-items: stretch;
              }

              .session-length-entry,
              .stream-option-entry {
                flex-basis: 50%;
              }
            }
          }
        }
      }
    }
  }

  .experiences-request-summary-panel {
    display: flex;
    flex-direction: column;

    flex-basis: 40%;
    min-width: 40%;

    padding: 96px 40px 64px 40px;

    background-color: $black-2;

    box-sizing: border-box;

    @include maxScreen($md) {
      flex-basis: 45%;
      min-width: 45%;

      padding: 96px 16px 16px 16px;
    }

    .gradient-button,
    .button {
      width: 100%;

      margin-top: auto;
    }
  }
}
