&.events {
  width: 80%;

  margin: 48px auto 0 auto;

  @include maxScreen($lg) {
    width: 90%;
  }

  .box {
    padding: 24px 32px;
    margin-top: 48px;

    background-color: $black-2;
    border-radius: 5px;

    @include maxScreen($sm) {
      padding: 24px 16px;
    }

    &.active {
      .box-header {
        .box-buttons {
          .icon {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .box-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;

      @include maxScreen($md) {
        flex-direction: column;
      }

      .box-buttons {
        display: flex;
        align-items: center;
        gap: 12px;

        .icon {
          svg {
            transition: transform 300ms ease;
          }
        }
      }
    }

    .box-content-wrapper {
      .box-content {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        min-height: 64px;

        padding: 16px 0;
      }

      .buttons-row {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
      }
    }
  }
}
