&.cta-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  padding-top: 64px;
  padding-bottom: 64px;

  // Scale up
  @include minScreen($sl) {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  @include minScreen($ul) {
    padding-top: 192px;
    padding-bottom: 192px;
  }

  // Scale down
  @include maxScreen($sm) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .splash-title {
    font-size: 64px;

    // Scale down
    @include maxScreen($sm) {
      font-size: 48px;
    }

    @include maxScreen($xs) {
      font-size: 40px;
    }
  }
}
