.simple-calendar {
  @each $name, $color in $colors {
    &.#{$name}-body-text {
      color: $color;
    }
  }

  @each $name, $color in $colors {
    &.#{$name}-head-text {
      color: $color;
    }
  }

  @mixin sizing($border-radius, $font-size) {
    border-radius: $border-radius;

    .simple-calendar-inner {
      .simple-calendar-table {
        thead,
        tbody {
          th,
          td {
            font-size: $font-size;

            p {
              font-size: $font-size;
            }
          }
        }
      }
    }
  }

  &.extra-small {
    @include sizing($border-radius: 3px, $font-size: 10px);
  }

  &.small {
    @include sizing($border-radius: 4px, $font-size: 12px);
  }

  &.medium {
    @include sizing($border-radius: 6px, $font-size: 14px);
  }

  &.large {
    @include sizing($border-radius: 8px, $font-size: 16px);
  }

  &.extra-large {
    @include sizing($border-radius: 10px, $font-size: 24px);
  }

  &.contained,
  &.outlined {
    @each $name, $color in $colors {
      &.#{$name} {
        background-color: $color;
        box-shadow: 0 0 0 1px $color;
      }
    }
  }

  &.flat,
  &.transparent {
    @each $name, $color in $colors {
      &.#{$name} {
        background-color: rgba($color, 0.3);
        box-shadow: 0 0 0 1px rgba($color, 0.3);
      }
    }
  }

  .simple-calendar-inner {
    width: 100%;
    height: 100%;

    .simple-calendar-table {
      width: 100%;
      height: 100%;

      padding: 2px;

      box-sizing: border-box;

      thead {
        th {
          height: 32px;
        }
      }

      thead,
      tbody {
        padding: 2px 4px;

        th,
        td {
          @include inter(600);
          text-align: center;

          padding: 1px;
          margin: 0;

          .icon {
            margin: 0 auto;
          }
        }
      }
    }
  }
}
