.splash {
  position: relative;

  overflow-x: hidden;

  .divider:not(.accordion-divider) {
    opacity: 0.1;
  }

  @mixin sectionPadding {
    padding: 0 128px;

    @include maxScreen($xl) {
      padding: 0 64px;
    }

    @include maxScreen($md) {
      padding: 0 48px;
    }

    @include maxScreen($sm) {
      padding: 0 16px;
    }
  }

  .cta-button {
    .button {
      pointer-events: all;

      &:not(:hover) {
        background: unset;
        background-color: $purple;
      }
    }
  }

  .section {
    &:not(.no-padding) {
      @include sectionPadding;
    }

    @import "./sections/topSplash.scss";
    @import "./sections/howItWorks.scss";
    @import "./sections/fanTestimonials.scss";
    @import "./sections/testimonials.scss";
    @import "./sections/splitViews.scss";
    @import "./sections/faqs.scss";
    @import "./sections/cigCardCarousel.scss";
    @import "./sections/gamesCarousel.scss";
    @import "./sections/upcomingEvents.scss";

    .splash-title {
      @include blatant(700);
      color: $white;
      font-size: 40px;

      &.center {
        text-align: center;
      }

      // Scale down
      @include maxScreen($xl) {
        font-size: 32px;
      }

      @include maxScreen($lg) {
        font-size: 28px;
      }

      @include maxScreen($sm) {
        font-size: 24px;
      }

      @include maxScreen($xs) {
        font-size: 20px;
      }

      // Scale up
      @include minScreen($sl) {
        font-size: 48px;
      }

      @include minScreen($ul) {
        font-size: 80px;
      }
    }

    .splash-text {
      @include inter(500);
      color: $gray-2;
      font-size: 20px;

      // Scale down
      @include maxScreen($xl) {
        font-size: 18px;
      }

      @include maxScreen($lg) {
        font-size: 16px;
      }

      @include maxScreen($sm) {
        font-size: 14px;
      }

      @include maxScreen($xs) {
        font-size: 12px;
      }

      // Scale up
      @include minScreen($sl) {
        font-size: 28px;
      }

      @include minScreen($ul) {
        font-size: 40px;
      }

      &.center {
        text-align: center;
      }

      &.large {
        font-size: 28px;

        // Scale down
        @include maxScreen($xl) {
          font-size: 26px;
        }

        @include maxScreen($lg) {
          font-size: 22px;
        }

        @include maxScreen($sm) {
          font-size: 18px;
        }

        @include maxScreen($xs) {
          font-size: 16px;
        }

        // Scale up
        @include minScreen($sl) {
          font-size: 36px;
        }

        @include minScreen($ul) {
          font-size: 48px;
        }
      }
    }

    .splash-box {
      background-color: $black-2;
      border-radius: 5px;

      @include minScreen($sl) {
        border-radius: 10px;
      }

      @include minScreen($ul) {
        border-radius: 16px;
      }
    }

    .dark-gradient {
      @include textGradient($gradient-blend-horizontal);
    }

    .light-gradient {
      @include textGradient($gradient-faded-horizontal);
    }
  }

  .splash-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 40px 128px;
    margin-top: 48px;

    // Scale up
    @include minScreen($ul) {
      padding: 96px 128px;
    }

    @include minScreen($sl) {
      padding: 64px 128px;
    }

    // Scale down
    @include maxScreen($xl) {
      padding: 40px 64px;
    }

    @include maxScreen($md) {
      padding: 40px 48px;
    }

    @include maxScreen($sm) {
      padding: 32px;
      margin-top: 16px;
    }

    .copyright {
      @include inter(600);
      color: $gray-2;
      font-size: 18px;

      // Scale up
      @include minScreen($ul) {
        font-size: 32px;
      }

      @include minScreen($sl) {
        font-size: 24px;
      }

      // Scale down
      @include maxScreen($sm) {
        font-size: 14px;
      }
    }
  }
}
