&.split-views {
  display: flex;
  flex-direction: column;
  gap: 96px;

  margin-top: 96px;

  // Scale up
  @include minScreen($sl) {
    gap: 128px;

    margin-top: 128px;
  }

  @include minScreen($ul) {
    gap: 192px;

    margin-top: 192px;
  }

  // Scale down
  @include maxScreen($sm) {
    gap: 64px;

    margin-top: 64px;
  }

  @include maxScreen($xs) {
    gap: 48px;
  }

  .split-view-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;

    // Scale up
    @include minScreen($sl) {
      gap: 96px;
    }

    @include minScreen($ul) {
      gap: 128px;
    }

    // Scale down
    @include maxScreen($sm) {
      gap: 48px;
    }

    @include maxScreen($xs) {
      gap: 32px;
    }

    .split-view {
      display: flex;
      justify-content: space-between;
      gap: 32px;

      // Scale down
      @include maxScreen($md) {
        flex-direction: column;
        gap: 64px;
      }

      @include maxScreen($sm) {
        gap: 32px;
      }

      &.reverse {
        @include minScreen($md) {
          flex-direction: row-reverse;

          .img-wrapper {
            img {
              display: block;
              margin-left: auto;
            }
          }
        }
      }

      .img-wrapper {
        flex-basis: 50%;

        @include maxScreen($md) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        img {
          width: 80%;

          border-radius: 5px;

          // Scale down
          @include maxScreen($sm) {
            width: 90%;
          }

          @include maxScreen($xs) {
            width: 100%;
          }
        }
      }

      .content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 24px;

        flex-basis: 50%;

        // Scale down
        @include maxScreen($sm) {
          padding: 0 16px;
        }

        @include maxScreen($xs) {
          padding: 0;
        }

        .become-talent-title {
          line-height: 1em;
        }

        .button-row {
          display: flex;
          gap: 12px;
        }
      }
    }
  }
}
