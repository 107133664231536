.form-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;

  @include maxScreen($sm) {
    margin: 8px 0 24px 0;
  }

  .form {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    transition: opacity 300ms linear, transform 300ms linear;
    width: 100%;
    min-height: 0;

    overflow: hidden auto;

    &.no-transition,
    &.appear-active,
    &.appear-done {
      transition: none !important;
    }

    &:not(.next) {
      opacity: 0;
      transform: translateX(0);

      &.enter-active {
        transition: none !important;
      }

      &.enter-active,
      &.enter-done,
      &.appear-active,
      &.appear-done {
        opacity: 1;
        transform: translateX(0);
      }

      &.exit-active {
        opacity: 0;
        transform: translateX(600px);
      }

      &.exit-done {
        opacity: 0;
        transform: translateX(0);
      }
    }

    &.next {
      position: absolute;
      inset: 0;

      opacity: 0;
      transform: translateX(-600px);

      &.enter-active,
      &.enter-done {
        opacity: 1;
        transform: translateX(0);
      }

      &.exit-active {
        opacity: 0;
        transform: translateX(600px);
      }

      &.exit-done {
        opacity: 0;
        transform: translateX(-600px);
      }
    }

    .inner {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      flex-grow: 1;
      gap: 40px 32px;
      padding: 32px 2px 40px 2px;

      @include maxScreen($lg) {
        gap: 40px 32px;
        padding: 32px 2px;
      }

      @include maxScreen($md) {
        gap: 32px;
        margin: 0;
      }

      @include maxScreen($sm) {
        gap: 32px;
        padding: 2px;
      }

      @include maxScreen($xs) {
        gap: 24px;
      }
    }
  }
}

.form-error {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  margin: 16px 0;

  @include inter(600);
  font-size: 14px;
  color: $red;
  text-align: center;

  @include maxScreen($sm) {
    margin: 8px 0 24px 0;
  }

  svg {
    @include size(24px);
  }
}

.validation {
  display: flex;
  align-items: center;
  margin: 0 2px;
  gap: 8px;

  svg {
    @include size(24px);
  }

  &.not-tooltip {
    position: relative;
    flex-direction: column;
    align-items: flex-start;

    width: calc(100% - 16px);

    margin: 4px 0 -16px 0;

    transform: translateY(2px);
  }

  &.error {
    color: $red;
    .text {
      color: $red;
    }
  }
  &.success {
    color: $green;
    .text {
      color: $green;
    }
  }

  .text {
    max-width: 100%;

    font-size: 12px;
    @include inter(600);
    white-space: nowrap;
    @include textOverflow;
  }
}

.form-row {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  gap: 24px;

  .form-label {
    width: 100%;
    padding: 8px 2px;
    letter-spacing: 0.1em;
    font-size: 14px;
    @include inter(600);
    white-space: nowrap;
  }

  .form-entry {
    flex-grow: 1;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    box-sizing: border-box;
    height: 100%;
    max-width: 100%;
    color: $white;

    .textarea {
      width: 100%;
      height: 100%;
    }

    &.error {
      .footnote {
        display: none;
      }
    }

    .footnote {
      position: absolute;

      bottom: -1px;
      transform: translateY(100%);

      font-size: 12px;
      @include inter(400);
      text-align: left;
    }
  }
}
