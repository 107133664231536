@import "./paymentCompletion/experiencesPaymentCompletion.scss";

#experiences-page {
  min-height: inherit;

  padding: 106px 0 64px 0;

  box-sizing: border-box;

  @include maxScreen($lg) {
    padding: 90px 0 48px 0;
  }

  @include maxScreen($sm) {
    padding: 16px 0;
  }

  .scroll-carousel-wrapper {
    position: relative;

    min-height: 375px;

    margin: 16px 32px 0 32px;

    @include maxScreen($sm) {
      margin: 16px 20px;
    }
  }
}
