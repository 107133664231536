&.build-bar {
  width: 80%;

  margin: 48px auto 0 auto;

  @include maxScreen($lg) {
    width: 90%;
  }

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    padding: 32px;

    background-color: $black-2;
    border-radius: 5px;

    @include maxScreen($md) {
      flex-direction: column;
    }

    @include maxScreen($sm) {
      gap: 24px;

      padding: 24px;
    }
  }
}
