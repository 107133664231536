@import "./manage/experiencesManage.scss";
@import "./request/experiencesRequest.scss";
@import "./requests/experiencesRequests.scss";

.experiences-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  margin: 0 32px;

  @include maxScreen($sm) {
    flex-direction: column;
    align-items: stretch;

    margin: 0 20px;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title,
    .sub-title {
      line-height: 1.2em;
    }

    .title {
      @include blatant(600);
      color: $white;
      font-size: 40px;

      @include maxScreen($xl) {
        font-size: 32px;
      }

      @include maxScreen($lg) {
        font-size: 28px;
      }

      @include maxScreen($sm) {
        font-size: 24px;
      }
    }

    .sub-title {
      @include inter(500);
      color: $gray-3;
      font-size: 20px;

      @include maxScreen($xl) {
        font-size: 18px;
      }

      @include maxScreen($lg) {
        font-size: 16px;
      }

      @include maxScreen($sm) {
        font-size: 14px;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 16px;

    @include maxScreen($sm) {
      flex-direction: column;
      align-items: stretch;
      gap: 12px;
    }

    .button {
      min-width: 200px;
    }
  }
}
