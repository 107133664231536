.file-picker {
  position: relative;

  overflow: hidden;

  @mixin visibleStyles() {
    & > .cover,
    & > .file-picker-cta {
      opacity: 1;
    }

    & > .placeholder {
      opacity: 0;
    }
  }

  &.standard {
    width: 100%;
    height: 150px;

    &:hover {
      @include visibleStyles();
      .preview-wrapper {
        @include visibleStyles();
      }
    }

    &.is-loading {
      pointer-events: none !important;
    }

    .preview-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      height: 100%;

      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      background-color: $black-4;
      border-radius: 5px;

      transition: background-color 300ms ease;
      cursor: pointer;
      overflow: hidden;

      .top-bar {
        position: absolute;

        height: 20px;
        width: calc(100% - 24px);

        top: 0;
        left: 0;
        right: 0;

        padding: 3px 12px;

        background-color: rgba($black-3, 0.3);

        .file-name {
          color: $white;
          font-size: 14px;
          white-space: nowrap;

          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &.hidden {
    display: flex;

    width: 100%;
    height: 100%;

    cursor: pointer;
    pointer-events: all !important;

    .cover {
      background-color: rgba($black-1, 0.25);
    }

    &:hover {
      .cover {
        background-color: rgba($black-1, 0.5);
      }
    }

    &.is-loading {
      pointer-events: none !important;
    }

    @include visibleStyles();

    .preview-wrapper {
      @include visibleStyles();
    }

    .file-picker-input {
      pointer-events: all !important;
    }
  }

  .cover {
    position: absolute;

    inset: 0;

    opacity: 0;

    transition: opacity 300ms ease, background-color 75ms ease;
    pointer-events: none;
    z-index: 100;
  }

  .document-icon {
    position: absolute;

    left: 50%;
    top: 50%;

    font-size: 64px;
    color: $black-3;

    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 100;
  }

  .file-picker-cta {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;

    inset: 0;

    opacity: 0;

    transition: opacity 300ms ease;
    pointer-events: none;
    z-index: 100;

    .file-picker-cta-icon {
      @include size(24px);

      color: $white;
    }

    .file-picker-cta-text {
      @include inter(500);
      font-size: 16px;
      color: $white;
      text-align: center;

      @include maxScreen($md) {
        font-size: 12px;
      }
    }
  }

  .placeholder {
    position: absolute;
    opacity: 1;

    left: 50%;
    top: 50%;

    @include inter(600);
    text-align: center;
    font-size: 14px;

    transform: translate(-50%, -50%);
    transition: opacity 150ms ease;
    z-index: 100;

    &.error {
      color: $red;
    }
  }

  .file-picker-input {
    display: none;
  }
}
