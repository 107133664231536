&.faq {
  margin: 48px 0;

  // Scale up
  @include minScreen($sl) {
    margin: 96px 0;
  }

  @include minScreen($ul) {
    margin: 160px 0;
  }

  .accordions {
    display: flex;
    flex-direction: column;
    gap: 24px;

    margin-top: 48px;

    // Scale down
    @include maxScreen($sm) {
      margin-top: 32px;
    }

    // Scale up
    @include minScreen($ul) {
      gap: 48px;
    }
  }
}
