&.clips {
  width: 80%;

  margin: 64px auto 0 auto;

  @include maxScreen($lg) {
    width: 90%;
  }

  .scroll-carousel {
    margin-top: 16px;
  }
}
