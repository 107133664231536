&.how {
  margin: 48px 0;

  // Scale up
  @include minScreen($sl) {
    margin: 96px 0;
  }

  @include minScreen($ul) {
    margin: 160px 0;
  }

  .become-talent-header {
    @include sectionPadding;
  }

  .steps-wrapper {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    gap: 96px;

    margin-top: 64px;
    padding: 0 32px;

    @include maxScreen($xl) {
      gap: 48px;
    }

    @include maxScreen($lg) {
      gap: 32px;

      margin-top: 48px;
    }

    @include maxScreen($md) {
      flex-wrap: wrap;
    }

    // Scale up
    @include minScreen($sl) {
      margin-top: 96px;
    }

    @include minScreen($ul) {
      margin-top: 128px;
    }

    .number-line {
      position: absolute;

      top: 30px;

      border-width: 2px;
      opacity: 1;

      // Scale down
      @include maxScreen($xl) {
        top: 25px;
      }

      @include maxScreen($lg) {
        top: 20px;
      }

      // Scale up
      @include minScreen($sl) {
        top: 35px;
      }

      @include minScreen($ul) {
        top: 55px;
      }
    }

    .step {
      flex-basis: 33%;
      display: flex;
      flex-direction: column;
      align-items: center;

      max-width: 400px;
      min-width: 280px;

      // Scale up
      @include minScreen($ul) {
        max-width: 700px;
      }

      .diamond {
        display: flex;
        align-items: center;
        justify-content: center;

        @include size(60px);

        background-color: $purple;
        border-radius: 10px;

        transform: rotate(45deg);

        // Scale down
        @include maxScreen($xl) {
          @include size(50px);
        }

        @include maxScreen($lg) {
          @include size(40px);
        }

        @include maxScreen($sm) {
          @include size(30px);

          border-radius: 5px;
        }

        // Scale up
        @include minScreen($sl) {
          @include size(70px);
        }

        @include minScreen($ul) {
          @include size(110px);

          border-radius: 15px;
        }

        .number {
          @include blatant(700);
          font-size: 26px;
          color: $white;

          transform: rotate(-45deg);

          // Scale down
          @include maxScreen($xl) {
            font-size: 22px;
          }

          @include maxScreen($lg) {
            font-size: 18px;
          }

          @include maxScreen($sm) {
            font-size: 16px;
          }

          // Scale up
          @include minScreen($sl) {
            font-size: 32px;
          }

          @include minScreen($ul) {
            font-size: 48px;
          }
        }
      }

      .icon {
        @include size(70px);

        margin-top: 48px;

        // Scale down
        @include maxScreen($xl) {
          @include size(60px);

          margin-top: 40px;
        }

        @include maxScreen($lg) {
          @include size(50px);

          margin-top: 32px;
        }

        @include maxScreen($sm) {
          @include size(40px);

          margin-top: 24px;
        }

        // Scale up
        @include minScreen($sl) {
          @include size(100px);

          margin-top: 64px;
        }

        @include minScreen($ul) {
          @include size(140px);

          margin-top: 80px;
        }
      }

      .content-wrapper {
        margin-top: 8px;

        .title {
          @include inter(700);
          color: $white;
          font-size: 26px;
          text-align: center;

          // Scale down
          @include maxScreen($xl) {
            font-size: 22px;
          }

          @include maxScreen($lg) {
            font-size: 18px;
          }

          // Scale up
          @include minScreen($sl) {
            margin-top: 16px;

            font-size: 32px;
          }

          @include minScreen($ul) {
            margin-top: 24px;

            font-size: 56px;
          }
        }

        .text {
          margin-top: 4px;

          @include inter(500);
          color: $gray-3;
          font-size: 20px;
          text-align: center;

          // Scale down
          @include maxScreen($xl) {
            font-size: 16px;
          }

          @include maxScreen($lg) {
            font-size: 12px;
          }

          // Scale up
          @include minScreen($sl) {
            font-size: 24px;
          }

          @include minScreen($ul) {
            font-size: 36px;
          }
        }
      }
    }
  }
}
