.yakkr-banner {
  overflow: visible;

  transition: color 300ms ease;

  @each $name, $color in $colors {
    &.#{$name} {
      color: $color;

      &.clickable {
        &:hover {
          color: offset($name, 20%);
        }
      }
    }
  }

  &.disabled {
    @include disabled;
  }

  &.clickable {
    cursor: pointer;
  }

  &.animated {
    path {
      @include jump(15px, 1.5s);

      @for $i from 1 through 5 {
        &:nth-of-type(#{$i}) {
          animation-delay: #{$i}00ms;
        }
      }
    }
  }
}
