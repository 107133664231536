&.games {
  margin: 48px 0;

  // Scale up
  @include minScreen($sl) {
    margin: 96px 0;
  }

  @include minScreen($ul) {
    margin: 160px 0;
  }

  .scroll-carousel-wrapper {
    position: relative;

    margin-top: 32px;

    min-height: 306px;

    @include maxScreen($sm) {
      min-height: 252px;
    }
  }
}
