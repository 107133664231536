.selectable-text {
  display: flex;
  align-items: center;

  gap: 8px;

  &.active {
    cursor: pointer;

    padding: 2px 8px;
    margin-left: -8px;

    border-radius: 4px;

    transition: background-color 300ms ease;

    &:hover {
      background-color: rgba($gray-3, 0.3);
    }
  }
}
