&.cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  margin: 64px 0 96px 0;

  // Scale up
  @include minScreen($sl) {
    margin: 96px 0 128px 0;
  }

  @include minScreen($ul) {
    margin: 128px 0 196px 0;
  }

  // Scale down
  @include maxScreen($sm) {
    margin: 32px 0 48px 0;
  }
}
