.experience-request-card {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  height: 1.3em;
  max-height: 1.3em;

  border-radius: 0.03em;

  box-sizing: border-box;
  user-select: none;
  overflow: hidden;

  .background-gradient {
    position: absolute;

    width: 100%;

    inset: 0;

    z-index: -100;
  }

  .top-wrapper {
    display: flex;
    align-items: center;
    gap: 0.05em;

    margin: 0.05em;

    .pill {
      min-width: 60px;
    }
  }

  .bottom-wrapper {
    display: flex;
    flex-direction: column;

    margin: 0.05em;

    .price {
      display: flex;
      align-items: center;

      @include inter(600);
      font-size: 0.15em;
      color: $white;

      .currency {
        font-size: 0.5em;
      }
    }

    .date-time {
      .date {
        @include inter(600);
        font-size: 0.065em;
        color: $white;
      }

      .time {
        @include inter(600);
        font-size: 0.065em;
        color: $white;
      }
    }

    .platform-game {
      margin-top: 0.5em;

      @include inter(600);
      font-size: 0.065em;
      color: $white;

      opacity: 0.8;
    }

    .state {
      display: flex;
      align-items: center;
      gap: 0.1em;

      margin-top: 0.5em;

      @include inter(700);
      font-size: 0.05em;

      opacity: 0.8;

      svg {
        font-size: 1.5em;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 0.03em;

      min-height: 0.17em;

      margin-top: 0.075em;
    }

    .created-at {
      margin-top: 0.5em;

      @include inter(700);
      font-size: 0.04em;
      color: $white;

      opacity: 0.75;
    }
  }

  .more-info-wrapper {
    position: absolute;

    inset: 0;

    .more-info-inner {
      display: flex;
      flex-direction: column;

      height: 100%;
      width: 100%;

      padding: 0.05em;

      background: linear-gradient(to top, $black 0%, rgba($black, 0) 100%);

      box-sizing: border-box;

      .tickets-number {
        @include inter(600);
        font-size: 0.15em;
        color: $white;
        line-height: 1em;
      }

      .tickets-text {
        @include inter(600);
        font-size: 0.065em;
        color: $white;
        line-height: 1em;
      }

      .title {
        margin-top: 0.75em;

        @include inter(600);
        font-size: 0.065em;
        color: $white;
      }

      .input-wrapper {
        width: calc(100% + 0.05em * 2);

        margin: 0 -0.05em -0.05em -0.05em;

        font-size: inherit;

        .input {
          padding: 0.77em;

          @include inter(400);
          font-size: 0.065em;

          opacity: 0.8;
        }
      }
    }
  }

  .more-info-button-wrapper {
    position: absolute;

    right: 0;
    top: 0;
    margin: 0.05em;
  }
}
