.cig-auth-wrapper {
  display: flex;
  justify-content: center;

  min-height: inherit;

  padding: 64px 0;

  box-sizing: border-box;

  @include maxScreen($lg) {
    padding: 48px 0;
  }

  @include maxScreen($sm) {
    padding: 0;
  }

  .content-wrapper {
    flex-basis: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 32px;

    box-sizing: border-box;

    @include maxScreen($md) {
      flex-basis: 100%;
    }

    @include maxScreen($xs) {
      padding: 24px 28px;
    }

    .progress-bar {
      width: 100%;
    }

    .title {
      margin-top: 32px;

      @include blatant(700);
      font-size: 40px;
      color: $white;
      text-align: center;

      @include maxScreen($sm) {
        margin-top: 32px;

        font-size: 24px;
      }
    }

    .sub-title {
      margin-top: 2px;

      @include inter(600);
      font-size: 15.75px;
      color: $gray-3;
      text-align: center;

      @include maxScreen($sm) {
        font-size: 14px;
      }
    }

    small {
      display: block;

      @include inter(600);
      font-size: 13.5px;
      color: $gray-2;
      text-align: center;
    }

    .alt-auth-bar {
      justify-content: center;

      margin-top: 24px;
    }

    .form-wrapper {
      flex-grow: 1;

      width: 70%;

      @include maxScreen($lg) {
        width: 80%;
      }

      @include maxScreen($md) {
        width: 100%;
      }

      .form {
        .inner {
          .break {
            margin-top: auto;
          }

          .or-divider {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
              position: absolute;

              padding: 0 16px;

              background-color: $black-1;

              @include inter(700);
              font-size: 13.5px;
              color: $gray-2;
            }
          }

          .button-row {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;

            margin-top: auto;

            @include maxScreen($md) {
              .button {
                flex-grow: 1;
              }
            }
          }
        }
      }
    }
  }

  .illustration-wrapper {
    flex-basis: 45%;

    @include maxScreen($md) {
      display: none;
    }
  }
}
