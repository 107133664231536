.card-brand-img {
  &.small {
    max-height: 40px;
  }
  &.medium {
    max-height: 60px;
  }
  &.large {
    max-height: 100px;
  }
}
