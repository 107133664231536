.time-picker-wrapper {
  position: relative;
}

.time-picker-selector {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 200px;
  min-height: 60px;

  margin-top: 8px;

  border: 1px solid transparent;
  background-color: $white;
  border-radius: 6px;

  transition: opacity 500ms ease;
  box-sizing: border-box;
  overflow: visible;
  z-index: $z-select;

  &.rounded {
    border-radius: 22px;
  }

  @each $name, $color in $colors {
    &.#{$name}-text {
      color: $color;
    }
  }

  &.contained,
  &.outlined {
    @each $name, $color in $colors {
      &.#{$name} {
        background-color: $color;
        box-shadow: 0 0 0 1px $color;
      }
    }
  }

  &.flat,
  &.transparent {
    @each $name, $color in $colors {
      &.#{$name} {
        background-color: rgba($color, 0.3);
        box-shadow: 0 0 0 1px rgba($color, 0.3);
      }
    }
  }

  .time-picker-selector-inner {
    padding: 8px;

    .form-row {
      align-items: center;
      gap: 6px;

      .time-icon {
        @include size(28px);

        margin-right: 4px;
      }

      .colon {
        @include inter(600);
        font-size: 18px;

        margin-top: -1px;
      }

      .form-entry {
        flex-grow: 0;

        .input,
        .select {
          text-align: center;
          width: 60px;
        }
      }
    }
  }
}

.time-picker.button {
  width: 100%;

  @include inter(400);

  &:disabled {
    @include disabled;
    @include inter(600);
  }

  &.active {
    .chevron {
      transform: rotate(90deg);
    }
  }

  .chevron {
    transition: transform 300ms ease;
  }
}
