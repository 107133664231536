.background-image {
  $src: var(--src);
  $color: var(--color);
  $opacity: var(--opacity);

  position: absolute;

  width: 100%;

  inset: 0;

  background-image: $src;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  z-index: -100;

  .background-image-inner {
    position: absolute;

    inset: 0;

    background-color: $color;
    opacity: $opacity;
  }
}
