.review-clip {
  width: 240px;
  min-width: 240px;
  height: 320px;
  min-height: 320px;

  .overlay {
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 4px;

      width: 100%;
      height: 100%;

      padding: 12px 16px;

      background: linear-gradient(to top, $black-1 0%, rgba($black-1, 0) 100%);

      box-sizing: border-box;

      .review-text {
        @include inter(500);
        font-size: 12px;
        color: $white;
        @include lineClamp(2);
      }
    }
  }
}
