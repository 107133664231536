.cig-event-spread {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.03em;

  width: 1em;
  min-width: 1em;
  height: 0.33em;

  &.is-loading {
    pointer-events: none;
  }

  &.is-mobile {
    flex-direction: column;
    gap: 0.08em;

    height: auto;

    .game-wrapper {
      flex-basis: 1em;

      width: 1em;
      min-width: 1em;
      height: 1em;
      min-height: 1em;

      .background-image {
        .background-image-inner {
          background: none;
        }
      }
    }

    .details-wrapper {
      flex-basis: auto;

      width: 1em;
      min-width: 1em;

      height: auto;

      .event-type {
        font-size: 0.079em;
      }

      .title {
        font-size: 0.104em;
      }

      .extra-details {
        margin-top: 0.3em;

        font-size: 0.05em;
      }

      .event-details {
        margin-top: 0.05em;

        .info-snippet {
          gap: 0.03em;

          min-width: 0;

          :first-child {
            flex-shrink: 0;
          }

          svg,
          .avatar-wrapper {
            @include size(0.06em);
          }

          a {
            display: inline-flex;
            min-width: 0;
          }

          .info-snippet-text {
            width: 100%;

            font-size: 0.05em;
            @include textOverflow;
          }
        }
      }

      .book-button {
        margin-top: 1em;
      }
    }
  }

  .game-wrapper {
    flex-basis: calc(60.74% - 0.015em);
    position: relative;
    display: flex;
    align-items: flex-end;

    min-width: calc(60.74% - 0.015em);
    height: 100%;

    background-color: $black-1;
    border-radius: 0.005em;

    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
    z-index: 100;

    &:hover {
      .background-image {
        transform: scale(1.1);
      }
    }

    .background-image {
      transition: transform 300ms ease;

      .background-image-inner {
        background: linear-gradient(to top, $black-2 20%, rgba($black-2, 0) 100%);
      }
    }

    .game-details {
      display: flex;
      align-items: center;
      gap: 0.015em;

      width: 100%;

      padding: 0.02em 0.015em;

      box-sizing: border-box;

      .avatar-wrapper {
        @include size(0.0617em);
      }

      .text-wrapper {
        min-width: 0;

        .game-name {
          @include blatant(700);
          font-size: 0.0325em;
          text-transform: uppercase;
          line-height: 1em;
        }

        .playing-with {
          @include inter(500);
          font-size: 0.0267em;
          color: $gray-1;
          line-height: 1em;
          @include textOverflow;

          display: block;

          .cig-name {
            @include inter(700);
            @include textGradient($gradient-faded-horizontal);
          }
        }
      }
    }
  }

  .details-wrapper {
    flex-basis: calc(39.26% - 0.015em);
    display: flex;
    flex-direction: column;

    min-width: calc(39.26% - 0.015em);
    height: 100%;

    overflow: hidden;
    box-sizing: border-box;

    .event-type {
      font-size: 0.0238em;
      @include blatant(700);
      @include textGradient($gradient-faded-horizontal);
      text-transform: uppercase;
      line-height: 0.8em;
    }

    .title {
      color: $white;
      font-size: 0.0312em;
      @include blatant(700, italic);
      @include textOverflow;
      line-height: 1.2em;
    }

    .extra-details {
      color: $gray-2;
      font-size: 0.0149em;
      @include inter(500);
      @include lineClamp(3);
      line-height: 1.1em;
    }

    .cig-details {
      display: flex;
      align-items: center;
      gap: 0.01em;

      min-width: 0;

      margin-top: 0.015em;

      .avatar-wrapper {
        @include size(0.0617em);
      }

      .text-wrapper {
        display: flex;
        flex-direction: column;

        min-width: 0;

        .cig-name {
          @include inter(700);
          font-size: 0.015em;
          @include textGradient($gradient-faded-horizontal);
          @include textOverflow;

          width: 100%;
        }

        .tag-line {
          @include inter(500);
          color: $gray-1;
          font-size: 0.015em;
          @include textOverflow;

          width: 100%;
        }
      }
    }

    .event-details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.01em;

      margin-top: 0.015em;

      .info-snippet {
        display: flex;
        gap: 0.01em;

        svg {
          @include size(0.018em);
          color: $gray-2;
        }

        .info-snippet-text {
          @include inter(500);
          color: $gray-2;
          font-size: 0.015em;
          line-height: 1.1em;

          .gradient {
            @include inter(700);
            @include textGradient($gradient-faded-horizontal);
          }
        }
      }
    }

    .book-button {
      width: fit-content;

      margin-top: auto;
    }
  }
}
