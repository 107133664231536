&.features {
  margin: 48px 0;

  // Scale up
  @include minScreen($sl) {
    margin: 96px 0;
  }

  @include minScreen($ul) {
    margin: 160px 0;
  }

  .features-wrapper {
    display: flex;
    justify-content: space-around;
    gap: 64px 12px;

    margin-top: 32px;

    flex-wrap: wrap;

    // Scale down
    @include maxScreen($lg) {
      gap: 48px 12px;
    }

    @include maxScreen($sm) {
      gap: 32px 12px;
    }

    // Scale up
    @include minScreen($sl) {
      gap: 192px 24px;

      margin-top: 48px;
    }

    @include minScreen($ul) {
      gap: 96px 48px;

      margin-top: 96px;
    }

    .feature {
      flex-basis: calc((33% - (12px * 2) / 3));

      box-sizing: border-box;

      // Scale down
      @include maxScreen($md) {
        flex-basis: calc((50% - (12px) / 2));
      }

      @include maxScreen($sm) {
        flex-basis: 80%;
      }

      @include maxScreen($xs) {
        flex-basis: 100%;
      }

      // Scale up
      @include minScreen($sl) {
        flex-basis: calc((33% - (24px * 2) / 3));
      }

      @include minScreen($ul) {
        flex-basis: calc((33% - (48px * 2) / 3));
      }

      .feature-img {
        width: 100%;
      }

      .title {
        margin-top: 24px;

        @include blatant(700);
        color: $white;
        font-size: 26px;

        // Scale down
        @include maxScreen($xl) {
          margin-top: 16px;

          font-size: 22px;
        }

        @include maxScreen($lg) {
          margin-top: 8px;

          font-size: 18px;
        }

        // Scale up
        @include minScreen($sl) {
          font-size: 34px;
        }

        @include minScreen($ul) {
          font-size: 58px;
        }

        span {
          @include textGradient($gradient-faded-horizontal);
        }
      }

      .text {
        margin-top: 4px;

        @include inter(500);
        color: $white;
        font-size: 16px;

        // Scale down
        @include maxScreen($xl) {
          font-size: 14px;
        }

        @include maxScreen($lg) {
          font-size: 12px;
        }

        // Scale up
        @include minScreen($sl) {
          font-size: 20px;
        }

        @include minScreen($ul) {
          font-size: 32px;
        }
      }
    }
  }
}
