.tickets-overlay-background {
  position: fixed;
  inset: 0;

  z-index: $z-overlay;

  &.entering {
    background-color: #18181b;
  }

  .tickets-overlay {
    position: absolute;
    inset: 0;

    .tickets-overlay-inner {
      position: absolute;
      inset: 0;

      .tickets-overlay-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        gap: 64px;

        height: 100%;

        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        overflow: auto;

        @include maxScreen($md) {
          flex-direction: column;
        }

        @include maxScreen($sm) {
          gap: 32px;
        }

        .tickets-overlay-tickets {
          position: relative;

          .ticket {
            box-shadow: 0 0 20px rgba($black, 0.5);

            z-index: 0;

            transition: transform 300ms ease;

            &:not(:first-of-type) {
              position: absolute;
              top: 0;
            }

            &:first-of-type {
              position: relative;
            }
          }
        }

        .tickets-overlay-content {
          display: flex;
          flex-direction: column;

          gap: 16px;

          .tickets-overlay-title {
            color: $white;
            font-size: 52px;
            letter-spacing: 0.15em;
            @include inter(600);

            @include maxScreen($sm) {
              font-size: 40px;
            }
          }

          .tickets-overlay-buttons {
            display: flex;

            gap: 16px;
          }
        }
      }
    }
  }
}
