.dev-panel-wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  width: 600px;

  top: 0;
  right: 0;

  z-index: $z-snackbar;

  @include maxScreen($sm) {
    width: 100%;
  }

  .toggle-button {
    transition: transform 300ms ease;
  }

  &.open {
    .toggle-button {
      transform: translateY(-50%);
    }
  }

  .dev-panel {
    width: 100%;

    margin-top: -1px;

    border-bottom-left-radius: 11.25px;
    background-color: $black-2;
    border-style: none none solid solid;
    border-width: 1px;
    border-color: $black-3;

    @include maxScreen($sm) {
      border-bottom-left-radius: 0;
      border-style: none none solid none;
    }

    .dev-panel-inner {
      display: flex;
      flex-direction: column;
      gap: 12px;

      width: 100%;
      max-height: 600px;

      padding: 16px 24px;

      box-sizing: border-box;
      overflow: auto;

      .dev-panel-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          @include blatant(400);
          font-size: 32px;
        }

        .buttons {
          display: flex;
          align-items: center;
          gap: 12px;
        }
      }

      .section {
        .section-title {
          margin-bottom: 12px;

          color: $gray-3;
          font-size: 16px;
          @include inter(600);
        }

        .row {
          display: flex;
          align-items: center;
          gap: 8px;

          margin-bottom: 6px;

          .select-wrapper {
            width: 100%;
          }

          p {
            flex-basis: 35%;
            min-width: 35%;
          }
        }

        p {
          color: $white;
          font-size: 12px;
          @include inter(600);
          white-space: nowrap;
        }

        &.cig-flow-state,
        &.auth-state {
          .button-select {
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}
