.review-card {
  position: relative;

  width: 240px;
  min-width: 240px;
  height: 320px;
  min-height: 320px;

  border-radius: 17px;

  overflow: hidden;

  @each $name, $color in $colors {
    &.#{$name}-bg {
      background-color: $color;
    }
  }

  .overlay {
    position: relative;

    width: 100%;
    height: 100%;

    z-index: 100;

    .background-image {
      background-size: 300%;
      .background-image-inner {
        background: linear-gradient(to top, $black-2 0%, rgba($black-2, 0.5) 100%);
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      width: 100%;
      height: 100%;

      padding: 16px;

      box-sizing: border-box;

      .review-text {
        @include inter(500);
        font-size: 14px;
        color: $white;
        @include lineClamp(8);
      }
    }
  }
}
