.checkbox-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;

  @mixin sizing($size, $checkmark-size, $border-radius, $font-size) {
    .checkbox-label {
      font-size: $font-size;
    }

    .box-wrapper {
      width: $size;
      height: $size;

      input.checkbox {
        width: $size;
        height: $size;

        border-radius: $border-radius;
      }

      .checkmark {
        width: $checkmark-size;
        height: $checkmark-size;
      }
    }
  }

  &.extra-small {
    @include sizing($size: 18px, $checkmark-size: 16px, $border-radius: 3px, $font-size: 10px);
  }

  &.small {
    @include sizing($size: 29px, $checkmark-size: 25px, $border-radius: 4px, $font-size: 12px);
  }

  &.medium {
    @include sizing($size: 41px, $checkmark-size: 35px, $border-radius: 6px, $font-size: 14px);
  }

  &.large {
    @include sizing($size: 56px, $checkmark-size: 48px, $border-radius: 8px, $font-size: 16px);
  }

  &.extra-large {
    @include sizing($size: 85px, $checkmark-size: 75px, $border-radius: 10px, $font-size: 18px);
  }

  &.disabled {
    pointer-events: none;
  }

  .box-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    input.checkbox {
      flex-shrink: 0;

      margin: 0;

      border: 0;

      box-sizing: border-box;
      background-color: $white;

      transition: background-color 300ms ease, border-radius 300ms ease, box-shadow 75ms ease-out;

      appearance: none;

      &:checked + .checkmark {
        opacity: 1;
      }

      &:disabled {
        @include disabled;
      }

      &:read-only {
        pointer-events: none;
      }

      &.rounded {
        border-radius: 50%;
      }

      &.contained {
        @each $name, $color in $colors {
          &.#{$name} {
            background-color: $color;
            box-shadow: 0 0 0 1px $color;

            &:not(.hover-disabled) {
              &:hover {
                background-color: offset($name, 5%);
                box-shadow: 0 0 0 1px offset($name, 5%);
              }

              &:active,
              &.active {
                background-color: offset($name, 5%);
                box-shadow: 0 0 0 2px offset($name, 5%);
              }
            }
          }
        }
      }

      &.outlined {
        background-color: transparent;

        @each $name, $color in $colors {
          &.#{$name} {
            box-shadow: 0 0 0 1px $color;

            &:not(.hover-disabled) {
              &:hover {
                background-color: rgba($color, 0.1);
                box-shadow: 0 0 0 1px offset($name, 5%);
              }

              &:active,
              &.active {
                background-color: rgba($color, 0.1);
                box-shadow: 0 0 0 2px offset($name, 10%);
              }
            }
          }
        }
      }

      &.flat {
        background-color: transparent;

        @each $name, $color in $colors {
          &.#{$name} {
            &:not(.hover-disabled) {
              &:hover {
                background-color: rgba($color, 0.1);
                box-shadow: 0 0 0 1px rgba($color, 0.1);
              }

              &:active,
              &.active {
                background-color: rgba($color, 0.1);
                box-shadow: 0 0 0 2px rgba($color, 0.1);
              }
            }
          }
        }
      }

      &.transparent {
        background-color: transparent;

        @each $name, $color in $colors {
          &.#{$name} {
            background-color: rgba($color, 0.3);
            box-shadow: 0 0 0 1px rgba($color, 0.3);

            &:not(.hover-disabled) {
              &:hover {
                background-color: rgba($color, 0.5);
                box-shadow: 0 0 0 1px rgba($color, 0.5);
              }

              &:active,
              &.active {
                background-color: rgba($color, 0.5);
                box-shadow: 0 0 0 2px rgba($color, 0.5);
              }
            }
          }
        }
      }
    }

    .checkmark {
      position: absolute;

      opacity: 0;

      pointer-events: none;
      transition: opacity 300ms ease;

      @each $name, $color in $colors {
        &.#{$name}-checkmark {
          color: $color;
          fill: $color;
        }
      }
    }
  }

  .checkbox-label {
    @include inter(500);

    @each $name, $color in $colors {
      &.#{$name}-text {
        color: $color;
      }
    }
  }
}

.form-entry.error {
  input {
    &.checkbox {
      box-shadow: 0 0 0 1px $red !important;

      &:focus-within,
      &.focus {
        box-shadow: 0 0 0 2px $red !important;
      }
    }
  }
}
