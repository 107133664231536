.data-check {
  flex-grow: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 100%;
  height: 100%;

  inset: 0;

  &.full-page {
    height: 100vh;
  }

  .error {
    @include inter(400);
    color: $red;
    text-align: center;
  }

  .no-data {
    @include inter(400);
    color: $gray-3;
    text-align: center;
  }
}

.gloss {
  @include gloss();
}
