.tooltip-base {
  box-sizing: border-box;

  z-index: $z-tooltip;

  pointer-events: none;

  &.absolute {
    position: absolute;

    &.left {
      right: 100%;

      &:not(.top):not(.bottom) {
        transform: translateY(-50%);
        top: 50%;
      }
    }

    &.right {
      left: 100%;

      &:not(.top):not(.bottom) {
        transform: translateY(-50%);
        top: 50%;
      }
    }

    &.top {
      bottom: 100%;

      &:not(.right):not(.left) {
        transform: translateX(-50%);
        left: 50%;
      }
    }

    &.bottom {
      top: 100%;

      &:not(.right):not(.left) {
        transform: translateX(-50%);
        left: 50%;
      }
    }
  }

  &.portal-absolute {
    position: absolute;
  }

  &.portal-fixed {
    position: fixed;
  }

  &.mouse {
    position: fixed;
  }

  &.hover {
    position: fixed;

    z-index: $z-hover-tooltip;

    .tooltip {
      padding: 6px 12px;

      border-radius: 4px;

      p {
        font-size: 12px;
      }
    }
  }

  .tooltip {
    padding: 12px 24px 12px 12px;

    border-radius: 8px;
    box-shadow: 0 0 20px rgba($black, 0.1);

    pointer-events: fill;
    box-sizing: border-box;
    transition: opacity 300ms ease;

    @include maxScreen($sm) {
      padding: 8px 20px 8px 8px;
    }

    @each $name, $color in $colors {
      &.#{$name} {
        background-color: $color;

        &.top {
          &::after {
            border-top-color: $color;
          }
        }
        &.bottom {
          &::after {
            border-bottom-color: $color;
          }
        }
        &.left {
          &::after {
            border-left-color: $color;
          }
        }
        &.right {
          &::after {
            border-right-color: $color;
          }
        }
      }
    }

    &.no-wrap {
      white-space: nowrap;
    }

    p {
      font-size: 14px;

      @include maxScreen($sm) {
        font-size: 12px;
      }
    }

    .close {
      position: absolute;

      right: 0;
      top: 0;

      margin: 2px;
      padding: 2px !important;

      border-radius: 50%;

      color: $black;

      cursor: pointer;

      .button-icon {
        font-size: 14px !important;
      }
    }

    &.top {
      transform: translate(-50%, calc(-100% - 10px));

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;

        inset: auto 0 0 0;
        margin: auto;
        transform: translateY(calc(100% - 1px));

        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $white;
      }
    }

    &.bottom {
      transform: translate(-50%, 10px);

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;

        inset: 0 0 auto 0;
        margin: auto;
        transform: translateY(calc(-100% + 1px));

        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $white;
      }
    }

    &.right {
      transform: translate(10px, -50%);

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;

        inset: 0 auto 0 0;
        margin: auto;
        transform: translateX(calc(-100% + 1px));

        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid $white;
      }
    }

    &.left {
      transform: translate(calc(-100% - 10px), -50%);

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;

        inset: 0 0 0 auto;
        margin: auto;
        transform: translateX(calc(100% - 1px));

        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid $white;
      }
    }

    &.center {
      transform: translate(-50%, -50%);
    }
  }
}
