.date-picker-wrapper {
  position: relative;
}

.date-picker-calendar {
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: 60px;

  margin-top: 6px;

  border: 1px solid transparent;
  background-color: $white;
  border-radius: 6px;

  transition: opacity 500ms ease;
  box-sizing: border-box;
  overflow: visible;
  z-index: $z-select;

  &.rounded {
    border-radius: 22px;
  }

  @each $name, $color in $colors {
    &.#{$name}-text {
      color: $color;
    }
  }

  &.contained,
  &.outlined {
    @each $name, $color in $colors {
      &.#{$name} {
        background-color: $color;
        box-shadow: 0 0 0 1px $color;
      }
    }
  }

  &.flat,
  &.transparent {
    @each $name, $color in $colors {
      &.#{$name} {
        background-color: rgba($color, 0.3);
        box-shadow: 0 0 0 1px rgba($color, 0.3);
      }
    }
  }
}

.date-picker.button {
  width: 100%;

  @include inter(400);

  &:disabled {
    @include disabled;
    @include inter(600);
  }

  &.active {
    .chevron {
      transform: rotate(90deg);
    }
  }

  .chevron {
    transition: transform 300ms ease;
  }
}
