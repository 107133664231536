.sticky-bar {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;

  right: 0;
  padding: 24px 40px;
  margin: 8px 12px;

  border: 1px solid $black-3;
  background: inherit;
  border-radius: 5px;

  box-sizing: border-box;
  overflow: hidden;

  width: calc(100% - 24px);

  z-index: $z-stickybar;

  @include maxScreen($md) {
    padding: 24px;
  }

  @include maxScreen($sm) {
    flex-direction: column;
    gap: 12px;

    width: 100%;

    margin: 0;

    border-style: solid none;
  }

  &:before {
    content: "";
    position: absolute;
    inset: 0;

    background: inherit;
    box-shadow: inset 0 0 0 200px rgba($black-2, 0.65);

    filter: blur(7px);
    backdrop-filter: blur(7px);

    z-index: -1;

    transition: box-shadow 300ms ease;
  }

  &.top {
    top: 106px;

    @include maxScreen($lg) {
      top: 90px;
    }

    @include maxScreen($sm) {
      top: 50px;
    }
  }

  &.bottom {
    bottom: 0;
  }

  &.minimized {
    transform: translateY(calc(100% - 15px)) !important;
    opacity: 1 !important;

    cursor: pointer;

    &:hover:before {
      box-shadow: inset 0 0 0 200px rgba($black-3, 0.65);
    }
  }

  .minimize-button {
    position: absolute;

    top: 0;
    right: 0;
    margin: 2px;
  }

  .close-button {
    @include maxScreen($sm) {
      position: absolute;

      top: 0;
      right: 0;
      margin: 24px;
    }
  }

  .sticky-bar-icon {
    @include size(50px);

    @include maxScreen($md) {
      @include size(40px);
    }

    @include maxScreen($sm) {
      align-self: flex-start;

      @include size(30px);
    }
  }

  .content {
    flex-grow: 1;

    @include inter(600);
    font-size: 26px;
    color: $white;
    line-height: 1.15em;

    @include maxScreen($md) {
      font-size: 18px;
    }

    @include maxScreen($sm) {
      padding-right: 40px;

      font-size: 16px;
      line-height: 1.3em;
    }

    .gradient {
      @include inter(700);
      @include textGradient($gradient-faded-horizontal);
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;

    @include maxScreen($md) {
      gap: 12px;
    }

    @include maxScreen($sm) {
      justify-content: flex-end;

      margin-top: 6px;

      width: 100%;
    }

    p {
      position: relative;

      @include inter(500);
      font-size: 22px;
      color: $white;
      white-space: nowrap;

      @include maxScreen($sm) {
        font-size: 16px;
      }
    }
  }
}
