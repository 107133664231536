@import "./experiencesRequestDesktop.scss";
@import "./experiencesRequestMobile.scss";

#experiences-request-page {
  min-height: inherit;

  .form-wrapper {
    width: 100%;

    box-sizing: border-box;

    .form {
      .inner {
        .form-section {
          display: flex;
          flex-direction: column;

          width: 100%;

          .form-row {
            justify-content: flex-start;
            align-items: flex-end;

            .platform-entry {
              .platform-select-wrapper {
                position: relative;

                min-height: 54px;

                .button-select {
                  flex-wrap: wrap;
                  gap: 16px;

                  margin-top: 12px;
                }
              }
            }

            .customer-requests-details-entry {
              height: 350px;
            }

            .time-entry {
              .form-label {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 16px;

                .controls {
                  display: flex;
                  align-self: flex-end;
                  gap: 12px;
                }
              }

              .form-entry {
                flex-grow: 0;
                height: unset;
              }

              .simple-calendar {
                padding: 4px 16px 16px 16px;
              }

              .duration-splash {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;

                margin-top: 16px;

                svg {
                  @include size(40px);
                }

                p {
                  @include inter(400);
                  font-size: 20px;
                }
              }

              .time-selector {
                position: relative;
                display: flex;
                gap: 12px;

                min-height: 100px;

                margin-top: 8px;

                @include maxScreen($md) {
                  flex-direction: column;
                }

                .divider-icon {
                  margin-top: 58px;

                  color: $white;
                  @include size(32px);
                }

                .form-entry {
                  flex-basis: 50%;

                  .form-group {
                    .select-wrapper,
                    .pill {
                      flex-basis: 50%;
                    }
                  }
                }
              }
            }

            .form-entry {
              justify-content: flex-end;

              .checkbox-outer {
                padding: 19px 16px;

                background-color: $black-2;
                border-radius: 8px;

                .checkbox-label {
                  flex-grow: 1;

                  display: flex;
                  justify-content: space-between;

                  @include inter(600);
                  font-size: 14px;
                }
              }

              .tickets-breakdown {
                display: flex;
                flex-direction: column;
                gap: 12px;

                padding: 24px 12px;

                background-color: $black-2;
                border-radius: 8px;

                .head {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 12px;

                  p {
                    @include inter(600);
                    font-size: 16px;
                    color: $gray-1;
                  }
                }

                .body {
                  position: relative;
                  display: flex;
                  flex-direction: column;

                  min-height: 170.5px;

                  background-color: $black-3;
                  border-radius: 5px;

                  overflow: hidden;

                  .row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 12px;

                    padding: 19px 12px;

                    &:not(:last-child) {
                      border-style: none none solid none;
                      border-width: 1px;
                      border-color: $gray-1;
                    }

                    p {
                      display: flex;
                      align-items: center;
                      gap: 12px;

                      @include inter(700);
                      font-size: 14px;
                      color: $white;

                      .discount {
                        color: $green;
                      }
                    }
                  }
                }

                .configuration {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 12px;

                  margin-top: 12px;

                  p {
                    @include inter(600);
                    font-size: 16px;
                    color: $white;
                  }

                  .tickets-entry {
                    max-width: 250px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .form-label {
    .label {
      @include blatant(700);
      font-size: 30px;
      white-space: normal;
    }
  }

  .experiences-request-summary {
    display: flex;
    flex-direction: column;

    .summary-title {
      font-size: 36px;
      @include blatant(700);
      @include textGradient($gradient-faded-horizontal);
    }

    .containers {
      display: flex;
      flex-direction: column;
      gap: 12px;

      margin-top: 16px;

      .container {
        position: relative;
        background-color: $black-3;
        border-radius: 5px;

        min-height: 58px;

        overflow: hidden;
        box-sizing: border-box;

        &.cig-summary {
          display: flex;
          align-items: center;
          gap: 8px;

          padding: 12px;

          min-height: 72px;

          .cig-details {
            display: flex;
            flex-direction: column;

            .display-name {
              @include blatant(400);
              font-size: 20px;
            }

            .tag-line {
              @include inter(600);
              font-size: 14px;
              color: $gray-3;
            }
          }
        }

        &.details {
          display: flex;
          flex-direction: column;

          padding: 4px 0;

          .details-row {
            position: relative;
            display: flex;
            justify-content: space-between;
            gap: 12px;

            min-height: 50px;

            padding: 16px;

            box-sizing: border-box;

            .label {
              @include inter(600);
              font-size: 14px;
              color: $gray-3;
            }

            .value {
              @include inter(700);
              font-size: 14px;
              color: $white;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
