$discord-blue: rgb(88, 101, 242);
$twitter-blue: rgb(73, 190, 253);
$youtube-red: rgb(255, 0, 32);
$tiktok-black: rgb(28, 28, 31);
$facebook-blue: rgb(24, 118, 242);
$instagram-orange: rgb(253, 89, 73);
$google-red: rgb(219, 68, 55);
$twitch-purple: rgb(130, 66, 253);

// New Colors

// Generic
$white: rgb(255, 255, 255); // #FFF
$off-white: rgb(250, 250, 250); // #FAFAFA
$stained-white: rgb(234, 234, 234); // #EAEAEA
$old-white: rgb(216, 216, 216); // #D8D8D8
$black: rgb(0, 0, 0); // #000

// Primary
$purple: rgb(140, 81, 255); // #8C51FF
$red: rgb(255, 82, 82); // #FF5252
$green: rgb(30, 221, 118); // #1EDD76
$blue: rgb(73, 190, 253); // #49BEFD
$dark-blue: rgb(87, 129, 254); // #5781FE
$orange: rgb(254, 146, 51); // #FE9233
$yellow: rgb(254, 183, 47); // #FEB72F
$gold: rgb(230, 205, 141); // #E6CD8D

// Black
$black-1: rgb(13, 11, 19); // #0D0B13
$black-2: rgb(21, 18, 28); // #15121C
$black-3: rgb(33, 28, 43); // #211C2B
$black-4: rgb(42, 36, 56); //#2A2438

// Gray
$gray-1: rgb(71, 66, 102); // #474266
$gray-2: rgb(127, 124, 169); // #7F7CA9
$gray-3: rgb(165, 162, 195); // #A5A2C3

// Purple
$purple-1: rgb(68, 40, 148); // #442894
$purple-2: rgb(79, 46, 173); // #4F2EAD
$purple-3: rgb(139, 93, 255); // #8B5DFF
$purple-4: rgb(174, 137, 255); // #AE89FF
$purple-5: rgb(197, 171, 255); // #C5ABFF
$purple-6: rgb(241, 233, 255); // #F1E9FF

// Peach
$peach-1: rgb(234, 110, 89); // #EA6E59
$peach-2: rgb(255, 156, 139); // #FF9C8B
$peach-3: rgb(255, 185, 185); // #FFB9B9

// Gradients
$gradient-blend-horizontal: linear-gradient(90deg, #49befd 5.38%, #6188fe 31.12%, #8140ff 76.17%);
$gradient-blend-vertical: linear-gradient(180deg, #49befd 76.34%, #6188fe 81.55%, #8140ff 90.66%);
$gradient-vibrant-blend-horizontal: linear-gradient(
  90deg,
  #32e6ff 5.38%,
  #5781fe 55.22%,
  #9f6ffd 105.38%
);
$gradient-faded-horizontal: linear-gradient(90deg, #99f3ff 5.38%, #9ab3fe 55.22%, #bc9afe 105.38%);
$gradient-light-vertical: linear-gradient(180deg, #e0d0ff 0%, #8242fd 100%);
$gradient-orange-horizontal: linear-gradient(90deg, #e39640 0%, #ea6a5b 100%);
$gradient-dark-orange-horizontal: linear-gradient(90deg, #ff8301 0%, #df2201 100%);
$gradient-gold-vertical: linear-gradient(180deg, #ffe7a8 0%, #d3aa3f 50%, #ffe7a8 100%);

// Double Gradients
$double-gradient-blend-horizontal: linear-gradient(
  90deg,
  #49befd 5%,
  #6188fe 20%,
  #8140ff 45%,
  #8140ff 55%,
  #6188fe 80%,
  #49befd 95%
);
$double-gradient-blend-vertical: linear-gradient(
  180deg,
  #49befd 5%,
  #6188fe 20%,
  #8140ff 45%,
  #8140ff 55%,
  #6188fe 80%,
  #49befd 95%
);
$double-gradient-vibrant-blend-horizontal: linear-gradient(
  90deg,
  #32e6ff 5%,
  #5781fe 20%,
  #9f6ffd 45%,
  #9f6ffd 55%,
  #5781fe 80%,
  #32e6ff 95%
);
$double-gradient-faded-horizontal: linear-gradient(
  90deg,
  #99f3ff 5%,
  #9ab3fe 20%,
  #bc9afe 45%,
  #bc9afe 55%,
  #9ab3fe 80%,
  #99f3ff 95%
);
$double-gradient-light-vertical: linear-gradient(
  180deg,
  #8242fd 5%,
  #e0d0ff 45%,
  #e0d0ff 55%,
  #8242fd 95%
);

$colors: (
  // Generic
  "white": $white,
  "off-white": $off-white,
  "stained-white": $stained-white,
  "old-white": $old-white,
  "black": $black,

  // Primary
  "purple": $purple,
  "red": $red,
  "green": $green,
  "blue": $blue,
  "orange": $orange,
  "yellow": $yellow,
  "gold": $gold,

  // Black
  "black-1": $black-1,
  "black-2": $black-2,
  "black-3": $black-3,
  "black-4": $black-4,

  // Gray
  "gray-1": $gray-1,
  "gray-2": $gray-2,
  "gray-3": $gray-3,

  // Peach
  "peach-1": $peach-1,
  "peach-2": $peach-2,
  "peach-3": $peach-3,

  // Purple
  "purple-1": $purple-1,
  "purple-2": $purple-2,
  "purple-3": $purple-3,
  "purple-4": $purple-4,
  "purple-5": $purple-5,
  "purple-6": $purple-6,

  // Brands
  "discord-blue": $discord-blue,
  "twitter-blue": $twitter-blue,
  "youtube-red": $youtube-red,
  "tiktok-black": $tiktok-black,
  "facebook-blue": $facebook-blue,
  "instagram-orange": $instagram-orange,
  "google-red": $google-red,
  "twitch-purple": $twitch-purple
);

$gradients: (
  "gradient-blend-horizontal": $gradient-blend-horizontal,
  "gradient-blend-vertical": $gradient-blend-vertical,
  "gradient-vibrant-blend-horizontal": $gradient-vibrant-blend-horizontal,
  "gradient-faded-horizontal": $gradient-faded-horizontal,
  "gradient-light-vertical": $gradient-light-vertical,
  "gradient-orange-horizontal": $gradient-orange-horizontal,
  "gradient-dark-orange-horizontal": $gradient-dark-orange-horizontal,
  "gradient-gold-vertical": $gradient-gold-vertical,
);

$double-gradients: (
  "gradient-blend-horizontal": $double-gradient-blend-horizontal,
  "gradient-blend-vertical": $double-gradient-blend-vertical,
  "gradient-vibrant-blend-horizontal": $double-gradient-vibrant-blend-horizontal,
  "gradient-faded-horizontal": $double-gradient-faded-horizontal,
  "gradient-light-vertical": $double-gradient-light-vertical,
);

$lightColors: ("white", "purple-6", "gray-3");
$darkColors: ("black", "black-1", "black-2", "black-3", "black-4", "tiktok-black");

$xs: 350px;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1780px;
$sl: 2560px; // 75% zoom
$ul: 3840px; // 50% zoom

$z-overlay: 20000;
$z-hover-tooltip: 19000;
$z-snackbar: 18000;
$z-stickybar: 17500;
$z-root: 17000;
$z-tooltip: 15000;
$z-select: 14000;
$z-menu-cover: 13500;
$z-modal: 13000;
$z-ribbon: 12000;
$z-drawer: 10000;
$z-page-cover: 9000;
$z-header: 8000;
$z-floating: 7000;

$z-page: 5000;

$z-back: -10000;

$z-indexes: (
  "overlay": $z-overlay,
  "snackbar": $z-snackbar,
  "root": $z-root,
  "tooltip": $z-tooltip,
  "menu-cover": $z-menu-cover,
  "select": $z-select,
  "modal": $z-modal,
  "ribbon": $z-ribbon,
  "drawer": $z-drawer,
  "page-cover": $z-page-cover,
  "header": $z-header,
  "page": $z-page,
  "back": $z-back,
);
