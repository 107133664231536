.search-wrapper {
  position: relative;

  .input-wrapper {
    width: 100%;
  }

  &.open {
    .input-wrapper {
      .input,
      .input-overflow-wrapper {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }
}

.form-entry.error {
  .search {
    box-shadow: 0 0 0 1px $red;

    &:hover:not(.open) {
      box-shadow: 0 0 0 1px $red;
    }
  }
}
