.auth-modal {
  .modal {
    width: 400px;

    @include minScreen($sm) {
      max-height: 750px;
    }

    .modal-content {
      overflow: hidden !important;

      .auth-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;

        min-height: 0;

        .header-wrapper {
          padding: 40px 16px;

          @include maxScreen($sm) {
            padding: 32px 16px;
          }

          &.no-alt-auth {
            .title {
              margin-top: 0;

              line-height: 1em;
            }
          }

          .title {
            margin-top: 16px;

            @include blatant(700);
            font-size: 40px;
            color: $white;
            text-align: center;

            @include maxScreen($xs) {
              margin-top: 8px;

              font-size: 32px;
            }
          }

          .alt-auth-bar {
            justify-content: center;

            margin-top: 24px;
          }
        }

        .or-divider {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 100%;

          p {
            position: absolute;

            padding: 0 16px;

            background-color: $black-2;

            @include inter(700);
            font-size: 13.5px;
            color: $gray-2;
          }
        }

        .content-wrapper {
          flex-grow: 1;
          display: flex;
          flex-direction: column;

          min-height: 0;
          width: 100%;

          padding: 24px 32px;

          box-sizing: border-box;
          overflow: hidden auto;

          @include maxScreen($sm) {
            padding: 20px 24px;
          }

          @include maxScreen($xs) {
            padding: 16px;
          }

          .form-wrapper {
            .form {
              .inner {
                gap: 24px;

                padding: 2px;

                .form-bar,
                .form-box {
                  padding: 18px 24px;

                  background-color: $black-3;

                  @include maxScreen($xs) {
                    padding: 18px 20px;
                  }

                  .text,
                  .form-bar-title {
                    font-size: 14px;

                    @include maxScreen($xs) {
                      font-size: 12px;
                    }

                    &.large {
                      font-size: 15px;

                      @include maxScreen($xs) {
                        font-size: 13px;
                      }
                    }
                  }
                }

                .auth-button-row {
                  display: flex;
                  align-items: center;
                  gap: 12px;

                  .button {
                    flex-grow: 1;
                  }
                }

                .bottom-text {
                  @include inter(400);
                  font-size: 14px;
                  color: $white;
                  text-align: center;

                  span {
                    color: $purple-5;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
