.shake-transition {
  animation: shake 500ms cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-iteration-count: infinite;
  animation-play-state: paused;

  &.shake-enter-active,
  &.shake-appear-active,
  &.shake-enter-done,
  &.shake-appear-done {
    animation-play-state: running;
  }

  &.shake-exit-active,
  &.shake-exit-done {
    animation-play-state: paused;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
