.slider {
  width: 100%;

  -webkit-appearance: none;

  @mixin thumbStyles {
    border-radius: 50%;
    border: 0;

    cursor: ew-resize;
    -webkit-appearance: none;

    transition: background-color 300ms ease, border-radius 300ms ease, box-shadow 75ms ease-out;
  }

  &::-webkit-slider-thumb {
    @include thumbStyles;
  }

  &::-moz-range-thumb {
    @include thumbStyles;
  }

  @mixin sizing($thumb-size, $border-radius) {
    height: calc($thumb-size / 4);
    border-radius: $border-radius;

    &::-webkit-slider-thumb {
      @include size($thumb-size);
    }

    &::-moz-range-thumb {
      @include size($thumb-size);
    }
  }

  @mixin thumb($color) {
    background-color: $color;
    box-shadow: 0 0 0 1px $color;
  }

  @mixin thumbHover($name) {
    &:hover {
      background-color: offset($name, 5%);
      box-shadow: 0 0 0 1px offset($name, 5%);
    }

    &:active {
      background-color: offset($name, 5%);
      box-shadow: 0 0 0 2px offset($name, 5%);
    }
  }

  @each $name, $color in $colors {
    &.#{$name}-thumb {
      &::-webkit-slider-thumb {
        @include thumb($color);
      }
      &::-moz-range-thumb {
        @include thumb($color);
      }

      &:not(.hover-disabled) {
        &::-webkit-slider-thumb {
          @include thumbHover($name);
        }
        &::-moz-range-thumb {
          @include thumbHover($name);
        }
      }
    }
  }

  &.extra-small {
    @include sizing($thumb-size: 18px, $border-radius: 3px);
  }

  &.small {
    @include sizing($thumb-size: 29px, $border-radius: 4px);
  }

  &.medium {
    @include sizing($thumb-size: 41px, $border-radius: 6px);
  }

  &.large {
    @include sizing($thumb-size: 56px, $border-radius: 8px);
  }

  &.extra-large {
    @include sizing($thumb-size: 85px, $border-radius: 10px);
  }

  &:disabled {
    @include disabled;
  }
}
