&.upcoming-events {
  padding-top: 64px;

  // Scale up
  @include minScreen($sl) {
    padding-top: 128px;
  }

  @include minScreen($ul) {
    padding-top: 192px;
  }

  .events {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;

    margin-top: 32px;
  }
}
