.clip {
  position: relative;

  border-radius: 17px;

  overflow: hidden;

  @each $name, $color in $colors {
    &.#{$name}-bg {
      background-color: $color;
    }
  }

  video {
    position: absolute;

    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }

  .overlay {
    position: relative;

    width: 100%;
    height: 100%;

    .play-button,
    .pause-button {
      position: absolute;
      inset: 0;
      margin: auto;
    }

    .audio-button {
      position: absolute;
      top: 0;
      right: 0;
      margin: 12px;
    }
  }
}
