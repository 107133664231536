.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  min-width: 0;

  &.textarea {
    height: 100%;
    min-height: 100px;
  }

  &.disabled {
    @include disabled;
  }

  @each $name, $color in $colors {
    &.#{$name}-text {
      .input {
        color: $color;

        &::placeholder {
          color: rgba($color, 0.4);
        }
      }

      .input-icon {
        color: $color;

        &.mask {
          mask-size: cover;
          mask-repeat: no-repeat;
          background-color: $color;
        }
      }
    }
  }

  p.input-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    line-height: 1em;

    .small {
      font-size: 0.6em;
      @include inter(500);
      line-height: 1em;
    }
  }

  .progress-wrapper {
    position: relative;

    .progress {
      position: absolute;

      left: 0;
    }
  }

  @mixin icons($padding, $margin, $icon-size) {
    &.has-start-icon {
      .input {
        padding-left: $padding;
      }

      .input-icon:first-child {
        left: $margin;

        &.icon {
          left: calc($margin / 4);
        }
      }
    }

    &.has-end-icon {
      .input {
        padding-right: $padding;
      }

      .input-icon:last-child {
        right: $margin;

        &.icon {
          right: calc($margin / 2);
        }
      }
    }
  }

  @mixin sizing(
    $vertical-padding,
    $horizontal-padding,
    $border-radius,
    $font-size,
    $icon-size,
    $icon-spacing,
    $scale,
    $translate
  ) {
    font-size: $font-size;

    &.oversized {
      font-size: calc($font-size * 2);

      .input-icon:not(.icon) {
        @include size(calc($icon-size * 2));
      }

      .input-icon,
      .input {
        @include inter(800);

        &:disabled {
          @include inter(700);
        }
      }

      @include icons(
        calc(($horizontal-padding + $icon-size + $icon-spacing) * 1.5),
        $horizontal-padding,
        calc($icon-size * 2)
      );
    }

    &.rounded {
      .input-overflow-wrapper {
        border-radius: calc($border-radius * 4.3);
      }
    }

    .input-icon:not(.icon) {
      @include size($icon-size);
    }

    .input-overflow-wrapper {
      border-radius: $border-radius;

      .input {
        padding: $vertical-padding $horizontal-padding;
      }
    }

    .progress-wrapper {
      @include size($icon-size);

      .progress {
        @include size($icon-size);

        transform: scale($scale) translate($translate, $translate);
      }
    }

    @include icons(
      calc($horizontal-padding + $icon-size + $icon-spacing),
      $horizontal-padding,
      $icon-size
    );
  }

  &.extra-small {
    @include sizing(
      $vertical-padding: 3px,
      $horizontal-padding: 5.5px,
      $border-radius: 3px,
      $font-size: 10px,
      $icon-size: 12px,
      $icon-spacing: 4px,
      $scale: 0.175,
      $translate: -32px
    );
  }

  &.small {
    @include sizing(
      $vertical-padding: 7px,
      $horizontal-padding: 9px,
      $border-radius: 4px,
      $font-size: 12px,
      $icon-size: 15px,
      $icon-spacing: 6px,
      $scale: 0.225,
      $translate: -30px
    );
  }

  &.medium {
    @include sizing(
      $vertical-padding: 12px,
      $horizontal-padding: 18px,
      $border-radius: 6px,
      $font-size: 14px,
      $icon-size: 17px,
      $icon-spacing: 8px,
      $scale: 0.25,
      $translate: -30px
    );
  }

  &.large {
    @include sizing(
      $vertical-padding: 18px,
      $horizontal-padding: 27px,
      $border-radius: 8px,
      $font-size: 16px,
      $icon-size: 20px,
      $icon-spacing: 10px,
      $scale: 0.3,
      $translate: -28px
    );
  }

  &.extra-large {
    @include sizing(
      $vertical-padding: 28px,
      $horizontal-padding: 42px,
      $border-radius: 10px,
      $font-size: 24px,
      $icon-size: 29px,
      $icon-spacing: 14px,
      $scale: 0.4,
      $translate: -25px
    );
  }

  &.contained {
    @each $name, $color in $colors {
      &.#{$name} {
        .input-overflow-wrapper {
          box-shadow: 0 0 0 1px $color;

          .input {
            background-color: $color;
          }
        }

        &:not(.hover-disabled) {
          .input-overflow-wrapper {
            &:hover {
              box-shadow: 0 0 0 1px offset($name, 5%);

              .input {
                background-color: offset($name, 5%);
              }
            }

            &:focus-within,
            &.focus {
              box-shadow: 0 0 0 2px offset($name, 5%);

              .input {
                background-color: offset($name, 5%);
              }
            }
          }
        }
      }
    }
  }

  &.outlined {
    .input {
      background-color: transparent;
    }

    @each $name, $color in $colors {
      &.#{$name} {
        .input-overflow-wrapper {
          box-shadow: 0 0 0 1px $color;
        }

        &:not(.hover-disabled) {
          .input-overflow-wrapper {
            &:hover {
              box-shadow: 0 0 0 1px $color;

              .input {
                background-color: rgba($color, 0.1);
              }
            }

            &:focus-within,
            &.focus {
              box-shadow: 0 0 0 2px offset($name, 10%);

              .input {
                background-color: rgba($color, 0.1);
              }
            }
          }
        }
      }
    }
  }

  &.flat {
    .input {
      background-color: transparent;
    }

    @each $name, $color in $colors {
      &.#{$name} {
        &:not(.hover-disabled) {
          .input-overflow-wrapper {
            &:hover {
              box-shadow: 0 0 0 1px rgba($color, 0.1);

              .input {
                background-color: rgba($color, 0.1);
              }
            }

            &:focus-within,
            &.focus {
              box-shadow: 0 0 0 2px rgba($color, 0.1);

              .input {
                background-color: rgba($color, 0.1);
              }
            }
          }
        }
      }
    }
  }

  &.transparent {
    .input {
      background-color: transparent;
    }

    @each $name, $color in $colors {
      &.#{$name} {
        .input-overflow-wrapper {
          box-shadow: 0 0 0 1px rgba($color, 0.3);

          .input {
            background-color: rgba($color, 0.3);
          }
        }

        &:not(.hover-disabled) {
          .input-overflow-wrapper {
            &:hover {
              box-shadow: 0 0 0 1px rgba($color, 0.5);

              .input {
                background-color: rgba($color, 0.5);
              }
            }

            &:focus-within,
            &.focus {
              box-shadow: 0 0 0 2px rgba($color, 0.5);

              .input {
                background-color: rgba($color, 0.5);
              }
            }
          }
        }
      }
    }
  }

  .input-icon {
    position: absolute;
    z-index: 100;

    &:not(.icon) {
      pointer-events: none;
    }

    &.avatar-wrapper {
      transform: scale(1.25);
    }
  }

  .input-overflow-wrapper {
    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;
    min-height: inherit;

    box-shadow: 0 0 0 1px transparent;

    font-size: inherit;

    overflow: hidden;
    box-sizing: border-box;

    transition: border-radius 300ms ease, box-shadow 75ms ease-out;

    .input {
      width: 100%;
      height: 100%;
      min-height: inherit;
      min-width: 0;

      outline: none;
      border: none;
      background-color: transparent;

      @include inter(500);
      font-size: inherit;

      -webkit-appearance: none;
      box-sizing: border-box;
      resize: none;

      transition: background-color 300ms ease;

      &[type="number"] {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      &:focus-within,
      &.focus {
        background-color: rgba($white, 0.25);
      }

      &:disabled {
        @include inter(600);
      }
    }
  }
}

.form-entry.error {
  .input-wrapper:not(.no-error) {
    .input-overflow-wrapper {
      box-shadow: 0 0 0 1px $red !important;

      &:focus-within,
      &.focus {
        box-shadow: 0 0 0 2px $red !important;
      }
    }
  }
}
