.featured-session {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  min-height: 353px;
  height: 353px;

  padding: 64px 32px 32px 32px;

  border-radius: 5px;

  overflow: hidden;
  box-sizing: border-box;

  @include maxScreen($sm) {
    flex-direction: column;
    align-items: flex-start;

    min-height: 305px;
    height: 305px;

    padding: 16px;
  }

  .details-wrapper {
    display: flex;
    flex-direction: column;

    min-width: 0;

    @include maxScreen($sm) {
      width: 100%;
    }

    a {
      width: fit-content;
    }

    .prompt-title {
      margin-top: 12px;

      @include blatant(600);
      font-size: 46px;
      @include textOverflow;

      @include maxScreen($sm) {
        font-size: 32px;
      }
    }

    .title {
      margin-top: 4px;

      @include inter(500);
      font-size: 26px;
      @include textOverflow;

      @include maxScreen($sm) {
        font-size: 18px;
      }
    }

    .game-name {
      @include inter(800);
      font-size: 26px;
      @include textGradient($gradient-faded-horizontal);
      @include lineClamp(1);

      @include maxScreen($sm) {
        font-size: 18px;
      }
    }

    .bottom-text {
      margin-top: 16px;

      @include inter(500);
      font-size: 20px;
      @include textOverflow;

      @include maxScreen($sm) {
        margin-top: 8px;

        font-size: 18px;
      }

      .bullet {
        color: rgba($white, 0.5);
      }
    }
  }
}
