#update-experience-package-page.mobile {
  .form-wrapper {
    padding: 0 8px;

    .form {
      overflow: visible;
    }
  }

  .tabs {
    margin: 16px 16px 0 16px;
  }

  .form-label {
    .label {
      font-size: 22px;
    }

    .sub-label {
      margin-bottom: 0;

      font-size: 14px;
    }
  }
}
