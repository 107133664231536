.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  .icons {
    display: flex;
  }
}
