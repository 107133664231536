.drawer {
  display: flex;
  flex-direction: column;

  top: 0;

  width: 0;
  height: 100%;

  background-color: $black-2;

  z-index: $z-drawer;
  transition: all 300ms ease;
  overflow: visible;

  &.fixed {
    position: fixed;
  }

  &.absolute {
    position: absolute;
  }

  &.inactive {
    width: 0 !important;

    .close-icon {
      display: none;
    }
  }

  &.left {
    left: 0;

    .expand-icon {
      right: 0;
      transform: translateX(50%);

      &.collapsed {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .close-icon {
      left: 0;
    }
  }

  &.right {
    right: 0;

    .expand-icon {
      left: 0;
      transform: translateX(-50%);

      svg {
        transform: rotate(180deg);
      }

      &.collapsed {
        svg {
          transform: rotate(0);
        }
      }
    }

    .close-icon {
      right: 0;
    }
  }

  .expand-icon {
    position: absolute;
    display: flex;

    top: 0;
    bottom: 0;
    margin: auto 0;

    svg {
      transition: transform 300ms ease;
    }
  }

  .close-icon {
    position: absolute;
    display: flex;

    top: 0;
    margin: 24px;
  }
}
