&.last-questions {
  .inner {
    background-color: $black-1;

    &::after {
      display: none;
    }

    padding: 32px 0 96px 0;

    .flow-sub-title {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      margin: 64px 0;

      @include maxScreen($md) {
        flex-direction: column-reverse;

        margin: 32px 0;
      }
    }

    .flow-text {
      @include inter(500);
      font-size: 24px;
      color: $gray-3;

      @include maxScreen($lg) {
        font-size: 22px;
      }

      @include maxScreen($md) {
        font-size: 18px;
      }

      @include maxScreen($sm) {
        font-size: 14px;
      }

      @include maxScreen($xs) {
        font-size: 12px;
      }
    }

    .accordions {
      display: flex;
      flex-direction: column;
      gap: 16px;

      width: 65%;

      margin-top: 32px;

      @include maxScreen($lg) {
        width: 75%;
      }

      @include maxScreen($md) {
        width: 85%;
      }

      @include maxScreen($sm) {
        width: 90%;
      }
    }

    .cta {
      display: flex;
      flex-direction: column;
      align-items: center;

      background-color: $white;

      width: 65%;

      padding: 48px 64px;

      border-radius: 15px;

      box-sizing: border-box;

      @include maxScreen($lg) {
        width: 75%;
      }

      @include maxScreen($md) {
        width: 85%;

        padding: 32px 48px;
      }

      @include maxScreen($sm) {
        width: 90%;

        padding: 32px 24px;
      }

      @include maxScreen($xs) {
        padding: 32px 16px;
      }

      .title {
        @include blatant(700);
        font-size: 54px;
        color: $gray-1;
        text-align: center;

        @include maxScreen($lg) {
          font-size: 46px;
        }

        @include maxScreen($md) {
          font-size: 40px;
        }

        @include maxScreen($sm) {
          font-size: 36px;
        }

        @include maxScreen($xs) {
          font-size: 20px;
        }
      }

      .button {
        margin-top: 24px;
      }

      .affiliates-bar {
        position: relative;
        display: flex;
        align-items: center;
        gap: 28px;

        width: 536px;

        margin-top: 32px;
        padding: 18px 40px;

        background-color: $purple-6;
        border-radius: 200px;

        box-sizing: border-box;

        @include maxScreen($md) {
          flex-direction: column;
          gap: 12px;

          width: 90%;

          padding: 16px 32px 24px 32px;
        }

        .text {
          flex-grow: 1;

          @include inter(500);
          font-size: 12px;
          color: $gray-1;

          z-index: 100;

          @include maxScreen($md) {
            text-align: center;
          }
        }

        .avatars {
          flex-grow: 1;
          flex-shrink: 0;

          position: relative;
          display: flex;

          z-index: 100;

          @include maxScreen($md) {
            position: absolute;

            bottom: 0;

            transform: translateY(60%);
          }

          .avatar-wrapper {
            margin-left: -16px;

            @include maxScreen($md) {
              margin-left: -12px;
            }

            .plus-text {
              @include size(unset);
              @include inter(900);
              font-size: 7px;
              color: $white;
            }
          }
        }
      }
    }
  }
}
