.room-loading-modal {
  justify-content: flex-start;

  pointer-events: none;

  .page-cover {
    background-color: rgba($black, 0.6);
  }

  .modal {
    margin: 0 auto;

    pointer-events: all;

    @include maxScreen($sm) {
      width: 100vw;
      max-width: 100vw;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      background-color: $black-2;
      border-radius: 0 0 10px 10px;
      box-shadow: 0 0 10px $black-3;

      overflow: hidden;

      @include maxScreen($sm) {
        border-radius: 0;
      }

      .main-wrapper {
        position: relative;

        margin: 12px 24px;

        @include maxScreen($sm) {
          margin: 8px 16px;
        }

        .statuses {
          display: flex;
          flex-direction: column;
          gap: 12px;

          .status {
            display: flex;
            justify-content: space-between;
            gap: 12px;

            .left-wrapper {
              display: flex;
              align-items: center;
              gap: 12px;

              .status-icon {
                @include size(24px);

                color: $gray-3;
              }

              .status-text {
                @include inter(500);
                font-size: 16px;
                color: $gray-2;
              }
            }

            .state {
              .progress {
                @include size(24px);

                transform: scale(0.3) translate(-110%, -85%);
              }

              &.error {
                color: $red;
              }

              .state-icon {
                @include size(24px);
              }
            }
          }
        }

        .modal-footer {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 12px;

          margin-top: 16px;

          .form-error {
            margin: 0;
          }

          .button-row {
            display: flex;
            gap: 8px;

            .button {
              width: fit-content;
            }
          }
        }
      }
    }
  }
}
