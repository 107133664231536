.ticket-book {
  .top {
    .top-content {
      display: block;
      justify-content: flex-start;

      .more-info-button {
        position: absolute;

        right: 0;
        top: 0;
        margin: 20px;
      }

      .content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 100%;

        .pill {
          width: fit-content;
        }

        .cig-name-wrapper {
          .cig-name {
            margin-right: 0;
          }
        }
      }

      .more-info-wrapper {
        position: absolute;

        inset: 0;

        background-color: $black-3;

        .more-info-inner {
          display: flex;
          flex-direction: column;
          gap: 12px;

          height: 100%;
          width: 100%;

          .title {
            @include inter(500);
            color: $white;
          }

          .more-info-inner-wrapper {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 16px;

            min-height: 0;

            overflow: hidden;

            .extra-info-wrapper {
              padding: 10px;

              background-color: $black-1;
              border-radius: 9px;

              .extra-info {
                @include inter(400);
                color: $white;
                text-align: center;

                white-space: pre-line;

                overflow: auto;

                span {
                  display: inline-block;
                  vertical-align: middle;
                  line-height: normal;
                }
              }
            }

            .platforms-wrapper {
              display: flex;
              flex-direction: column;

              min-height: 0;

              overflow: hidden;

              .platforms-title {
                @include inter(500);
                color: $white;
              }

              .platforms-list {
                position: relative;
                flex-grow: 1;
                display: flex;
                align-content: flex-start;
                flex-wrap: wrap;

                min-height: 50px;

                overflow: auto;

                .data-check {
                  .progress {
                    transform: scale(0.4) translateY(-12px);
                  }
                  .no-data,
                  .error {
                    font-size: 14px;
                    text-align: center;
                    transform: translateY(17px);
                  }
                }

                .platform {
                  display: flex;
                  align-items: center;

                  height: fit-content;

                  .icon {
                    background-color: $white;
                    mask-size: cover;
                    mask-repeat: no-repeat;
                  }

                  .name {
                    white-space: nowrap;
                    color: $white;
                  }
                }
              }
            }

            .charity-wrapper {
              display: flex;
              flex-direction: column;

              .charity-title {
                @include inter(500);
                color: $white;
              }

              .charity {
                display: flex;
                align-items: center;

                svg {
                  color: $white;
                }

                p {
                  @include inter(500);
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    .bottom-content {
      .content-wrapper {
        .details-wrapper {
          .titles {
            .sub-title {
              @include lineClamp(1);
            }
          }

          .meta-wrapper {
            display: flex;
            flex-direction: column;

            .date {
              @include inter(400);
              text-transform: uppercase;
            }

            .time {
              @include inter(400);
            }
          }
        }

        .pills {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-end;

          height: 100%;
        }
      }
    }
  }

  &.small {
    .top {
      .top-content {
        .more-info-wrapper {
          padding: 18px;

          .title {
            font-size: 20px;
          }

          .more-info-inner-wrapper {
            .extra-info-wrapper {
              .extra-info {
                height: 32px;
                max-height: 32px;
                line-height: 32px;

                font-size: 12px;
              }
            }

            .platforms-wrapper {
              gap: 6px;

              .platforms-title {
                font-size: 14px;
              }

              .platforms-list {
                gap: 6px 10px;

                .platform {
                  gap: 6px;

                  .icon {
                    @include size(16px);
                  }

                  .name {
                    font-size: 10px;
                  }
                }
              }
            }

            .charity-wrapper {
              gap: 6px;

              .charity-title {
                font-size: 14px;
              }

              .charity {
                gap: 6px;

                svg {
                  @include size(16px);
                }

                p {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }

    .bottom {
      .bottom-content {
        .details-wrapper {
          .meta-wrapper {
            .date {
              font-size: 10px;
            }

            .time {
              font-size: 8px;
            }
          }
        }
      }
    }
  }

  &.medium {
    .top {
      .top-content {
        .more-info-wrapper {
          padding: 20px;

          .title {
            font-size: 22px;
          }

          .more-info-inner-wrapper {
            .extra-info-wrapper {
              .extra-info {
                height: 60px;
                max-height: 60px;
                line-height: 60px;

                font-size: 12px;
              }
            }

            .platforms-wrapper {
              gap: 8px;

              .platforms-title {
                font-size: 16px;
              }

              .platforms-list {
                gap: 8px 12px;

                .platform {
                  gap: 8px;

                  .icon {
                    @include size(18px);
                  }

                  .name {
                    font-size: 12px;
                  }
                }
              }
            }

            .charity-wrapper {
              gap: 8px;

              .charity-title {
                font-size: 16px;
              }

              .charity {
                gap: 8px;

                svg {
                  @include size(18px);
                }

                p {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }

    .bottom {
      .bottom-content {
        .details-wrapper {
          .meta-wrapper {
            .date {
              font-size: 12px;
            }

            .time {
              font-size: 10px;
            }
          }
        }
      }
    }
  }

  &.large {
    .top {
      .top-content {
        .more-info-wrapper {
          padding: 32px;

          .title {
            font-size: 26px;
          }

          .more-info-inner-wrapper {
            .extra-info-wrapper {
              .extra-info {
                height: 90px;
                max-height: 90px;
                line-height: 90px;

                font-size: 14px;
              }
            }

            .platforms-wrapper {
              gap: 12px;

              .platforms-title {
                font-size: 20px;
              }

              .platforms-list {
                gap: 12px 16px;

                .platform {
                  gap: 12px;

                  .icon {
                    @include size(22px);
                  }

                  .name {
                    font-size: 16px;
                  }
                }
              }
            }

            .charity-wrapper {
              gap: 12px;

              .charity-title {
                font-size: 20px;
              }

              .charity {
                gap: 12px;

                svg {
                  @include size(22px);
                }

                p {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }

    .bottom {
      .bottom-content {
        .details-wrapper {
          .meta-wrapper {
            .date {
              font-size: 12px;
            }

            .time {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
