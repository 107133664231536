&.experiences {
  width: 80%;

  margin: 64px auto 0 auto;

  @include maxScreen($lg) {
    width: 90%;
  }

  .cards {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 64px;

    min-height: 100px;

    margin-top: 64px;

    @include maxScreen($sm) {
      gap: 32px;

      margin-top: 32px;
    }
  }
}
