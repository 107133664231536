.tabs {
  position: relative;

  height: fit-content;
  width: fit-content;
  max-width: 100%;

  overflow-x: scroll;
  @include hideScrollbar();

  @mixin sizing($indicator-size, $border-radius) {
    .tabs-wrapper {
      .tabs-inner {
        border-radius: $border-radius;

        .indicator {
          .indicator-circle {
            @include size($indicator-size);
          }
        }
      }
    }

    &.rounded {
      .tabs-wrapper {
        .tabs-inner {
          border-radius: calc($border-radius * 4.3);
        }
      }
    }
  }

  &.extra-small {
    @include sizing($indicator-size: 4px, $border-radius: 3px);
  }

  &.small {
    @include sizing($indicator-size: 5px, $border-radius: 4px);
  }

  &.medium {
    @include sizing($indicator-size: 6px, $border-radius: 6px);
  }

  &.large {
    @include sizing($indicator-size: 7px, $border-radius: 8px);
  }

  &.extra-large {
    @include sizing($indicator-size: 8px, $border-radius: 10px);
  }

  &.contained {
    @each $name, $color in $colors {
      &.#{$name} {
        .tabs-wrapper {
          .tabs-inner {
            background-color: $color;
          }
        }
      }
    }
  }

  &.transparent {
    @each $name, $color in $colors {
      &.#{$name} {
        .tabs-wrapper {
          .tabs-inner {
            background-color: rgba($color, 0.3);
          }
        }
      }
    }
  }

  &.flat {
    .tabs-wrapper {
      .tabs-inner {
        .indicator {
          @each $name, $color in $colors {
            &.#{$name} {
              .pill-inner {
                height: 100%;

                span {
                  height: 100%;

                  .indicator-circle {
                    position: absolute;

                    bottom: -40%;

                    background-color: $color;
                    border-radius: 50%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.underlined {
    .tabs-wrapper {
      .tabs-inner {
        .indicator {
          @each $name, $color in $colors {
            &.#{$name} {
              .pill-inner {
                height: 100%;

                span {
                  height: 100%;

                  .indicator-underline {
                    position: absolute;

                    bottom: -40%;
                    left: 0;

                    width: 100%;

                    border-style: none none solid none;
                    border-width: 2px;
                    border-color: $color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .tabs-wrapper {
    position: relative;

    width: calc(100% - 2px);

    padding: 1px;

    .tabs-inner {
      position: relative;
      display: flex;
      align-items: center;
      gap: 4px;

      width: calc(100% - 2px);

      padding: 1px;

      box-sizing: border-box;

      .tab {
        &:active {
          z-index: 50;
        }
        @include minScreen($md) {
          &:hover {
            z-index: 50;
          }
        }

        &.selected {
          .button-inner {
            z-index: 100;
            pointer-events: none;

            span {
              transition: color 300ms ease;
            }
          }
        }
      }

      .indicator {
        position: absolute;

        height: calc(100% - 2px);

        z-index: 75;
        transition: transform 300ms ease;
      }
    }
  }
}
