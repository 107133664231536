&.how-it-works {
  width: 80%;

  margin: 48px auto 0 auto;

  @include maxScreen($lg) {
    width: 90%;
  }

  .box {
    padding: 24px 32px 32px 32px;

    background-color: $black-2;
    border-radius: 5px;

    @include maxScreen($sm) {
      padding: 24px 16px;
    }

    .bars {
      display: flex;
      flex-direction: column;
      gap: 8px;

      margin-top: 24px;

      svg {
        @include size(25px);
      }

      .bar {
        display: flex;
        align-items: center;
        gap: 16px;

        padding: 16px 20px;

        background-color: $black-3;
        border-radius: 5px;

        @include maxScreen($sm) {
          flex-direction: column;
        }

        .content {
          .bar-title {
            @include blatant(700);
            font-size: 18px;
            color: $white;

            @include maxScreen($sm) {
              font-size: 16px;
              text-align: center;
            }
          }

          .bar-text {
            @include inter(500);
            font-size: 14px;
            color: $gray-2;

            @include maxScreen($sm) {
              font-size: 13px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
