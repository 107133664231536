.divider {
  $opacity: var(--opacity);
  $width: var(--width);

  opacity: $opacity;

  &.horizontal {
    width: 100%;
    height: 0;
    min-height: 0;
    max-height: 0;
    border-width: $width;

    &.solid {
      border-top-style: solid;
    }

    &.dotted {
      border-top-style: dotted;
    }

    &.dashed {
      border-top-style: dashed;
    }
  }

  @each $name, $color in $colors {
    &.#{$name} {
      border-color: $color;
    }
  }

  &.vertical {
    height: 100%;
    width: 0;
    min-width: 0;
    max-width: 0;
    border-width: $width;

    &.solid {
      border-left-style: solid;
    }

    &.dotted {
      border-left-style: dotted;
    }

    &.dashed {
      border-left-style: dashed;
    }
  }
}
