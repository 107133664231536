.cig-event {
  position: relative;
  display: flex;

  width: 1em;
  min-width: 1em;
  height: 0.39em;

  border-radius: 0.0113em;

  overflow: hidden;
  cursor: pointer;

  &:hover {
    .game-wrapper {
      .background-image {
        transform: scale(1.1);
      }
    }
  }

  &.is-loading {
    pointer-events: none;
  }

  .details-wrapper {
    flex-basis: 72.5%;
    display: flex;
    flex-direction: column;

    min-width: 72.5%;

    background-color: $black-1;
    border-radius: 0.0113em 0.0339em 0.0339em 0.0113em;

    box-sizing: border-box;
    overflow: hidden;

    .top-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      padding: 0.03em;

      .event-type-wrapper {
        display: flex;
        align-items: center;
        gap: 0.02em;

        .event-icon {
          @include size(0.036em);
        }

        .event-type {
          font-size: 0.036em;
          @include blatant(700);
          @include textGradient($gradient-faded-horizontal);
          text-transform: uppercase;
        }
      }

      .title {
        color: $white;
        font-size: 0.0555em;
        @include blatant(700, italic);
        @include textOverflow;
        text-transform: uppercase;
      }

      .extra-details {
        color: $gray-1;
        font-size: 0.027em;
        @include inter(600);
        @include lineClamp(3);
      }
    }

    .divider {
      height: 3px;
      min-height: 3px;

      border: none;
      @include backgroundGradient($gradient-faded-horizontal);
    }

    .bottom-wrapper {
      display: flex;
      align-items: center;
      gap: 0.03em;

      padding: 0.02em 0.03em;

      .detail {
        @include inter(900, italic);
        font-size: 0.045em;
        color: $white;

        span {
          color: $gray-1;
        }
      }
    }
  }

  .game-wrapper {
    flex-basis: 27.5%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    min-width: 27.5%;

    border-radius: 0.0339em 0.0113em 0.0113em 0.0339em;
    background-color: $black-3;

    overflow: hidden;
    box-sizing: border-box;
    z-index: 100;

    .background-image {
      transition: transform 300ms ease;
    }

    .date {
      position: relative;

      margin: auto;

      color: $white;
      font-size: 0.068em;
      @include blatant(700, italic);
      text-transform: uppercase;
      text-align: center;
      word-spacing: 2em;
      line-height: 0.9;
    }

    .time {
      position: absolute;

      bottom: 0.6em;
      margin: auto;

      color: $white;
      font-size: 0.045em;
      @include inter(900, italic);
      text-transform: uppercase;
      text-align: center;
      line-height: 1;
    }
  }
}
