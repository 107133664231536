.calendar-manage .calendar-wrapper {
  .calendar-manage-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 16px 48px 16px 16px;

    min-height: 0;

    @include maxScreen($sm) {
      padding: 16px;
    }

    .dates {
      .row {
        grid-template-columns: 80px repeat(7, 160px);

        @include maxScreen($xl) {
          grid-template-columns: 80px repeat(7, 140px);
        }

        @include maxScreen($lg) {
          grid-template-columns: 80px repeat(7, 110px);
        }

        @include maxScreen($md) {
          grid-template-columns: 80px calc(100% - 160px);
        }
      }
    }

    .blocks-wrapper {
      flex-grow: 1;
      position: relative;
      display: flex;
      flex-direction: column;

      min-height: 0;

      .indicator {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: rgba($black-3, 0.3);

        z-index: 200;
        pointer-events: none;

        &.x {
          top: 0;
          bottom: 0;
          height: 100%;
          width: 30px;

          &:not(.left):not(.right) {
            display: none;
          }

          &.left {
            left: 0;
          }

          &.right {
            right: 0;
          }
        }

        &.y {
          left: 0;
          right: 0;
          width: 100%;
          height: 30px;

          &:not(.top):not(.bottom) {
            display: none;
          }

          &.top {
            top: 0;
          }

          &.bottom {
            bottom: 0;
          }
        }

        svg {
          color: $white;
          @include size(24px);
        }
      }

      .blocks {
        flex-grow: 1;
        position: relative;

        min-height: 0;

        padding: 16px;

        user-select: none;
        overflow-y: auto;

        @include maxScreen($sm) {
          padding: 8px;
        }

        &.no-access {
          opacity: 0.1;
        }

        &.selecting {
          cursor: grabbing;
        }

        .row {
          position: relative;
          display: grid;
          grid-template-columns: 80px repeat(7, 160px);

          @include maxScreen($xl) {
            grid-template-columns: 80px repeat(7, 140px);
          }

          @include maxScreen($lg) {
            grid-template-columns: 80px repeat(7, 110px);
          }

          @include maxScreen($md) {
            grid-template-columns: 80px auto;
          }

          .cell {
            position: relative;

            &:not(.spacer) {
              cursor: grab;

              transition: background-color 100ms ease;

              &:hover {
                background-color: $black-4;
              }
            }
          }

          &:not(.divider) {
            .cell:not(.spacer) {
              display: flex;
              height: 60px;

              border-color: $gray-3;
              border-width: 1px;
              border-bottom-style: solid;

              overflow: visible;
              touch-action: none;

              &.first {
                border-top-style: solid;
              }

              &.in-past {
                cursor: not-allowed;
                background-color: $black-3;
              }
            }
          }

          &.divider {
            height: 0;

            overflow: visible;

            .time {
              margin-right: 16px;

              font-size: 14px;
              color: $gray-3;
              white-space: nowrap;
              text-align: right;

              transform: translateY(-50%);
            }
          }
        }

        .session {
          position: absolute;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          margin: 4px;

          background-color: $black-4;
          border: 1px solid $gray-3;
          border-radius: 5px;

          box-sizing: border-box;
          z-index: 100;
          touch-action: none;
          cursor: pointer;

          transition-duration: 300ms;
          transition-timing-function: ease;
          transition-property: box-shadow, border-color, transform;

          .selection-indicator {
            position: absolute;

            inset: -5px;

            border-radius: 6px;
            border: 2px dashed $gray-3;
            transition: border-color ease 300ms;
            pointer-events: none;
          }

          &.private {
            background-color: $black-3;
            border-color: $gray-2;
          }

          &.full,
          &.ongoing {
            background-color: $purple-1;
            border-color: $purple-1;
          }

          &.ended {
            background-color: $black-2;
            border-color: $gray-2;

            .details-wrapper {
              .details {
                .slots-wrapper {
                  .slots {
                    color: $gray-2;
                    text-decoration: line-through;
                  }
                }
              }
            }
          }

          &.session-header {
            border-bottom-style: none !important;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          &.session-body {
            border-top-style: none !important;
            border-bottom-style: none !important;
            border-radius: 0;

            .details {
              display: none;
            }
          }

          &.session-footer {
            border-top-style: none !important;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            .details {
              display: none;
            }
          }

          &.selecting,
          &.error {
            display: flex;
            align-items: center;

            .prompt-text {
              padding: 8px 4px;

              @include inter(600);
              font-size: 12px;
              text-align: center;
            }

            &.small {
              .prompt-text {
                font-size: 10px;
              }
            }
          }

          &.selecting {
            border: 2px dashed $gray-2;
            box-shadow: 0 0 20px rgba($gray-3, 0.5);

            .prompt-text {
              color: $gray-3;
            }

            .prompt-icon {
              color: $gray-3;
            }
          }

          &.creating {
            border: 2px dashed $gray-2;
            box-shadow: 0 0 20px rgba($black-3, 0.1);

            pointer-events: none;

            .prompt-text {
              color: $purple;
            }
          }

          &.error {
            border-color: $red;

            .prompt-text {
              color: $red;
            }
          }

          &.overlapping {
            border-style: dashed;
          }

          &.small {
            &:not(.selecting) {
              .details-wrapper {
                .details {
                  justify-content: space-between;
                  gap: 0;

                  padding: 2px 0 2px 8px;

                  .status {
                    font-size: 10px;
                  }

                  .game-name {
                    font-size: 12px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  .slots-wrapper {
                    svg {
                      @include size(14px);
                    }

                    .slots {
                      font-size: 12px;
                    }
                  }
                }

                .icons {
                  justify-content: space-between;
                  gap: 0;

                  padding: 4px 4px 4px 0;

                  & > *,
                  svg,
                  img {
                    @include size(20px);
                  }
                }
              }
            }
          }

          .details-wrapper {
            display: flex;

            .details {
              flex-grow: 1;

              display: flex;
              flex-direction: column;
              gap: 8px;

              padding: 8px 0 8px 8px;

              overflow: hidden;

              .status {
                color: $white;
                @include textOverflow();
              }

              .game-name {
                @include inter(600);
                font-size: 14px;
                color: $white;
                @include lineClamp(2);
              }

              .slots-wrapper {
                display: flex;
                align-items: center;
                gap: 4px;

                svg {
                  color: $gray-2;
                  @include size(20px);
                }

                .slots {
                  font-size: 14px;
                  color: $gray-3;
                }
              }
            }

            .icons {
              display: flex;
              flex-direction: column;
              gap: 4px;

              top: 0;
              right: 0;

              padding: 8px 6px 8px 0;

              & > *,
              svg,
              img {
                color: $gray-3;
                @include size(24px);
              }
            }
          }
        }
      }
    }

    .footer-prompt {
      display: flex;
      flex-direction: column;
      gap: 16px;

      p {
        @include inter(600);
        color: $gray-3;
        text-align: center;
        font-size: 14px;
      }
    }
  }
}

.create-session-panel {
  .action-panel-form {
    .form-wrapper {
      .form {
        .inner {
          .form-row {
            .form-entry {
              .button-select {
                .button {
                  width: 32px;
                  transition-property: opacity, background-color;

                  @include maxScreen($sm) {
                    width: 24px;
                  }
                }
              }

              .repeat-count {
                flex-grow: 1;
              }
            }

            .price-entry,
            .slots-entry {
              flex-grow: 0;
              flex-basis: 50%;
            }

            .dates-entry {
              .form-group {
                .date-picker-wrapper {
                  flex-basis: 57%;
                }
                .time-picker-wrapper {
                  flex-basis: 43%;
                }

                @include maxScreen($sm) {
                  .time-picker-wrapper {
                    flex-grow: 1;
                  }
                }
              }
            }

            .repeat-entry {
              .checkbox-label {
                .label-wrapper {
                  flex-grow: 1;
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  .select {
                    width: 55px;
                  }

                  &.disabled {
                    @include disabled;
                  }
                }
              }
            }
          }

          .price-slots-row {
            @include maxScreen($xs) {
              flex-direction: column;
              align-items: stretch;
            }
          }
        }
      }
    }
  }
}

.update-session-panel {
  .action-panel-form {
    .form-wrapper {
      .form {
        .inner {
          .form-row {
            .price-entry,
            .slots-entry {
              flex-grow: 0;
              flex-basis: 50%;
            }

            .slots-entry {
              p {
                flex-grow: 1;
                display: flex;
                align-items: center;
              }
            }
          }

          .dates-row {
            align-items: center;

            @include maxScreen($xs) {
              flex-direction: column;
              gap: 0;
            }

            .start-entry {
              text-align: right;

              @include maxScreen($xs) {
                text-align: left;
                flex-direction: column-reverse;
                align-self: flex-start;
              }

              .form-label {
                @include maxScreen($xs) {
                  padding-bottom: 0;
                  padding-top: 8px;
                }
              }
            }

            .form-entry {
              .form-label {
                @include maxScreen($sm) {
                  font-size: 12px;
                }
              }

              p {
                white-space: nowrap;
                @include inter(600);

                @include maxScreen($sm) {
                  font-size: 14px;
                }
              }
            }

            .end-entry {
              text-align: left;

              @include maxScreen($xs) {
                text-align: right;
                align-self: flex-end;
              }
            }

            svg {
              color: $gray-3;
              @include size(32px);

              transition: transform 500ms ease;

              @include maxScreen($xs) {
                transform: rotate(45deg);
              }
            }
          }
        }
      }
    }
  }
}

.create-session-panel,
.update-session-panel {
  .action-panel-header {
    .form-row {
      flex-grow: 1;

      .form-entry {
        flex-grow: 1;

        .input-wrapper {
          .input {
            font-size: 24px;
            @include inter(600);
          }
        }
      }
    }
  }

  .action-panel-form {
    .form-wrapper {
      .form {
        .platforms-row {
          .form-entry {
            .platforms {
              display: grid;
              grid-template-rows: auto;
              gap: 8px;

              width: fit-content;
              min-height: 43px;

              padding-top: 8px;

              .data-check {
                .progress {
                  transform: scale(0.4) translateY(40px);
                }
                .no-data,
                .error {
                  font-size: 14px;
                  text-align: center;
                  transform: translateY(17px);
                }
              }

              .platform {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 2px;

                .icon {
                  width: 18px;
                  height: 18px;
                  background-color: $gray-3;
                  mask-size: cover;
                  mask-repeat: no-repeat;
                }

                .name {
                  white-space: nowrap;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
