&.how-it-works {
  padding-top: 96px;

  // Scale up
  @include minScreen($sl) {
    padding-top: 128px;
  }

  @include minScreen($ul) {
    padding-top: 192px;
  }

  .boxes {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 32px;

    margin-top: 32px;

    // Scale up
    @include minScreen($ul) {
      gap: 48px;

      margin-top: 128px;
    }

    @include minScreen($sl) {
      gap: 64px;

      margin-top: 64px;
    }

    // Scale down
    @include maxScreen($xl) {
      gap: 16px;
    }

    @include minMaxScreen($md, $lg) {
      grid-template-columns: 1fr;
    }

    @include maxScreen($md) {
      grid-template-columns: 1fr;
    }

    .group {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      gap: 32px;

      // Scale up
      @include minScreen($sl) {
        gap: 48px;
      }

      @include minScreen($ul) {
        gap: 64px;
      }

      // Scale down
      @include maxScreen($xl) {
        gap: 16px;
      }

      @include minMaxScreen($md, $lg) {
        &:last-of-type {
          :first-child {
            order: 2;
          }

          .divider-arrow-wrapper {
            order: 1;
            transform: rotate((180deg));
          }

          :last-child {
            order: 0;
          }
        }
      }

      // Scale down
      @include maxScreen($md) {
        grid-template-columns: 1fr;
      }
    }

    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;

      padding: 48px 32px;

      box-sizing: border-box;

      // Scale up
      @include minScreen($sl) {
        gap: 48px;

        padding: 64px 48px;
      }

      @include minScreen($ul) {
        gap: 64px;

        padding: 96px 64px;
      }

      svg {
        color: $gray-2;
        @include size(42px);

        // Scale up
        @include minScreen($sl) {
          @include size(64px);
        }

        @include minScreen($ul) {
          @include size(96px);
        }
      }

      .text {
        @include inter(600);
        color: $white;
        font-size: 16px;
        text-align: center;

        // Scale up
        @include minScreen($sl) {
          font-size: 24px;
        }

        @include minScreen($ul) {
          font-size: 32px;
        }
      }
    }

    .divider-arrow-wrapper {
      @include size(40px);

      margin: auto 0;

      color: $gray-1;

      // Scale up
      @include minScreen($sl) {
        @include size(60px);

        svg {
          @include size(60px);
        }
      }

      @include minScreen($ul) {
        @include size(92px);

        svg {
          @include size(92px);
        }
      }

      // Scale down
      @include minMaxScreen($md, $lg) {
        &.middle {
          margin: 0 0 0 75%;

          transform: rotate(90deg);
        }
      }

      @include maxScreen($md) {
        margin: 0 auto;

        transform: rotate(90deg);
      }
    }

    svg {
      @include size(40px);
    }
  }
}
