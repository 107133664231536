.notifications {
  position: fixed;
  display: flex;
  flex-direction: column;

  width: 300px;
  min-width: 300px;
  max-width: 300px;

  background-color: $black-2;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba($black, 0.1);

  box-sizing: border-box;
  transition: opacity 300ms ease;
  overflow: hidden;
  z-index: $z-modal;

  &.full-width {
    width: 100%;

    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &.top {
    transform: translate(-50%, calc(-100% - 10px));
  }

  &.bottom {
    transform: translate(-50%, 10px);
  }

  &.right {
    transform: translate(10px, -50%);
  }

  &.left {
    transform: translate(calc(-100% - 10px), -50%);
  }

  &.center {
    transform: translate(-50%, -50%);
  }

  .notifications-inner {
    .notifications-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 8px 16px;

      .title {
        @include inter(500);
        color: $white;
      }

      .button {
        white-space: nowrap;
        font-size: 14px;
      }
    }

    .progress {
      transform: scale(0.4) translateY(-50%);
    }

    .notifications-list {
      position: relative;

      min-height: 48px;

      max-height: 50vh;
      overflow: auto;

      .notification {
        position: relative;
        display: flex;
        align-items: center;
        gap: 16px;

        padding: 12px 16px;

        @each $name, $color in $colors {
          &.#{$name} {
            .icon-wrapper {
              svg {
                color: $color;
              }
            }
          }
        }

        &.read {
          background-color: $black-3;

          .icon-wrapper {
            svg {
              color: $gray-3;
            }
          }

          .content-wrapper {
            .content {
              color: $gray-3;
            }

            .date {
              color: $gray-2;
            }
          }
        }

        .close-icon {
          position: absolute;
          right: 0;
          top: 0;

          margin: 8px;

          .button-icon {
            @include size(14px);
          }
        }

        .icon-wrapper {
          @include size(24px);
          :first-child {
            @include size(24px);
          }
        }

        .content-wrapper {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .content {
            @include inter(500);
            font-size: 14px;
            color: $white;
          }

          .date {
            @include inter(400);
            font-size: 12px;
            color: $gray-3;
          }
        }
      }
    }

    .notifications-show-all {
      display: flex;
      justify-content: center;

      padding: 8px 16px;
    }
  }
}
