&.why {
  margin: 48px 0 80px 0;

  .cards-wrapper {
    display: flex;
    justify-content: space-around;
    gap: 96px;

    margin-top: 32px;

    // Scale down
    @include maxScreen($xl) {
      gap: 48px;
    }

    @include maxScreen($lg) {
      gap: 32px;
    }

    @include maxScreen($md) {
      flex-wrap: wrap;
    }

    // Scale up
    @include minScreen($sl) {
      margin-top: 48px;
    }

    @include minScreen($ul) {
      margin-top: 96px;
    }

    .card {
      flex-basis: 33%;

      max-width: 500px;

      padding: 16px 24px;

      background-color: $black-2;
      border: solid 2px $black-3;
      border-radius: 5px;

      box-sizing: border-box;

      // Scale down
      @include maxScreen($lg) {
        padding: 16px;
      }

      @include maxScreen($md) {
        min-width: 300px;
      }

      @include maxScreen($sm) {
        min-width: 250px;
      }

      // Scale up
      @include minScreen($sl) {
        min-width: 500px;

        padding: 24px 32px;
      }

      @include minScreen($ul) {
        min-width: 800px;

        padding: 32px 48px;
      }

      .card-img-wrapper {
        position: relative;

        aspect-ratio: 100 / 47;

        border-radius: 5px 5px 0 0;

        overflow: hidden;

        .card-img {
          width: 100%;
        }
      }

      .content-wrapper {
        margin-top: 8px;

        .title {
          @include inter(700);
          @include textGradient($gradient-faded-horizontal);
          font-size: 60px;

          // Scale down
          @include maxScreen($xl) {
            font-size: 40px;
          }

          @include maxScreen($lg) {
            font-size: 32px;
          }

          // Scale up
          @include minScreen($sl) {
            font-size: 80px;
          }

          @include minScreen($ul) {
            font-size: 128px;
          }
        }

        .text {
          margin-top: 16px;

          @include inter(500);
          color: $white;
          font-size: 16px;

          // Scale down
          @include maxScreen($xl) {
            font-size: 14px;
          }

          @include maxScreen($lg) {
            font-size: 12px;
          }

          // Scale up
          @include minScreen($sl) {
            font-size: 20px;
          }

          @include minScreen($ul) {
            font-size: 32px;
          }
        }
      }
    }
  }
}
