&.top-splash {
  margin-top: 128px;

  .inner {
    z-index: 100;

    &::after {
      display: none;
    }

    .title {
      @include blatant(700);
      font-size: 68px;
      color: $white;
      text-align: center;
      line-height: 1em;

      @include maxScreen($lg) {
        font-size: 56px;
      }

      @include maxScreen($md) {
        font-size: 40px;
      }

      @include maxScreen($sm) {
        font-size: 24px;
      }

      @include maxScreen($xs) {
        font-size: 20px;
      }
    }

    .sub-title {
      width: 70%;

      margin-top: 32px;

      @include inter(500);
      font-size: 24px;
      color: $gray-3;
      text-align: center;

      @include maxScreen($lg) {
        width: 80%;

        font-size: 18px;
      }

      @include maxScreen($md) {
        width: 90%;

        margin-top: 24px;

        font-size: 14px;
      }

      @include maxScreen($sm) {
        font-size: 12px;
      }

      @include maxScreen($xs) {
        margin-top: 16px;

        font-size: 10px;
      }

      span {
        @include inter(800);
        color: $purple-5;
      }
    }

    .button {
      width: 350px;

      margin-top: 40px;

      @include maxScreen($lg) {
        width: 250px;
      }

      @include maxScreen($md) {
        margin-top: 32px;
      }

      @include maxScreen($xs) {
        margin-top: 24px;
      }
    }

    .affiliates-bar-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100vw;

      margin-top: 48px;

      @include maxScreen($md) {
        margin-top: 32px;
      }

      @include maxScreen($xs) {
        margin-top: 24px;
      }

      $bar-width: 528px;

      .dividers {
        .divider {
          position: absolute;

          width: calc(50vw - ($bar-width / 2));

          opacity: 0.05;

          &:first-of-type {
            left: 0;
          }

          &:last-of-type {
            right: 0;
          }
        }
      }

      .affiliates-bar {
        position: relative;
        display: flex;
        align-items: center;
        gap: 28px;

        width: $bar-width;

        padding: 18px 40px;

        box-sizing: border-box;

        @include maxScreen($sm) {
          flex-direction: column;
          gap: 12px;

          width: 90%;

          padding: 16px 32px 24px 32px;
        }

        .background {
          position: absolute;

          width: 100%;
          height: 100%;

          inset: 0;

          background: $gradient-blend-horizontal;
          border-radius: 200px;

          opacity: 0.75;
        }

        .text {
          flex-grow: 1;

          @include inter(500);
          font-size: 12px;
          color: $white;

          z-index: 100;

          @include maxScreen($sm) {
            text-align: center;
          }
        }

        .avatars {
          flex-grow: 1;
          flex-shrink: 0;

          position: relative;
          display: flex;

          z-index: 100;

          @include maxScreen($sm) {
            position: absolute;

            bottom: 0;

            transform: translateY(60%);
          }

          .avatar-wrapper {
            margin-left: -16px;

            @include maxScreen($sm) {
              margin-left: -12px;
            }

            .plus-text {
              @include size(unset);
              @include inter(900);
              font-size: 7px;
              color: $white;
            }
          }
        }
      }
    }

    .top-splash-img {
      width: 100%;

      margin-top: 32px;
      margin-bottom: -240px;

      @include maxScreen($lg) {
        margin-bottom: -200px;
      }

      @include maxScreen($md) {
        margin-bottom: -100px;
      }

      @include maxScreen($sm) {
        margin-bottom: -50px;
        margin-top: 40px;
      }
    }
  }

  .circular-blur-wrapper {
    position: absolute;

    width: 100%;
    height: 100%;

    overflow: hidden;

    .circular-blur {
      position: absolute;

      bottom: 0;
      left: 50%;

      width: 75%;

      transform: translate(-50%, 50%);

      @include maxScreen($lg) {
        width: 85%;
      }

      @include maxScreen($md) {
        width: 100%;
      }

      @include maxScreen($sm) {
        width: 120%;
      }

      @include maxScreen($xs) {
        width: 140%;
      }
    }
  }
}
