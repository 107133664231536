.cig-card {
  position: relative;

  border-radius: 0.05em;

  height: 1.5em;

  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  .image-wrapper {
    position: relative;
    display: block;

    height: 1.25em;

    cursor: pointer;

    &:hover {
      .background-image {
        transform: scale(1.15);
      }
    }

    .background-image {
      transition: transform 300ms ease;

      .background-image-inner {
        background: linear-gradient(to top, $black 0%, rgba($black, 0.975) 9%, rgba($black, 0) 25%);
      }
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 0.007em;

      height: 100%;

      padding: 0.045em 0.05em;

      box-sizing: border-box;

      .display-name {
        @include inter(500);
        font-size: 0.0825em;
        color: $white;

        @include textOverflow;
      }

      .tag-line {
        @include inter(400);
        font-size: 0.055em;
        color: $gray-3;

        @include textOverflow;
      }
    }
  }

  .divider {
    opacity: 0.1;
  }

  .details-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 0.25em;

    padding: 0 0.07em;

    background-color: $black-2;

    .text {
      font-size: 0.06em;
      @include inter(500);
      color: $gray-3;
    }
  }
}
