.sections-wrapper.support {
  .make-a-claim {
    @include maxScreen($xl) {
      max-width: 100%;
    }

    .section-flex {
      .section-bar {
        padding: 0 !important;

        .wrapper {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;

          width: 100%;

          padding: 40px 40px;

          overflow: hidden;

          @include maxScreen($md) {
            flex-direction: column-reverse;
          }

          @include maxScreen($sm) {
            padding: 32px 32px;
          }

          .title-wrapper {
            display: flex;
            flex-direction: column;

            @include maxScreen($md) {
              align-items: center;
            }

            .title {
              color: $gray-3;
              @include inter(600);

              @include maxScreen($md) {
                text-align: center;
              }

              @include maxScreen($sm) {
                font-size: 24px;
              }

              span {
                color: $purple;
              }
            }

            .button {
              margin-top: 16px;
              width: fit-content;
            }
          }

          img {
            height: 200px;

            @include maxScreen($sm) {
              height: 160px;
            }
          }
        }
      }
    }
  }
}
