.accordion {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  box-sizing: border-box;
  overflow: hidden;

  @mixin sizing($padding, $min-height, $border-radius, $button-font-size, $content-font-size) {
    min-height: $min-height;

    border-radius: $border-radius;

    .accordion-button {
      padding: $padding;

      border-radius: $border-radius;

      font-size: $button-font-size;
    }

    .content-wrapper {
      .content-inner {
        padding: $padding $padding calc($padding * 1.5) $padding;

        .content {
          font-size: $content-font-size;
        }
      }
    }
  }

  &.extra-small {
    @include sizing(
      $padding: 5.5px,
      $min-height: 26px,
      $border-radius: 3px,
      $button-font-size: 14px,
      $content-font-size: 10px
    );
  }

  &.small {
    @include sizing(
      $padding: 9px,
      $min-height: 34px,
      $border-radius: 4px,
      $button-font-size: 16px,
      $content-font-size: 12px
    );
  }

  &.medium {
    @include sizing(
      $padding: 18px,
      $min-height: 55px,
      $border-radius: 6px,
      $button-font-size: 18px,
      $content-font-size: 14px
    );
  }

  &.large {
    @include sizing(
      $padding: 27px,
      $min-height: 75px,
      $border-radius: 8px,
      $button-font-size: 20px,
      $content-font-size: 16px
    );
  }

  &.extra-large {
    @include sizing(
      $padding: 42px,
      $min-height: 113px,
      $border-radius: 10px,
      $button-font-size: 28px,
      $content-font-size: 24px
    );
  }

  @each $name, $color in $colors {
    &.#{$name} {
      background-color: offset($name, 5%);

      .accordion-button {
        background-color: offset($name, 5%);

        &:hover {
          background-color: $color;
        }
      }
    }
  }

  @each $name, $color in $colors {
    &.#{$name}-button-text {
      .accordion-button {
        color: $color;
      }
    }
  }

  @each $name, $color in $colors {
    &.#{$name}-content-text {
      .content-wrapper {
        .content-inner {
          .accordion-divider {
            border-color: $color;
          }

          .content {
            color: $color;
          }
        }
      }
    }
  }

  .accordion-button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: transparent;
    border: 0;

    @include blatant(500);
    text-align: left;

    transition: background-color 300ms ease;
    cursor: pointer;
  }

  .content-wrapper {
    .content-inner {
      display: flex;
      align-items: stretch;
      gap: 16px;

      .accordion-divider {
        height: unset;
      }

      .content {
        color: $gray-3;
        @include inter(500);
      }
    }
  }
}
