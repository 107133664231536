&.cta-1 {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 96px 0 64px 0;

  // Scale up
  @include minScreen($sl) {
    margin: 128px 0 96px 0;
  }

  @include minScreen($ul) {
    margin: 192px 0 128px 0;
  }

  // Scale down
  @include maxScreen($sm) {
    margin: 96px 0 32px 0;
  }

  .splash-title {
    width: 50%;

    line-height: 1em;

    .dark-gradient {
      line-height: 1em;
    }

    // Scale down {
    @include maxScreen($xl) {
      width: 60%;
    }

    @include maxScreen($lg) {
      width: 70%;
    }

    @include maxScreen($md) {
      width: 80%;
    }

    @include maxScreen($sm) {
      width: 100%;
    }
  }
}
