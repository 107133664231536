&.top-splash {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 64px;

  padding-top: calc(106px + 48px);

  overflow: hidden;

  z-index: 100;

  @include maxScreen($xl) {
    gap: 48px;
  }

  @include maxScreen($lg) {
    flex-direction: column;
    padding-top: calc(90px + 48px);
  }

  @include maxScreen($md) {
    gap: 32px;

    padding-top: calc(90px + 32px);
  }

  @include maxScreen($sm) {
    padding-top: calc(90px + 16px);
  }

  .layer {
    position: absolute;

    z-index: -100;

    &.columns {
      display: flex;
      align-items: center;
      justify-content: center;

      top: 0;
      left: 0;
      right: 0;

      .column {
        display: flex;
        flex-direction: column;

        img {
          border-radius: 5px;
        }
      }
    }

    &.opacity {
      inset: 0;

      background-color: rgba($black, 0.8);
    }

    &.gradient {
      inset: 0;

      background: linear-gradient(to top, $black-1 0%, rgba($black, 0) 100%);
    }
  }

  .cta {
    flex-basis: 45%;
    display: flex;
    flex-direction: column;

    @include maxScreen($lg) {
      align-items: center;

      width: 100%;
    }

    .title {
      @include blatant(700);
      font-size: 80px;
      color: $white;
      line-height: 0.9em;

      // Scale down
      @include maxScreen($xl) {
        font-size: 60px;
      }

      @include maxScreen($lg) {
        text-align: center;
      }

      @include maxScreen($md) {
        font-size: 56px;
      }

      @include maxScreen($sm) {
        font-size: 40px;
      }

      @include maxScreen($xs) {
        font-size: 32px;
      }

      // Scale up
      @include minScreen($sl) {
        font-size: 100px;
      }

      @include minScreen($ul) {
        font-size: 144px;
      }
    }

    .sub-title {
      width: 90%;

      margin-top: 40px;

      @include inter(500);
      font-size: 20px;
      color: $gray-2;

      // Scale down
      @include maxScreen($xl) {
        margin-top: 24px;

        font-size: 16px;
      }

      @include maxScreen($lg) {
        text-align: center;
      }

      @include maxScreen($md) {
        margin-top: 16px;
      }

      @include maxScreen($sm) {
        font-size: 14px;
      }

      @include maxScreen($xs) {
        font-size: 12px;
      }

      // Scale up
      @include minScreen($sl) {
        font-size: 32px;
      }

      @include minScreen($ul) {
        font-size: 48px;
      }
    }

    .button-row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;

      margin-top: 24px;
      margin-bottom: 18px;

      @include maxScreen($sm) {
        justify-content: center;

        & > * {
          flex-grow: 1;
        }
      }

      .gradient-button {
        .button {
          min-width: 200px;
        }
      }
    }
  }

  .media {
    flex-basis: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include maxScreen($lg) {
      width: 100%;
    }

    &.is-img {
      flex-basis: 55%;
      align-self: stretch;
    }

    .video-wrapper {
      position: relative;

      width: 100%;
      max-width: 800px;

      aspect-ratio: 16 / 9;

      border-radius: 10px;
      background-color: $black-2;

      overflow: hidden;

      @include maxScreen($sm) {
        border-radius: 10px;
      }

      .clip {
        width: 100%;
        height: 100%;
      }
    }

    .image-wrapper {
      position: relative;

      width: 100%;
      height: 100%;

      aspect-ratio: 16 / 9;

      .background-image {
        background-size: contain;
      }
    }
  }
}
