.starting-soon-sessions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include minScreen($sm) {
    gap: 24px;

    padding: 24px;

    border-radius: 10px;
    background-color: $black-2;
  }

  @include maxScreen($sm) {
    gap: 8px;
  }

  .starting-soon-session-desktop {
    position: relative;
    display: flex;
    gap: 24px;

    height: 120px;
    min-height: 120px;

    border-radius: 5px;

    overflow: hidden;

    &.charity {
      .session {
        box-shadow: 0 0 0 1px $yellow;
      }
    }

    .session {
      flex-grow: 1;
      position: relative;

      height: calc(100% - 2px);

      margin: 1px;

      border-radius: 5px;

      color: $white;

      z-index: 100;

      overflow: hidden;
      cursor: pointer;

      .session-content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 100%;
        height: 100%;

        padding: 14px 20px;

        box-sizing: border-box;

        &:hover {
          .background-image {
            transform: scale(1.1);
          }
        }

        .background-image {
          border-radius: 8px;

          z-index: -100;

          overflow: hidden;
          backface-visibility: hidden;
          transform: translate3d(0, 0, 0);

          transition: transform 300ms ease;
        }

        .top,
        .bottom {
          display: flex;
          justify-content: space-between;
          gap: 12px;

          pointer-events: none;

          .pill {
            min-width: 90px;
          }

          .icon,
          .pill {
            pointer-events: all;
          }

          .pill-row {
            position: relative;
            display: flex;
            align-items: center;
            gap: 8px;

            .price {
              @include inter(700);
              font-size: 18px;

              .currency {
                color: $purple-4;
              }
            }
          }
        }

        .top {
          align-items: flex-start;

          min-width: 0;

          .date-time {
            margin-top: 2px;

            font-size: 18px;
            @include inter(400);
            @include textOverflow;
            line-height: 1em;
          }
        }

        .bottom {
          align-items: flex-end;

          min-width: 0;

          .text-wrapper {
            display: flex;
            flex-direction: column;
            gap: 4px;

            min-width: 0;

            .title {
              font-size: 18px;
              @include inter(500);
              line-height: 1em;

              @include textOverflow;
            }

            .game-name {
              font-size: 24px;
              @include inter(700);
              line-height: 1.2em;

              @include textOverflow;
            }
          }
        }
      }
    }

    .cig {
      flex-shrink: 0;
      flex-basis: 18%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      min-width: 0;
      overflow: hidden;

      .avatar-wrapper {
        cursor: pointer;
      }

      a {
        width: fit-content;
      }

      .cig-details {
        display: flex;
        flex-direction: column;

        .cig-name {
          width: 100%;

          @include inter(600);
          font-size: 16px;
          @include textOverflow;
          color: $white;

          cursor: pointer;
        }

        .cig-tag-line {
          width: 100%;

          @include inter(400);
          font-size: 18px;
          @include textOverflow;
          color: $gray-2;
        }
      }
    }
  }

  .starting-soon-session-mobile {
    position: relative;
    display: block;

    min-height: 262px;
    height: 262px;

    .session {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 48px;

      min-height: inherit;
      height: inherit;

      padding: 24px 16px 16px 8px;

      border-radius: 10px;

      overflow: hidden;
      box-sizing: border-box;

      .top,
      .bottom {
        display: flex;
        justify-content: space-between;

        .pill-row {
          display: flex;
          gap: 8px;

          .pill {
            min-width: 40px;
          }
        }
      }

      .bottom {
        .details-wrapper {
          .cig-name {
            margin-bottom: 4px;

            @include blatant(500);
            font-size: 22px;
          }

          .date,
          .time,
          .game-name {
            @include inter(500);
            font-size: 15.5;
          }
        }
      }
    }
  }
}
