.options {
  box-sizing: border-box;
  overflow: hidden;
  z-index: $z-select;

  .options-inner {
    display: flex;
    flex-direction: column;

    max-height: inherit;

    margin-top: 1px;

    overflow: auto;

    .button.option {
      width: 100%;
      box-shadow: none !important;
    }
  }

  @each $name, $color in $colors {
    &.#{$name} {
      .options-inner {
        @include scrollbar($color, offset($name, 5%));
      }
    }
  }

  @mixin sizing($border-radius, $max-height) {
    max-height: $max-height;

    border-radius: 0 0 $border-radius $border-radius;
  }

  &.extra-small {
    @include sizing($border-radius: 3px, $max-height: 100px);
  }

  &.small {
    @include sizing($border-radius: 4px, $max-height: 125px);
  }

  &.medium {
    @include sizing($border-radius: 6px, $max-height: 150px);
  }

  &.large {
    @include sizing($border-radius: 8px, $max-height: 200px);
  }

  &.extra-large {
    @include sizing($border-radius: 10px, $max-height: 300px);
  }

  &.contained,
  &.outlined {
    @each $name, $color in $colors {
      &.#{$name} {
        box-shadow: 0 0 0 2px offset($name, 5%);
      }
    }
  }

  &.flat,
  &.transparent {
    @each $name, $color in $colors {
      &.#{$name} {
        box-shadow: 0 0 0 2px rgba($color, 0.5);
      }
    }
  }

  .option.button {
    border-radius: 0;
  }
}
