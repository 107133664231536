&.banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  z-index: 100;

  @include maxScreen($sm) {
    &.edit-mode {
      height: unset;
    }
  }

  .background-image {
    .background-image-inner {
      background: linear-gradient(
        to top,
        $black-1 5%,
        rgba($black-1, 0.975) 10%,
        rgba($black-1, 0.1) 100%
      );
    }
  }

  @mixin bannerHeightStyles {
    height: 60%;

    @include maxScreen($md) {
      height: 50%;
    }

    @include maxScreen($sm) {
      height: 35%;
    }
  }

  &:not(.edit-mode) {
    .background-image {
      @include bannerHeightStyles;
    }
  }

  &.edit-mode {
    .content {
      pointer-events: none;

      & > * :not(.avatar-and-details):not(.details):not(.buttons) {
        pointer-events: all;

        &:disabled,
        &.disabled {
          pointer-events: none;
          & > * {
            pointer-events: none;
          }
        }
      }
    }

    .background-image {
      height: 100%;
    }

    .banner-file-picker {
      @include bannerHeightStyles;
    }
  }

  .banner-file-picker {
    position: absolute;

    top: 0;

    .file-picker-cta {
      @include maxScreen($sm) {
        width: 50%;

        margin-left: auto;
      }
    }
  }

  .content {
    display: flex;
    gap: 40px;

    width: 80%;

    margin-top: 225px;

    z-index: 100;

    @include maxScreen($lg) {
      width: 90%;

      margin-top: 200px;
    }

    @include maxScreen($md) {
      flex-direction: column;
      align-items: flex-end;
      gap: 20px;

      margin-top: 200px;
    }

    @include maxScreen($sm) {
      align-items: stretch;
      gap: 32px;

      margin-top: 50px;
    }

    .avatar-and-details {
      flex-grow: 1;
      position: relative;
      display: flex;
      gap: 40px;

      min-width: 0;

      @include maxScreen($md) {
        gap: 32px;

        width: 100%;
      }

      @include maxScreen($sm) {
        flex-direction: column;
        gap: 12px;
      }

      .profile-file-picker {
        @include size(250px);

        border-radius: 6px;

        @include maxScreen($lg) {
          @include size(225px);
        }

        @include maxScreen($md) {
          @include size(200px);
        }

        @include maxScreen($sm) {
          @include size(150px);
        }

        .edit-indicator {
          border-radius: 6px;
        }
      }

      .live-indicator {
        position: absolute;

        left: 0;
        top: 0;

        padding-right: 12px;

        background-color: rgba($black, 0.5);
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;

        @include maxScreen($sm) {
          padding-right: 10px;
        }
      }

      .details {
        flex-grow: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        position: relative;

        min-width: 0;

        &.top-margin {
          margin-top: 40px;

          @include maxScreen($md) {
            margin-top: 0;
          }
        }

        .buttons {
          display: flex;
          align-items: center;
          gap: 8px;

          .edit-button {
            width: fit-content;

            min-width: 113px;

            margin: 5px 0;
          }
        }

        .display-name {
          flex-shrink: 0;
          position: relative;

          @include blatant(700);
          font-size: 36px;
          color: $white;

          @include textOverflow;

          @include maxScreen($md) {
            font-size: 32px;
          }

          @include maxScreen($sm) {
            font-size: 29px;
          }
        }

        .rating-wrapper {
          position: relative;

          margin-top: 8px;

          display: flex;
          align-items: center;
          gap: 8px;

          .ratings {
            @include inter(500);
            font-size: 13px;
            color: $gray-1;

            @include maxScreen($md) {
              font-size: 12px;
            }
          }
        }

        .tag-line {
          position: relative;

          margin-top: 8px;

          @include inter(600);
          font-size: 20px;
          color: $white;

          @include textOverflow;

          @include maxScreen($md) {
            font-size: 18px;
          }

          @include maxScreen($sm) {
            font-size: 16px;
          }
        }

        .description {
          position: relative;

          margin-top: 6px;

          @include inter(400);
          font-size: 16px;
          color: $gray-3;

          @include lineClamp(4);

          @include maxScreen($lg) {
            @include lineClamp(3);
          }

          @include maxScreen($md) {
            @include lineClamp(4);
          }

          @include maxScreen($sm) {
            font-size: 13px;
          }
        }

        .tag-line-input {
          margin-top: 8px;
        }

        .description-input {
          min-height: 0;

          margin-top: 8px;
        }
      }
    }

    .cta {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;

      padding: 0 64px;

      @include maxScreen($xl) {
        padding: 0;
      }

      @include maxScreen($md) {
        padding: 0 16px;
      }

      @include maxScreen($sm) {
        padding: 0;
      }

      .book-now-button {
        @include maxScreen($sm) {
          width: 100%;
        }
      }
    }
  }
}
