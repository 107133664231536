.relative-portal {
  $spacing: var(--spacing);

  transition: opacity 300ms ease;

  &.exit-done,
  &:not(.enter-active):not(.enter-done):not(.exit-active) {
    visibility: hidden;
  }

  &:not(.enter-active):not(.enter-done) {
    opacity: 0;
  }

  &.enter-active,
  &.enter-done {
    opacity: 1;
  }

  &:not(.absolute) {
    &.top {
      transform: translate(-50%, calc(-100% - $spacing));
    }

    &.bottom {
      transform: translate(-50%, $spacing);
    }

    &.right {
      transform: translate($spacing, -50%);

      &.top {
        transform: translate($spacing, -100%);
      }

      &.bottom {
        transform: translate($spacing, 0);
      }
    }

    &.left {
      transform: translate(calc(-100% - $spacing), -50%);

      &.top {
        transform: translate(calc(-100% - $spacing), -100%);
      }

      &.bottom {
        transform: translate(calc(-100% - $spacing), 0);
      }
    }

    &.center {
      transform: translate(-50%, -50%);
    }
  }

  &.absolute {
    position: absolute;

    &.left {
      right: 100%;

      &:not(.top):not(.bottom) {
        transform: translateY(-50%);
        top: 50%;
      }
    }

    &.right {
      left: 100%;

      &:not(.top):not(.bottom) {
        transform: translateY(-50%);
        top: 50%;
      }
    }

    &.top {
      bottom: 100%;

      &:not(.right):not(.left) {
        transform: translateX(-50%);
        left: 50%;
      }
    }

    &.bottom {
      top: 100%;

      &:not(.right):not(.left) {
        transform: translateX(-50%);
        left: 50%;
      }
    }
  }

  &.portal-absolute {
    position: absolute;
  }

  &.portal-fixed {
    position: fixed;
  }

  &.mouse {
    position: fixed;
  }

  &.full-width {
    width: calc(100% - ($spacing * 2));

    left: 0 !important;
    right: 0 !important;
  }
}
