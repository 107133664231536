&.why-use-us {
  background-color: $off-white;

  .inner {
    &::after {
      border-top-color: $off-white;
    }
  }

  .inner {
    .flow-sub-title {
      margin-top: 64px;
    }

    .sub-text {
      margin-top: 32px;

      @include inter(500);
      font-size: 24px;
      color: $gray-1;

      @include maxScreen($lg) {
        font-size: 22px;
      }

      @include maxScreen($md) {
        font-size: 18px;
      }

      @include maxScreen($sm) {
        font-size: 14px;
      }

      @include maxScreen($xs) {
        font-size: 10px;
      }

      span {
        @include inter(800);
      }
    }

    .team {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px 32px;

      width: 65%;

      margin-top: 48px;

      @include maxScreen($lg) {
        width: 75%;
      }

      @include maxScreen($md) {
        grid-template-columns: 1fr;

        width: 85%;
      }

      @include maxScreen($sm) {
        width: 90%;
      }

      .team-member {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        max-width: 400px;

        padding: 32px 24px;
        margin: 0 auto;

        background-color: $white;
        border-radius: 15px;
        border: 1px solid rgba($black, 0.1);

        overflow: hidden;
        box-sizing: border-box;

        .new-tag {
          position: absolute;

          left: 0;
          top: 0;
          padding: 8px 12px;

          background-color: $purple-6;
          border-bottom-right-radius: 15px;

          @include inter(700);
          font-size: 20px;
          color: $gray-1;
          text-align: center;

          @include maxScreen($lg) {
            font-size: 18px;
          }

          @include maxScreen($md) {
            font-size: 16px;
          }

          @include maxScreen($sm) {
            font-size: 14px;
          }

          @include maxScreen($xs) {
            font-size: 12px;
          }
        }

        .name {
          margin-top: 8px;

          @include inter(800);
          font-size: 24px;
          color: $gray-1;
          text-align: center;

          @include maxScreen($lg) {
            font-size: 22px;
          }

          @include maxScreen($md) {
            font-size: 20px;
          }

          @include maxScreen($sm) {
            font-size: 18px;
          }

          @include maxScreen($xs) {
            font-size: 16px;
          }
        }

        .role {
          margin-top: 2px;

          @include inter(500);
          color: $gray-1;
          text-align: center;
        }

        .label {
          margin-top: 24px;

          @include inter(700);
          color: $gray-3;
          text-align: center;
        }

        .value {
          @include inter(500);
          color: $gray-1;
          text-align: center;
        }

        p {
          font-size: 18px;

          @include maxScreen($lg) {
            font-size: 16px;
          }

          @include maxScreen($md) {
            font-size: 14px;
          }

          @include maxScreen($sm) {
            font-size: 12px;
          }

          @include maxScreen($xs) {
            font-size: 10px;
          }
        }
      }
    }

    .divider-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 65%;

      margin-top: 40px;

      @include maxScreen($lg) {
        width: 75%;
      }

      @include maxScreen($md) {
        width: 85%;
      }

      @include maxScreen($sm) {
        width: 90%;
      }

      .many-more {
        position: absolute;

        padding: 0 16px;

        background-color: $off-white;

        @include inter(500);
        font-size: 24px;
        color: $old-white;

        @include maxScreen($lg) {
          font-size: 22px;
        }

        @include maxScreen($md) {
          font-size: 20px;
        }

        @include maxScreen($sm) {
          font-size: 18px;
        }

        @include maxScreen($xs) {
          font-size: 16px;
        }
      }
    }

    .landing-testimonial {
      margin-top: 96px;

      @include maxScreen($md) {
        margin-top: 64px;
      }
    }

    .button-row {
      display: flex;
      align-items: center;
      gap: 24px;

      margin: 48px 0 80px 0;

      @include maxScreen($xs) {
        flex-direction: column;
        align-items: stretch;
        gap: 16px;

        width: 80%;
      }
    }
  }
}
