@import "./experiencesManageSelectMobile.scss";
@import "./experiencesManageSelectDesktop.scss";

#experiences-manage-select-page {
  .experience-update-card {
    height: fit-content;

    padding: 0.1em 0.09em 0.09em 0.09em;

    box-sizing: border-box;
    user-select: none;

    @include maxScreen($sm) {
      padding: 0.05em 0.09em;
    }

    @each $name, $color in $colors {
      &.#{$name} {
        .experience-update-card-inner {
          .details {
            .text-row {
              .name {
                color: $color;
              }
            }
          }
        }
      }
    }

    .experience-update-card-inner {
      display: flex;
      flex-direction: column;

      height: 100%;

      border-radius: 0.015em;
      background-color: $black-3;

      overflow: hidden;

      .imagery {
        position: relative;
        height: 0.3em;

        z-index: 100;

        .diamond-icon {
          position: absolute;

          height: 120%;
          width: auto;

          inset: 0;

          margin: auto;
          padding-top: 0.04em;
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        gap: 0.03em;

        margin: 0.03em 0.04em 0.04em 0.04em;

        .text-row {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .name {
            @include blatant(600);
            font-size: 0.06em;
          }

          .status {
            @include inter(500);
            font-size: 0.04em;
            color: $gray-2;
          }
        }

        .button {
          width: 100%;
        }
      }
    }
  }
}
