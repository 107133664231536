.sections-wrapper.payment {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 48px;

    width: 500px;

    @include maxScreen($sm) {
      width: fit-content;
      margin-top: 16px;
      padding: 0 8px;
      gap: 24px;
    }

    .title-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      gap: 8px;

      @include maxScreen($sm) {
        gap: 16px;
      }

      .title {
        color: $gray-3;
        @include inter(600);
        text-align: center;

        @include maxScreen($sm) {
          font-size: 18px;
        }
      }
    }

    .brand-wrapper {
      .stripe-img {
        height: 60px;

        @include maxScreen($sm) {
          height: 40px;
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 16px;

      .group {
        display: flex;
        gap: 12px;
      }
    }
  }
}
