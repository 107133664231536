&.testimonials {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  --gap: 48px;

  $gap: var(--gap);
  gap: $gap;

  margin-top: 96px;

  // Scale up
  @include minScreen($sl) {
    --gap: 64px;

    margin-top: 128px;
  }

  @include minScreen($ul) {
    --gap: 96px;

    margin-top: 192px;
  }

  // Scale down
  @include maxScreen($lg) {
    grid-template-columns: 1fr 1fr;
  }

  @include maxScreen($md) {
    grid-template-columns: 1fr;
  }

  @include maxScreen($sm) {
    --gap: 32px;

    margin-top: 64px;
  }

  @include maxScreen($xs) {
    --gap: 24px;
  }

  .testimonial {
    padding: 24px;

    box-sizing: border-box;

    // Scale up
    @include minScreen($sl) {
      padding: 32px;
    }

    @include minScreen($ul) {
      padding: 48px;
    }

    @include minScreen($xl) {
      min-width: 450px;
    }

    // Scale down
    @include maxScreen($md) {
      flex-direction: column;

      padding: 20px;
    }

    @include maxScreen($xs) {
      padding: 16px;
    }

    .cig-details {
      display: flex;
      align-items: center;
      gap: 24px;

      // Scale up
      @include minScreen($sl) {
        gap: 32px;
      }

      @include minScreen($ul) {
        gap: 48px;
      }

      // Scale down
      @include maxScreen($xs) {
        gap: 16px;
      }

      .display-name {
        @include inter(700);
        font-size: 20px;

        // Scale up
        @include minScreen($sl) {
          font-size: 24px;
        }

        @include minScreen($ul) {
          font-size: 40px;
        }

        // Scale down
        @include maxScreen($lg) {
          font-size: 18px;
        }

        @include maxScreen($xs) {
          font-size: 16px;
        }
      }
    }

    .testimonial-text {
      margin-top: 24px;

      font-size: 18px;

      // Scale up
      @include minScreen($sl) {
        margin-top: 32px;

        font-size: 22px;
      }

      @include minScreen($ul) {
        margin-top: 48px;

        font-size: 36px;
      }

      // Scale down
      @include maxScreen($lg) {
        font-size: 16px;
      }

      @include maxScreen($md) {
        margin-top: 20px;
      }

      @include maxScreen($xs) {
        margin-top: 16px;

        font-size: 14px;
      }
    }
  }
}
