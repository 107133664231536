.footer {
  display: flex;
  justify-content: center;

  margin-top: auto;

  z-index: $z-header;

  .footer-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    width: 100%;

    padding: 32px 48px;

    box-sizing: border-box;

    @include maxScreen($md) {
      padding: 24px 32px;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 48px;

      @include maxScreen($sm) {
        width: 100%;
        gap: 12px;
      }

      .top {
        display: flex;
        justify-content: space-between;

        @include maxScreen($sm) {
          flex-direction: column;
          align-items: center;
          gap: 12px;
        }

        .branding {
          display: flex;
          gap: 16px;

          .logo-background {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            width: 56px;
            height: 56px;

            border-radius: 10px;

            background-color: $black;

            overflow: hidden;

            @include maxScreen($sm) {
              width: 40px;
              height: 40px;

              border-radius: 5px;
            }

            .blurs {
              .circular-blur {
                position: absolute;

                width: 175%;
                height: 175%;

                &:first-of-type {
                  top: 0;
                  left: 0;

                  transform: translate(-52.5%, -52.5%);
                }

                &:last-of-type {
                  bottom: 0;
                  right: 0;

                  transform: translate(52.5%, 52.5%);
                }
              }
            }

            .yakkr-logo {
              z-index: 100;
            }
          }
        }

        .socials {
          display: flex;
          align-items: center;
        }
      }

      .bottom {
        display: flex;
        justify-content: center;
        gap: 128px;

        @include maxScreen($md) {
          gap: 64px;
        }

        @include maxScreen($sm) {
          flex-direction: column;
          gap: 12px;
        }

        .link-column-group {
          display: flex;
          gap: 128px;

          @include maxScreen($md) {
            gap: 64px;
          }

          @include maxScreen($sm) {
            justify-content: space-around;
            gap: 12px;
          }

          @include maxScreen($xs) {
            justify-content: space-between;
          }

          .link-column {
            display: flex;
            flex-direction: column;
            gap: 12px;

            @include maxScreen($sm) {
              &:last-of-type {
                text-align: right;
              }
            }

            .link-header {
              margin-bottom: 4px;

              @include blatant(600);
              font-size: 16px;

              @include maxScreen($sm) {
                margin-bottom: 2px;
                font-size: 12px;
              }
            }

            .link {
              @include inter(500);
              color: $gray-2;
              font-size: 16px;

              @include maxScreen($sm) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
