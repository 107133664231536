.walkthrough {
  position: relative;

  width: 100vw;
  height: 100vh;

  background-color: $black;

  overflow: hidden;

  .walkthrough-banner {
    position: absolute;

    top: 0;
    left: 0;

    padding: 64px;

    z-index: 999999;

    @include maxScreen($sm) {
      display: flex;
      justify-content: center;

      right: 0;
      padding: 32px;
    }
  }

  .carousel {
    .slide {
      height: 100vh;
    }

    .next-button {
      position: absolute;

      right: 32px;
      top: 0;
      bottom: 0;

      margin: auto 0;

      @include size(224px);

      @include maxScreen($lg) {
        @include size(160px);
      }

      @include maxScreen($md) {
        @include size(128px);
      }

      @include maxScreen($sm) {
        right: 16px;
        top: 96px;
        @include size(96px);
      }

      .button-icon {
        @include size(192px);

        @include maxScreen($lg) {
          @include size(128px);
        }

        @include maxScreen($md) {
          @include size(96px);
        }

        @include maxScreen($sm) {
          @include size(64px);
        }
      }
    }
  }

  .walkthrough-circular-blurs {
    .circular-blur {
      position: absolute;

      width: 800px;
      height: 800px;

      transition: opacity 300ms ease;

      @include maxScreen($lg) {
        width: 700px;
        height: 700px;
      }

      @include maxScreen($md) {
        width: 600px;
        height: 600px;
      }

      @include maxScreen($sm) {
        width: 500px;
        height: 500px;
      }

      &:nth-of-type(1) {
        top: -42%;
        left: -15%;

        @include maxScreen($lg) {
          left: -25%;
        }

        @include maxScreen($sm) {
          top: -35%;
        }

        @include maxScreen($sm) {
          top: -300px;
        }
      }

      &:nth-of-type(2) {
        bottom: -35%;
        right: -14%;

        @include maxScreen($lg) {
          right: -24%;
        }

        @include maxScreen($sm) {
          bottom: -300px;
        }
      }
    }
  }

  .walkthrough-squares {
    z-index: 100;

    .square {
      position: absolute;
      width: 60px;
      height: 60px;

      transition: opacity 300ms ease;

      @include float(3s, 25px);

      @include maxScreen($lg) {
        width: 50px;
        height: 50px;
      }

      &.small {
        width: 40px;
        height: 40px;

        @include maxScreen($lg) {
          width: 30px;
          height: 30px;
        }
      }

      &:nth-of-type(1) {
        right: 28%;
        bottom: 25%;

        transform: rotate(30deg);
        animation-delay: 300ms;

        @include maxScreen($xl) {
          right: 38%;
        }

        @include maxScreen($lg) {
          right: unset;
          left: 35%;
        }

        @include maxScreen($sm) {
          bottom: 30%;
          left: 65%;
        }
      }

      &:nth-of-type(2) {
        right: 34%;
        bottom: 10%;

        transform: rotate(-15deg);

        @include maxScreen($xl) {
          right: 44%;
        }

        @include maxScreen($lg) {
          right: unset;
          left: 20%;
        }

        @include maxScreen($sm) {
          bottom: 20%;
          left: 30%;
        }
      }
    }
  }

  .walkthrough-circular-blurs,
  .walkthrough-squares {
    transition: opacity 300ms ease;

    &.enter-active,
    &.enter-done {
      opacity: 1;
    }

    &.exit-active,
    &.exit-exit {
      opacity: 0;
    }

    &.exit-done {
      display: none;
    }
  }
}
