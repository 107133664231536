@import "./updateExperienceCustomMobile.scss";
@import "./updateExperienceCustomDesktop.scss";

#update-experience-custom-page {
  .form-wrapper {
    .form {
      .inner {
        .form-section {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 16px;

          width: 100%;

          .any-checkbox {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
