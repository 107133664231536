.form-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 8px;

  min-height: 34px;

  padding: 24px;
  margin-top: 16px;

  border-radius: 11.25px;
  background-color: $black-2;

  text-align: left;

  @include maxScreen($sm) {
    padding: 18px;
    margin-top: 8px;
  }

  .form-bar-title {
    flex-grow: 1;

    @include inter(700);
    font-size: 15.75px;
    color: $white;

    @include maxScreen($xl) {
      font-size: 15px;
    }

    @include maxScreen($sm) {
      font-size: 14px;
    }
  }

  .buttons {
    display: flex;
    gap: 12px;

    @include maxScreen($sm) {
      gap: 8px;
    }
  }
}
