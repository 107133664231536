#style-guide-page {
  min-height: inherit;

  .showcases {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 32px;

    padding: 128px 64px 256px 32px;

    .accordion {
      .content-wrapper {
        .content-inner {
          .content {
            flex-grow: 1;

            .showcase {
              display: flex;
              align-items: flex-end;
              gap: 16px;

              &.is-dark-color {
                .group {
                  background-color: $white;
                  border-radius: 10px;
                }
              }

              .left-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;

                margin-right: auto;

                .select,
                .input {
                  width: 200px;
                }

                .title {
                  @include inter(700);
                  color: $gray-3;
                }
              }

              .group {
                display: flex;
                flex-direction: column;
                gap: 8px;

                padding: 8px;

                transition-property: background-color, border-radius;
                transition-duration: 500ms;
                transition-timing-function: ease;
              }

              &.buttons,
              &.pills,
              &.inputs,
              &.selects,
              &.sliders,
              &.number-inputs,
              &.currency-inputs,
              &.percent-inputs,
              &.country-selects {
                .group {
                  .extra-small {
                    width: 100px;
                  }
                  .small {
                    width: 125px;
                  }
                  .medium {
                    width: 165px;
                  }
                  .large {
                    width: 200px;
                  }
                  .extra-large {
                    width: 300px;
                  }
                }
              }
              &.increment-pickers {
                .group {
                  .extra-small {
                    width: 125px;
                  }
                  .small {
                    width: 165px;
                  }
                  .medium {
                    width: 200px;
                  }
                  .large {
                    width: 300px;
                  }
                  .extra-large {
                    width: 400px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
