#experiences-payment-completion-page {
  min-height: inherit;

  padding: 106px 0 64px 0;

  box-sizing: border-box;

  @include maxScreen($lg) {
    padding: 90px 0 48px 0;
  }

  @include maxScreen($sm) {
    padding: 16px 0;
  }

  .request-summary {
    display: flex;
    flex-direction: column;

    max-width: 480px;

    margin: 0 auto;

    padding: 12px 18px 0 18px;

    .title {
      font-size: 36px;
      @include blatant(700);
      @include textGradient($gradient-faded-horizontal);
      line-height: 1em;
      text-align: center;

      @include maxScreen($sm) {
        font-size: 32px;
      }
    }

    .sub-title {
      position: relative;

      min-height: 54px;

      margin-top: 16px;

      border-radius: 5px;

      font-size: 14px;
      @include inter(400);
      text-align: center;
      color: $gray-2;

      overflow: hidden;

      @include maxScreen($sm) {
        font-size: 12px;
      }
    }

    .containers {
      display: flex;
      flex-direction: column;
      gap: 12px;

      margin-top: 16px;

      .container {
        position: relative;
        background-color: $black-3;
        border-radius: 5px;

        min-height: 58px;

        overflow: hidden;
        box-sizing: border-box;

        &.cig-summary {
          display: flex;
          align-items: center;
          gap: 8px;

          min-height: 72px;

          padding: 12px;

          .cig-details {
            display: flex;
            flex-direction: column;

            .display-name {
              @include blatant(400);
              font-size: 20px;
            }

            .tag-line {
              @include inter(600);
              font-size: 14px;
              color: $gray-3;
            }
          }
        }

        &.data {
          display: flex;
          flex-direction: column;

          padding: 4px 0;

          .data-row {
            position: relative;
            display: flex;
            justify-content: space-between;
            gap: 12px;

            min-height: 50px;

            padding: 16px;

            box-sizing: border-box;

            .label {
              @include inter(600);
              font-size: 14px;
              color: $gray-3;
            }

            .value {
              @include inter(700);
              font-size: 14px;
              color: $white;
              text-align: right;
            }
          }
        }

        &.details {
          min-height: 377px;
        }

        &.price {
          min-height: 58px;
        }
      }
    }

    .button {
      margin-top: 16px;
    }
  }
}
