#cig-splash-page {
  width: 100vw;
  min-height: inherit;

  overflow: hidden;

  .banner-wrapper {
    position: absolute;
    display: flex;

    padding: 64px;
    right: 0;
    left: 0;

    z-index: $z-page-cover;

    @include maxScreen($md) {
      justify-content: center;
      padding: 24px;
    }

    @include maxScreen($sm) {
      justify-content: flex-end;
    }
  }

  .cards-wrapper {
    width: 100%;
    height: 100%;

    .card-wrapper {
      position: fixed;

      .details {
        position: absolute;
        display: flex;
        flex-direction: column;

        width: 100%;
        height: 100%;

        background-color: $black-1;
        overflow: hidden;

        .apply-details {
          flex-grow: 1;
          display: flex;
          flex-direction: column;

          margin: 64px;
          padding: 64px 32px 0 32px;

          overflow: hidden auto;

          @include maxScreen($md) {
            margin: 32px;
            padding: 32px 16px 0 32px;
          }

          @include maxScreen($sm) {
            align-items: center;
            margin: 8px;
            padding: 16px 16px 8px 16px;
          }

          .apply-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 32px;

            @include maxScreen($md) {
              gap: 16px;
            }

            .title {
              color: $gray-3;
              font-size: 96px;
              @include blatant(600);
              text-transform: uppercase;

              @include maxScreen($xl) {
                font-size: 64px;
              }

              @include maxScreen($md) {
                font-size: 40px;
              }

              @include maxScreen($md) {
                font-size: 32px;
              }
            }
          }

          .content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            margin: 32px 0;

            @include maxScreen($lg) {
              margin: 32px 0;
            }

            .sub-title {
              margin-top: 16px;

              font-size: 32px;
              @include inter(600);
              color: $gray-2;

              @include maxScreen($xl) {
                font-size: 24px;
              }

              @include maxScreen($sm) {
                text-align: center;
                font-size: 16px;
              }
            }

            .description {
              margin-top: 32px;

              font-size: 22px;
              @include inter(400);
              color: $white;

              @include maxScreen($xl) {
                font-size: 18px;
              }

              @include maxScreen($sm) {
                text-align: center;
                font-size: 14px;
              }
            }
          }

          .button-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: 16px;

            margin-top: 16px;

            @include maxScreen($sm) {
              margin-top: 32px;
            }
          }
        }
      }

      .card {
        position: absolute;
        display: flex;
        align-items: flex-end;
        z-index: 10;
        pointer-events: none;
        width: 100%;
        height: 100%;

        .text-wrapper {
          padding: 32px;

          @include maxScreen($md) {
            padding: 16px 24px;
          }

          .im-a {
            color: $white;
            @include inter(500);
            text-transform: uppercase;
            font-size: 32px;

            @include maxScreen($lg) {
              font-size: 24px;
            }
          }

          .title {
            color: $white;
            @include blatant(600);
            text-transform: uppercase;
            font-size: 64px;

            @include maxScreen($lg) {
              font-size: 48px;
            }
          }

          svg {
            @include size(64px);
          }
        }
      }

      .background-image-wrapper {
        position: absolute;

        width: 100%;
        height: 100%;

        overflow: hidden;
        cursor: pointer;
        z-index: 0;

        &:hover {
          .background-image {
            transform: scale(1.1);
          }
        }

        .background-image {
          position: absolute;
          inset: 0;

          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          transition: transform 250ms ease-in;
        }
      }
    }
  }
}
