.modal-wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  inset: 0;

  z-index: $z-modal;

  .modal {
    position: relative;

    display: flex;
    flex-direction: column;

    max-height: calc(100vh - 16px);
    max-width: calc(100vw - 16px);
    min-height: 0;

    margin: auto;

    z-index: $z-modal;

    @include minScreen($xs) {
      min-width: 350px;
    }

    @include maxScreen($xs) {
      width: calc(100vw - 16px);
    }

    &.loading {
      overflow: hidden;
    }

    &:not(.bare) {
      justify-content: center;

      border-radius: 5px;

      .modal-close-button {
        position: absolute;
        top: 0;
        right: 0;
        margin: 8px;

        z-index: $z-page-cover + 100;
      }

      .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        background-color: $black-2;
        border-radius: 10px;
        border: 2px solid $black-3;

        overflow: hidden auto;

        .wrapper {
          display: flex;
          align-items: center;
          gap: 16px;

          width: 100%;

          padding: 32px 0;

          &.text {
            flex-direction: column;

            width: calc(100% - 128px);
            padding: 32px 64px;

            @include maxScreen($sm) {
              width: calc(100% - 64px);
              padding: 32px;
            }

            &.large {
              .modal-icon {
                @include size(96px);
              }

              .titles-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;

                .title {
                  display: flex;
                  align-items: center;
                  gap: 16px;

                  color: $white;
                  text-align: center;
                }

                .sub-title {
                  color: $gray-3;
                  text-align: center;
                }
              }
            }

            &.condensed {
              flex-direction: row;
              align-items: flex-end;
              gap: 12px;

              width: calc(100% - 64px);

              padding: 24px 32px;

              @include maxScreen($sm) {
                flex-direction: column;
                align-items: center;
              }

              .modal-icon {
                @include size(88px);

                @include maxScreen($sm) {
                  @include size(64px);
                }
              }

              .titles-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                margin-bottom: 12px;

                @include maxScreen($sm) {
                  align-items: center;
                }

                .title {
                  width: 100%;

                  @include inter(600);
                  font-size: 24px;
                  color: $white;
                  text-align: left;

                  @include maxScreen($sm) {
                    text-align: center;
                  }
                }

                .sub-title {
                  width: 100%;

                  @include inter(400);
                  color: $gray-3;
                  text-align: left;

                  @include maxScreen($sm) {
                    text-align: center;
                  }
                }
              }
            }
          }

          &.children {
            flex-direction: column;
            padding-top: 0;
          }
        }

        .button-row {
          display: flex;
          justify-content: flex-end;
          gap: 24px;

          width: 100%;

          padding: 16px 24px;

          box-sizing: border-box;

          &.flat {
            background-color: $black-4;
          }

          &.stack-buttons {
            flex-direction: column;
            gap: 8px;
          }

          .inner-button-row {
            display: flex;
            gap: 24px;

            .button {
              flex-grow: 1;
            }
          }
        }
      }
    }

    // .modal-content {
    //   flex-grow: 1;

    //   min-height: 0;
    //   max-width: unset;
    //   max-height: unset;

    //   padding: 0;

    //   background-color: transparent;
    //   box-shadow: none;
    //   border-radius: 0;
    // }
  }

  &.dialogue-modal {
    z-index: $z-modal + 2;
    .modal {
      width: $sm - 100px;
      .modal-content {
        .wrapper {
          .form {
            overflow: visible;
            .inner {
              padding: 0;
              margin: 0;
            }
          }

          .input {
            width: 250px;
            text-align: center;
          }
        }
      }
    }
  }

  &.form-modal {
    .modal {
      width: $sm - 100px;
      .modal-content {
        .wrapper {
          &.children {
            gap: 0;
            padding-bottom: 8px;

            .form-wrapper {
              width: 100%;

              .form {
                .inner {
                  gap: 24px;

                  padding: 16px 0;

                  .form-row {
                    padding: 0 32px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
